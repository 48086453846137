import { Component, HostListener, ViewChild, ElementRef, AfterViewInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from 'src/assets/routes';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Options } from 'ng5-slider';
import { unwatchFile } from 'fs';
import { isNullOrUndefined, isBuffer } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { IsAdminService } from 'src/app/services/is-admin.service';
import { SousCatService } from 'src/app/services/Products/sous-cat.service';
import { take } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { DebugService } from 'src/app/services/debug.service';
import { TextService } from 'src/app/services/text.service';

@Pipe({ name: 'split' })
export class ShiftPosition implements PipeTransform {
  transform(items: string, value: string): string {
    return items.substr(1) + ' ' + items.substr(0, 1);
  }
}

@Component({
  selector: 'app-sous-cat',
  templateUrl: './sous-cat.component.html',
  styleUrls: ['./sous-cat.component.scss']
})
export class SousCatComponent implements AfterViewInit {
  @ViewChild('m_classify', { static: true }) viewChildHook: ElementRef;
  $isMobile = new BehaviorSubject<boolean>(true);
  $isFilterOuvert = new BehaviorSubject<boolean>(false);
  $finishedLoading = new BehaviorSubject<boolean>(false);
  sousCat: any;
  cat: any;
  value: number = 0;
  highValue: number = 100000;
  options: Options = {
    floor: 0,
    ceil: 100000,
    animate: false
  };
  img = `https://picsum.photos/900/500?random&t=${Math.random()});`;
  sub = new Subscription();
  products: any[] = [];
  filters;
  filteredProduct: any[] = [];
  barreRechercheForm: FormGroup;
  isAdmin;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    private cookieS: CookieService,
    private adminS: IsAdminService,
    private sousCatS: SousCatService,
    private debugS: DebugService,
    private textS: TextService) {

    this.sousCat = this.route.snapshot.paramMap.get('souscat');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.sousCat = param.get('souscat');
    }));
    this.cat = this.route.snapshot.paramMap.get('cat');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.cat = param.get('cat');
    }));
    this.barreRechercheForm = new FormGroup({
      barreRecherche: new FormControl('')
    })

    this.sub.add(this.sousCatS.provideFilter(this.sousCat).pipe(take(1)).subscribe(res => {
      if (this.debugS.isDebug())
        console.log(res);
      let resP: any = res;
      if (!isNullOrUndefined(resP.authorizedPath) && resP.authorizedPath !== "") {
        if (this.cat !== resP.authorizedPath) {
          if (this.debugS.isDebug())
            console.log('redirect because authorized path');
          this.router.navigate([routes.notfound])
        }
      }
      if (!isNullOrUndefined(resP.data) && resP.data !== "") {
        this.filters = resP.data;
        if (this.debugS.isDebug())
          console.log(this.filters);
        this.filters.unshift({ type: 'slider', isClicked: true, titre: "Prix", options: { floor: 0, ceil: 100000, animate: false }, value: 0, highValue: 100, productValue: 'promo', isProductAttributes: false });
        if (this.debugS.isDebug())
          console.log(this.filters);

        this.isAdmin = this.adminS.isAdmin();
        this.sub.add(this.sousCatS.provideProducts(this.sousCat, true).pipe(take(1)).subscribe(res => {
          let resP: any = res;
          this.products = resP;
          for (let product of this.products) {
            if (this.debugS.isDebug())
              console.log(product['attributs']);
            product['attributs'] = JSON.parse(product['attributs'])
            if (this.debugS.isDebug())
              console.log(product['attributs']);
          }
          this.parseFilters();
          this.DOfilter();
          this.$finishedLoading.next(true);
          if (this.debugS.isDebug())
            console.log(res);
        }, err => {
          if (this.debugS.isDebug())
            console.log(err);
        }))
      } else {
        this.redirect();
      }
    },
      err => {
        if (this.debugS.isDebug())
          console.log(err);
        this.redirect();
      }))

    /* this.$isFilterOuvert.subscribe(() => console.log(this.$isFilterOuvert.value)) */
  }
  filtre = (s) => {
    let res = true;
    let rech = false;
    for (let filter of this.filters) {
      if (filter.type === "slider") {
        if (!isNullOrUndefined(filter.productValue) && !isNullOrUndefined(filter.isProductAttributes)) {
          let value;
          if (filter.isProductAttributes) {
            try {
              value = s.attributs.filter(s => s.name === filter.productValue)[0].value;
            } catch (t) {

            }
          } else {
            value = s[filter.productValue];
          }
          if (this.debugS.isDebug())
            console.log('value :', value);
          if (!isNullOrUndefined(value)) {
            if (!(value >= filter.value && value <= filter.highValue)) {
              res = false;
            }
          }
        }
      } else if (filter.type === "checkbox") {
        if (!isNullOrUndefined(filter.productValue) && !isNullOrUndefined(filter.isProductAttributes)) {
          let value;
          if (filter.isProductAttributes) {
            try {
              value = s.attributs.filter(s => s.name === filter.productValue)[0].value;
            } catch (t) {

            }
          } else {
            value = s[filter.productValue];
          }
          let selected = []
          for (let checkb of filter.checkb) {
            if (checkb.isChecked) {
              selected.push(checkb.name.toLowerCase());
            }
          }
          if (selected.length !== 0) {
            if (!isNullOrUndefined(value)) {
              if (!selected.includes(value.toLowerCase())) {
                res = false
              }
            } else {
              res = false;
            }
          }
        }
      }
      let recherche = this.barreRechercheForm.controls['barreRecherche'].value.split(' ').join('').toLowerCase();
      if (!isNullOrUndefined(recherche) && recherche !== "") {
        for (const key in s) {
          if (key !== "img" && key !== 'id' && key !== 'createdAt' && key !== 'updatedAt' && key !== 'sousCat' && key !== "bigP") {
            if (key === "attributs") {
              for (const key of s.attributs) {
                if (!isNullOrUndefined(key.value)) {
                  let v: string = key.value.toString().toLowerCase();
                  if (this.debugS.isDebug())
                    console.log('key :', key, 'value :', v);
                  if (v.split(' ').join('').includes(recherche)) {
                    rech = true;
                  }
                }
              }
            } else {
              if (!isNullOrUndefined(s[key])) {
                let v: string = s[key].toString().toLowerCase();
                /* console.log(`${v.split(' ').join('')}.includes(${recherche}) = ${v.split(' ').join('').includes(recherche)}`) */
                if (v.split(' ').join('').includes(recherche)) {
                  rech = true;
                }
              }
            }
          }
        }
        rech;
      } else {
        rech = true;
      }
    }
    return (res && rech);
  }
  async DOfilter() {
    this.filteredProduct = await this.products.filter(this.filtre);
    if (this.debugS.isDebug()) {
      console.log('filtered :')
      console.log(this.filteredProduct);
    }
  }

  checkBoxChange(index1, index2) {
    this.filters[index1].checkb[index2].isChecked = !this.filters[index1].checkb[index2].isChecked;
    let filter = this.filters[index1];
    if (filter.type !== "checkbox") {
      this.DOfilter();
      return;
    }
    if (filter.onlyOne) {
      if (this.debugS.isDebug())
        console.log("only");
      for (const [index, chekcb] of this.filters[index1].checkb.entries()) {
        if (chekcb.isChecked && chekcb.name !== filter.checkb[index2].name) {
          this.filters[index1].checkb[index].isChecked = false;
        }
      }
    }
    this.DOfilter();
  }

  ngAfterViewInit() {
    const value = getComputedStyle(this.viewChildHook.nativeElement).getPropertyValue('background-color');
    if (value === "rgb(255, 0, 0)") {
      if (!this.$isMobile.value) {
        this.$isMobile.next(true);
        if (this.debugS.isDebug())
          console.log('mobile');
      }
    } else {
      if (this.$isMobile.value) {
        this.$isMobile.next(false);
        if (this.debugS.isDebug())
          console.log('pc');
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    const value = getComputedStyle(this.viewChildHook.nativeElement).getPropertyValue('background-color');
    if (value === "rgb(255, 0, 0)") {
      if (!this.$isMobile.value) {
        this.$isMobile.next(true);
        if (this.debugS.isDebug())
          console.log('mobile');
      }
    } else {
      if (this.$isMobile.value) {
        this.$isMobile.next(false);
        if (this.debugS.isDebug())
          console.log('pc');
      }
    }
  }

  getImage(dataImage) {
    const dataImageP = dataImage.split(' ').join('+');
    return this.sanitizer.bypassSecurityTrustStyle(`url('${dataImageP}')`);
  }

  async parseFilters() {
    if (this.debugS.isDebug())
      console.log('filters before parse :', this.filters);
    let removedFilter = [];
    for (let filter of this.filters) {
      if (filter.type === 'slider') {
        if (!isNullOrUndefined(filter.productValue) && !isNullOrUndefined(filter.isProductAttributes)) {
          let floor: number;
          let ceil: number;
          if (filter.titre.toLowerCase() === "prix") {
            for (let product of this.products) {
              if (this.debugS.isDebug())
                console.log("---------------")
              let value: number = Math.min(product.prix, product.promo);
              if (this.debugS.isDebug()) {
                console.log('Prix le plus bas :')
                console.log(value);
              }
              if (!isNullOrUndefined(value)) {
                if (isNullOrUndefined(ceil)) {
                  ceil = value;
                }
                if (isNullOrUndefined(floor)) {
                  floor = value;
                }
                if (value < floor) {
                  floor = value;
                }
                if (value > ceil) {
                  ceil = value;
                }
              }
            }
          } else {
            for (let product of this.products) {
              if (this.debugS.isDebug())
                console.log("---------------");
              let value: number;
              if (filter.isProductAttributes) {
                try {
                  value = parseFloat(product.attributs.filter(s => s.name === filter.productValue)[0].value);
                } catch (t) {
                  if (this.debugS.isDebug())
                    console.log(t);
                }
              } else {
                value = product[filter.productValue];
              }
              if (this.debugS.isDebug())
                console.log('parsing :' + value)
              if (!isNullOrUndefined(value)) {
                if (isNullOrUndefined(ceil)) {
                  ceil = value;
                }
                if (isNullOrUndefined(floor)) {
                  floor = value;
                }
                if (value < floor) {
                  floor = value;
                }
                if (value > ceil) {
                  ceil = value;
                }
              }
            }
          }
          if (!(isNullOrUndefined(ceil) && isNullOrUndefined(floor))) {
            filter.options.floor = Math.floor(floor);
            filter.options.ceil = Math.ceil(ceil);
            filter.highValue = Math.ceil(ceil);
            filter.value = Math.floor(floor);
          } else {
            removedFilter.push(filter.titre);
          }
        }
      } else if (filter.type === "checkbox") {
        if (!isNullOrUndefined(filter.productValue) && !isNullOrUndefined(filter.isProductAttributes)) {
          let add = [];
          let included = [];
          for (let product of this.products) {
            let value;
            if (filter.isProductAttributes) {
              try {
                value = product.attributs.filter(s => s.name === filter.productValue)[0].value;
              } catch (t) {

              }
            } else {
              value = product[filter.productValue];
            }
            if (!isNullOrUndefined(value)) {
              if (!included.includes(value)) {
                let cookie = this.cookieS.get('filter');
                let checkedTemp = false;
                if (!isNullOrUndefined(cookie) && cookie !== '') {
                  this.cookieS.delete('filter');
                  let cookieSplited = cookie.split('$');
                  if (cookieSplited[0] === filter.titre) {
                    if (cookieSplited[1] === value) {
                      checkedTemp = true;
                    }
                  }
                }
                if (filter.isLocked) {
                  if (filter.valeursPossible.includes(value)) {
                    add.push({ name: value, isChecked: checkedTemp });
                    included.push(value);
                  }
                } else {
                  add.push({ name: value, isChecked: checkedTemp });
                  included.push(value);
                }
              }
            }
          }
          if (add.length !== 0) {
            filter.checkb = add;
          } else {
            if (this.debugS.isDebug())
              console.log(`pushed ${filter.titre}`);
            removedFilter.push(filter.titre);
          }
        } else {
          if (this.debugS.isDebug())
            console.log(`pushed ${filter.titre}`);
          removedFilter.push(filter.titre);
        }
      }
      filter.isEditing = false;
      this.sub.add(this.textS.provideData('help_' + filter.titre).pipe(take(1)).subscribe(
        res => {
          try {
            filter.help = res.text;
            console.log(res.text);
          } catch (err) {
            if (this.debugS.isDebug()) console.log('catched err while parsing :', res, ' - ', err);
          }
        }
      ));
    }
    for (let filterName of removedFilter) {
      if (this.debugS.isDebug())
        console.log('filterName : ' + filterName);
      this.filters = this.filters.filter(s => s.titre !== filterName);
    }
    if (this.debugS.isDebug())
      console.log('filters after parse :', this.filters);
  }

  redirect() {
    if (this.debugS.isDebug())
      console.log('redirecting...');
    let tDate = new Date(Date.now());
    tDate.setSeconds(tDate.getSeconds() + 60);
    this.cookieS.set('redirectFrom', this.router.url, tDate);

    this.router.navigate([routes.notfound]);
  }

  returnBack() {
    let ret = this.router.url.split('/');
    ret.pop();
    return ret;
  }

  editHelp(word, value) {
    this.textS.pullData('help_' + word, { text: value }).pipe(take(1)).subscribe();
  }
}
