<!-- test version e-cigarettes -->
<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<ng-template #finishedLoading>
        <div id="autoResize"></div>
        <div class="body_container" [formGroup]="editControl">
                <div id="messageEdit_container">
                        <p id="messageEdit">Vous êtes dans le mode édition, cliquez sur du texte pour le modifier ou le
                                rédiger. Les
                                changements seront appliqués après appuie sur "modifier la page". Autrement, vous pouvez
                                à tout moment
                                annuler l'édition avec le bouton "annuler".</p>
                </div>
                <div id="back_container">
                        <input type="submit" value="annuler" (click)="htmlestcon()" class="apply_modify" />
                </div>
                <img src="image" alt="">

                <div class="simple_pres" *ngIf="Data[1].isEnable">
                        <div class="big_title">
                                <h1><input class="edited_input" placeholder="Saisissez un titre" #D1TH1N1
                                                formControlName="D1TH1N1" type="text"></h1>
                                <span></span>
                        </div>
                        <p><textarea class="edited_textarea" placeholder="Saisissez du texte" #D1TP1
                                        formControlName="D1TP1" type="text"></textarea></p>
                </div>


                <div class="dual_separator" *ngIf="Data[2].isEnable">
                        <div class="ds">
                                <h3 class="subtitle"><input #Lien1 class="edited_input"
                                                placeholder="Saisissez un nom à afficher dans le lien"
                                                formControlName="Lien1" type="text" (input)="this.changeSousCat()"></h3>
                                <h2><input class="edited_input" placeholder="Saisissez un titre" #D2TH2N1
                                                formControlName="D2TH2N1" type="text"></h2>
                                <h3 class="subtitle"><input class="edited_input" placeholder="Saisissez un sous-titre"
                                                type="text"></h3>
                                <p><textarea id="testarea" class="edited_textarea" placeholder="Saisissez du texte"
                                                #D2TP1 formControlName="D2TP1" type="text"></textarea></p>
                        </div>
                        <div class="dc_separator"></div>
                        <div class="ds">
                                <h3 class="subtitle"><input #Lien2 class="edited_input"
                                                placeholder="Saisissez un nom à afficher dans le lien"
                                                formControlName="Lien2" type="text" (input)="changeSousCat()"></h3>
                                <h2><input class="edited_input" placeholder="Saisissez un titre" #D2TH2N2
                                                formControlName="D2TH2N2" type="text"></h2>
                                <h3 class="subtitle"><input #D2TH3N1 class="edited_input"
                                                placeholder="Saisissez un sous-titre" formControlName="D2TH3N1"
                                                type="text"></h3>
                                <p><textarea class="edited_textarea" placeholder="Saisissez du texte" #D2TP2
                                                formControlName="D2TP2" type="text"></textarea></p>
                        </div>
                        <div class="dc_separator"></div>
                        <div class="ds">
                                <h3 class="subtitle"><input #Lien3 class="edited_input"
                                                placeholder="Saisissez un nom à afficher dans le lien"
                                                formControlName="Lien3" type="text" (input)="changeSousCat()"></h3>
                                <h2><input class="edited_input" placeholder="Saisissez un titre" #D2TH2N3
                                                formControlName="D2TH2N3" type="text"></h2>
                                <h3 class="subtitle"><input class="edited_input" placeholder="Saisissez un sous-titre"
                                                type="text"></h3>
                                <p><textarea class="edited_textarea" placeholder="Saisissez du texte" #D2TP3
                                                formControlName="D2TP3" type="text"></textarea></p>
                        </div>
                </div>



                <div *ngIf="Data['simple_pres'] !== null">
                        <div class="simple_pres" *ngFor="let d of Data['simple_pres']" [formGroup]="d.formG">

                                <div class="big_title">
                                        <h1 id="kits&POD"><input class="edited_input"
                                                        placeholder="Saisissez un sous-titre" type="text"
                                                        formControlName="titre"></h1>
                                        <span></span>
                                </div>
                                <p id="del-sc" (click)="supprimer(d)">supprimer</p>
                                <p><textarea class="edited_textarea" placeholder="Saisissez du texte" type="text"
                                                formControlName="text"><!-- {{d.textP}} --></textarea>
                                </p>
                                <div class="slider">
                                    <h3>slider</h3>
                                    <p>afficher le slider : <input type="checkbox" formControlName="afficherSlider">
                                    </p>
                                    <p>configuration du slider : <input type="text" formControlName="configSlider">
                                    </p>
                                </div>
                        </div>
                </div>

                <p id="add-sc" (click)="ajouter()">ajouter</p>


                <div id="apply_container">
                        <input type="submit" value="modifier la page" (click)="onEditSubmit()" class="apply_modify" />
                </div>
        </div>
</ng-template>