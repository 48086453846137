export const path = {
  accueil: 'home',
  dashboard: 'dashboard',
  account: 'account',
  admin: 'admin',
  categorie: 'cat',
  faq: 'faq',
  header: 'm/header',
  mentionlegal: 'mentionslegales',
  contact: 'contacts',
  notfound: 'notfound',
  informations: 'info',
  carousselEdit: 'caroussel',
  vapomatic: 'vapomatic',
  verifemail: 'verifmail',
  forgotPassword: 'forgotPassword'
};

export const routes = {
  accueil: '/' + path.accueil,
  dashboard: '/' + path.dashboard,
  account: '/' + path.dashboard + '/' + path.account,
  admin: '/' + path.dashboard + '/' + path.admin,
  categorie: '/' + path.categorie,
  faq: '/' + path.faq,
  header: '/' + path.header,
  mentionlegal: '/' + path.mentionlegal,
  contact: '/' + path.contact,
  notfound: '/' + path.notfound,
  informations: '/' + path.dashboard + '/' + path.informations,
  carousselEdit: '/' + path.dashboard + '/' + path.carousselEdit,
  vapomatic: '/' + path.vapomatic,
  verifemail: '/' + path.verifemail,
  forgotPassword: '/' + path.forgotPassword
};
