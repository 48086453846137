//#region imports
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthServiceConfig, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { BodyComponent } from './core/body/body.component';
import { LoginformComponent } from './core/login-register/loginform/loginform.component';
import { RegisterformComponent } from './core/login-register/registerform/registerform.component';
import { AccountInformationComponent } from './core/user-panel/Components/account-information/account-information.component';
import { DashHeaderComponent } from './core/user-panel/Components/dash-header/dash-header.component';
import { VerticalMenuComponent } from './core/user-panel/Components/vertical-menu/vertical-menu.component';
import { AuthInterceptor } from './providers/interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CatComponent } from './core/cat/cat.component';
import { LoadingComponent } from './core/loading/loading.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MainCarousselComponent } from './core/main-caroussel/main-caroussel.component';
import { E404Component } from './core/e404/e404.component';
import { MentionsLegalComponent } from './core/footers/mentions-legal/mentions-legal.component';
import { ContactComponent } from './core/footers/contact/contact.component';
import { CatEditComponent } from './core/cat-edit/cat-edit.component';
import { SousCatComponent } from './core/sous-cat/sous-cat.component';
import { InfoEditComponent } from './core/user-panel/Components/info-edit/info-edit.component';
import { Ng5SliderModule } from 'ng5-slider';
import { FaqComponent } from './core/faq/faq.component';
import { FooterComponent } from './core/footers/footer/footer.component';
import { HeadComponent } from './core/headers/head/head.component';
import { HeaderComponent } from './core/headers/header/header.component';
import { ListItemComponent } from './core/headers/list-item/list-item.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
/** Import themes */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { LyTheme2, StyleRenderer, LY_THEME_NAME, LY_THEME, LyHammerGestureConfig, LyThemeModule, LY_THEME_GLOBAL_VARIABLES, LyCommonModule, LyOverlayModule } from '@alyle/ui';
import { CompModule } from './comp.module';
import { AdminPanelComponent } from './core/user-panel/admin-panel/admin-panel.component';
import { CropperWithDialogAppModule, GlobalVariables } from './core/cropper/cropper-with-dialog.module';
import { LyIcon } from '@alyle/ui/icon/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog';
import { CropperDialog } from './core/cropper/cropper-dialog';
import { CarousselEditFormComponent } from './core/user-panel/Components/caroussel-edit-form/caroussel-edit-form.component';
import { ProductComponent } from './core/product/product.component';
import { VapomaticComponent } from './core/vapomatic/vapomatic.component';
import { BoutonAdminComponent } from './core/user-panel/admin-panel/bouton-admin/bouton-admin.component';
import { PasswordPopupComponent } from './core/user-panel/admin-panel/password-popup/password-popup.component';
import { VerifMailComponent } from './core/verif-mail/verif-mail.component';
import { ChangePasswordComponent } from './core/change-password/change-password.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { CookieService } from 'ngx-cookie-service';
import { ProductEditComponent } from './core/product-edit/product-edit.component';
import { FaqEditComponent } from './core/faq-edit/faq-edit.component';
//#endregion

// Config de OAuth
//#region configOAuth
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      '437984952543-nth1251jk4g56klf927so0skaho67k20.apps.googleusercontent.com'
    )
  }
  /*  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("Facebook-App-Id")
  } */
]);

registerLocaleData(localeFr, 'fr-FR');

export function provideConfig() {
  return config;
}
//#endregion

//#region ngmodule
@NgModule({
  declarations: [
    AppComponent,
    LoginformComponent,
    RegisterformComponent,
    BodyComponent,
    CatComponent,
    MainCarousselComponent,
    E404Component,
    MentionsLegalComponent,
    ContactComponent,
    CatEditComponent,
    SousCatComponent,
    FaqComponent,
    FooterComponent,
    HeadComponent,
    HeaderComponent,
    ListItemComponent,
    LoadingComponent,
    AdminPanelComponent,
    AccountInformationComponent,
    DashHeaderComponent,
    InfoEditComponent,
    VerticalMenuComponent,
    CarousselEditFormComponent,
    ProductComponent,
    VapomaticComponent,
    BoutonAdminComponent,
    PasswordPopupComponent,
    VerifMailComponent,
    ChangePasswordComponent,
    ProductEditComponent,
    FaqEditComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SocialLoginModule,
    ReactiveFormsModule,
    JwtModule,
    AppRoutingModule,
    MatDialogModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents([
      BoutonAdminComponent
    ]),
    NgbModule,
    Ng5SliderModule,
    RecaptchaV3Module,
    CropperWithDialogAppModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    HammerModule,
    LyCommonModule,
    LyOverlayModule,
    NgbPaginationModule
  ],
  entryComponents: [
    LoginformComponent,
    RegisterformComponent,
    CropperDialog,
    PasswordPopupComponent
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LfXL_8UAAAAAOmLqZflo0H7-V-g3wdQbXnee-pU'
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr', // use French language
    },
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    {
      provide: LY_THEME,
      useClass: MinimaLight,
      multi: true
    },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true
    },
    {
      provide: LY_THEME_GLOBAL_VARIABLES,
      useClass: GlobalVariables
    }, // global variables
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    CookieService
  ],
  bootstrap: [AppComponent],
  exports: []
})
//#endregion

export class AppModule { }
