<footer>
    <div class="elements" id="number1">
        <p>Designed by <a href="https://www.linkedin.com/in/maximeLBe" target="_blank">Max</a> and <a
                href="https://www.linkedin.com/in/RaphaëlDelaygues" target="_blank">Raf</a></p>
    </div>
    <div class="elements" id="number2">
        <a routerLink="mentionslegales">mentions légales</a>
        <div id="copyright">
            <p>Copyright © 2019-{{year}} TropikVap</p>
            <p id="spaceX">&nbsp;|&nbsp;</p>
            <p>Tous droits réservés</p>
        </div>
    </div>
    <div class="elements" id="number3">
        <h1>contacts</h1>
        <a href="https://www.facebook.com/tropikvap/" target="_blank">notre facebook</a>
        <a routerLink="contacts">informations générales</a>
    </div>
</footer>