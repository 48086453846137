import { Injectable } from '@angular/core';
import { routes } from 'src/assets/routes';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatDataService {
  text;
  constructor(private http: HttpClient) { }

  provideData(id) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostData + `getCatData?name=${id}`, { headers });
  }

  pullData(id, data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostAdmin + `setCatData`, `name=${id}&data=${JSON.stringify(data)}`, { headers });
  }

  provideRandomImage(sousCat?, caract?, value?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get<any[]>(environment.hostData + `getRandomImage?sousCat=${sousCat}&caract=${caract}&value=${value}`, { headers });
  }

}
