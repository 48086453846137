<app-loading *ngIf="$finishedLoading.value === false; else finishedTemplate"></app-loading>
<ng-template #finishedTemplate>
    <div class="body_container" [formGroup]='editForm'>
        <div *ngFor="let msg of $AllMsgs.value">
            <div id="sucessfull" class="popup" *ngIf="msg.type === 'success'">
                <img src="/assets/img/utilities/green_check.svg">
                <p>Vos modifications ont bien été enregistrées.</p>
            </div>
            <div id="caramba_error" class="popup" *ngIf="msg.type === 'error'">
                <img src="/assets/img/utilities/red_cross.svg">
                <p>{{msg.error}}</p>
            </div>
        </div>
        <div id="back_container">
            <!-- <input type="submit" value="annuler" (click)="htmlestcon()" class="apply_modify" /> -->
            <input type="submit" value="annuler" [routerLink]="this.router.url.replace('/edit', '')"
                class="apply_modify" />
        </div>
        <div id="entete">
            <h3><input class="edited_input" placeholder="Saisissez un nom de produit" type="text" #nomProduct
                    formControlName="nomProduct"></h3>
            <input class="edited_input" placeholder="Saisissez un lien" type="text" #link formControlName="link">
            <p class="desc"><textarea class="edited_textarea" placeholder="Saisissez une très courte description"
                    type="text" #desc formControlName="desc"></textarea></p>
        </div>
        <div id="big_container">
            <div id="presentation">
                <div id="p1">
                    <!-- <div class="image_kk"></div> -->
                    <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>

                    <button ly-button (click)="_fileInput.click()" style="padding:0 !important;">
                        <!-- <ly-icon [mf]="1">image</ly-icon>
                    <span>Select File</span> -->
                        <img src="\assets\img\utilities\upload2.svg" id="upload_button" />
                        <img [src]="this.getImage(this.cropped)" id="cropped_img" />
                    </button>
                </div>
                <div id="p2">
                    <p id="bigDesc">
                        <textarea formControlName="bigDesc" #bigDesc class="edited_textarea"
                            placeholder="Saisissez une description" type="text"></textarea>
                    </p>
                    <p id="price">
                        <input class="edited_input" formControlName="price" #price placeholder="prix" type="number">
                        <input class="edited_input" formControlName="promo" #promo placeholder="promo" type="number">
                    </p>
                    <p id="formats">


                        <input class="edited_input" formControlName="formatsLiquides" #formatsLiquides
                            placeholder="Saisissez les formats (liquides uniquement)" type="text">
                    <div [formGroup]="capaciteForm" id="subformats">
                        <input type="checkbox" formControlName="0" id="0"/><label for="0">0 Mg</label>
                        <input type="checkbox" formControlName="3" id="3"/><label for="3">3 Mg</label>
                        <input type="checkbox" formControlName="6" id="6"/><label for="6">6 Mg</label>
                        <input type="checkbox" formControlName="10" id="10"/><label for="10">10 Mg</label>
                        <input type="checkbox" formControlName="11" id="11"/><label for="11">11 Mg</label>
                        <input type="checkbox" formControlName="12" id="12"/><label for="12">12 Mg</label>
                        <input type="checkbox" formControlName="16" id="16"/><label for="16">16 Mg</label>
                        <input type="checkbox" formControlName="18" id="18"/><label for="18">18 Mg</label>
                        <input type="checkbox" formControlName="20" id="20"/><label for="20">20 Mg</label>
                    </div>
                    <!-- C ICI QUE FAUT QUE TU TRAVAILLES -->

                    <!-- </p> -->
                </div>
            </div>
            <div id="caracteristiques">
                <h3>Caractéristiques</h3>
                <div id="confirm_rm" *ngIf="$warningShow.value">
                    <div id="rm_subcontainer">
                        <p>Souhaitez-vous supprimer ce produit ? Cette action affectera tous les produits.
                        </p>
                        <div>
                            <input type="submit" value="annuler" class="apply_modify grey"
                                (click)="this.$warningShow.next(false)" />
                            <input type="submit" value="supprimer" class="apply_modify red" (click)="delete()" />
                        </div>
                    </div>
                </div>
                <div id="confirm_rm" *ngIf="$warningCatShow.value">
                    <div id="rm_subcontainer">
                        <p>Souhaitez-vous supprimer cette caractéristique ? Cette action affectera tous les produits.
                        </p>
                        <div>
                            <input type="submit" value="annuler" class="apply_modify grey"
                                (click)="this.$warningCatShow.next(false)" />
                            <input type="submit" value="supprimer" class="apply_modify red" (click)="supprCaract()" />
                        </div>
                    </div>
                </div>
                <div id="confirm_rm" *ngIf="$warningChangeShow.value">
                    <div id="rm_subcontainer">
                        <p>Souhaitez-vous modifier le type de cette caractéristique? Cette action affectera tous les
                            produits.
                        </p>
                        <div>
                            <input type="submit" value="annuler" class="apply_modify grey"
                                (click)="this.$warningChangeShow.next(false)" />
                            <input type="submit" value="modifier" class="apply_modify red"
                                (click)="changeCaractType()" />
                        </div>
                    </div>
                </div>
                <table>
                    <tr>
                        <td class="td_start">catégorie</td>
                        <td>valeure</td>
                        <td>filtré ?</td>
                        <td>une case cochable</td>
                        <td class="noborder"></td>
                    </tr>
                    <tr *ngFor="let caract of this.caracts; let i = index">
                        <td class="c1 td_start">
                            <div>
                                <span class="caract_key">{{caract.key}}</span>
                                <!-- <input class="edited_input" placeholder="Saisissez un critère" type="text"
                                    [value]="caract.key" (change)="changeCaractName(caract, $event)"> -->
                                <p class="nbOrNot" *ngIf="caract.type === 'countable'"
                                    (click)="selectedCaract = caract; $warningChangeShow.next(true)">dénombrable</p>
                                <p class="nbOrNot" *ngIf="caract.type === 'uncountable'"
                                    (click)="selectedCaract = caract; $warningChangeShow.next(true)">indénombrable</p>
                            </div>
                        </td>
                        <td class="c2">
                            <input class="edited_input" (change)='onCaractChange(caract,$event.target.value, "value")'
                                placeholder="Saisissez une valeure" type="text" [value]="caract.value"
                                *ngIf="caract.type === 'uncountable'">
                            <div *ngIf="caract.type === 'countable'" class="countable">
                                <input class="edited_input" placeholder="Saisissez une valeure (ou laissez vide)"
                                    type="number" (change)='onCaractChange(caract,$event.target.value, "value")'
                                    [value]="caract.value" />
                                <input class="edited_input" placeholder="Saisissez une unitée" type="text"
                                    (change)='onCaractChange(caract,$event.target.value, "unit")'
                                    [value]="caract.unit" />
                            </div>
                        </td>
                        <td>
                            <!-- filtré -->
                            <input type="checkbox" [checked]='caract.filterable'
                                (click)="changeFilterableOrOnlyOne(caract, 'filterable', $event)" />
                        </td>
                        <td>
                            <!-- cochable 1 fois ? -->
                            <input type="checkbox" [checked]='caract.onlyOne'
                                (click)="changeFilterableOrOnlyOne(caract, 'onlyOne', $event)" />
                        </td>
                        <!-- <td class="c3 td_end" (click)="supprCaract(caract)">supprimer</td> -->
                        <td class="c3 td_end" (click)="selectedCaract = caract;  $warningCatShow.next(true) ">supprimer
                        </td>
                    </tr>
                    <tr *ngIf="$isNewOpened.value" [formGroup]="addGroup">
                        <td class="c1 td_start">
                            <div>
                                <!-- {{caract.key}} --><input class="edited_input" placeholder="Saisissez un critère"
                                    type="text" formControlName="caractName">
                                <p class="nbOrNot" *ngIf="$newOpenedType.value === 'countable'"
                                    (click)="$newOpenedType.next('uncountable')">dénombrable</p>
                                <p class="nbOrNot" *ngIf="$newOpenedType.value === 'uncountable'"
                                    (click)="$newOpenedType.next('countable');">
                                    indénombrable</p>
                            </div>
                        </td>
                        <td class="c2">
                            <input class="edited_input" placeholder="Saisissez une valeure" type="text"
                                *ngIf="$newOpenedType.value === 'uncountable'" formControlName="value">
                            <input class="edited_input" placeholder="Saisissez une valeure" type="number"
                                *ngIf="$newOpenedType.value === 'countable'" formControlName="value">
                            <input class="edited_input" palceholder="Saissisez une unité" type="text"
                                *ngIf="$newOpenedType.value === 'countable'" formControlName="unit">
                        </td>
                        <td class="checkboxes">
                            <!-- filtré -->
                            <input type="checkbox" formControlName="isFilter" />
                        </td>
                        <td class="checkboxes">
                            <!-- cochable 1 fois ? -->
                            <input type="checkbox" formControlName="isOnlyOne" />
                        </td>
                        <td class="c3 td_end">
                            <div>
                                <p (click)="$isNewOpened.next(false)" id="c3_1">annuler</p>
                                <p id="c3_2" (click)="onSubmitNewCaract()">valider</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" id="addSpec" class="noborder" (click)="$isNewOpened.next(true)"
                            *ngIf="!$isNewOpened.value">
                            ajouter une nouvelle catégorie
                        </td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="apply_container" id="Tbuttons">
            <input type="button" value="supprimer" class="apply_modify red" (click)="this.$warningShow.next(true)"
                *ngIf="!this.router.url.split('/').includes('new')" />
            <input type="button" value="enregistrer" (click)="onSubmit()" class="apply_modify green" />
            <input type="button" value="créer un autre" class="apply_modify" (click)="onSubmit(true)" />
        </div>
    </div>
</ng-template>