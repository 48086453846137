import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class SousCatService {

  constructor(private http: HttpClient) { }

  pullFilter(id, data, authorizedPath?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    if (!isNullOrUndefined(authorizedPath) && authorizedPath !== "") {
      return this.http.post(environment.hostAdmin + `setFilters`, `name=${id}&data=${JSON.stringify(data)}&authorizedPath=${authorizedPath}`, { headers });
    } else {
      return this.http.post(environment.hostAdmin + `setFilters`, `name=${id}&data=${JSON.stringify(data)}`, { headers });
    }
  }

  provideFilter(id) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get<any>(environment.hostData + `getFilters?name=${id}`, { headers });
  }

  removeFilter(id) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostAdmin + `removeFilters?name=${id}`, { headers });
  }

  pullProduct(sousCat, lien, h3, prix, img, attributs, promo?, p?, bigP?, capacite?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers.append('charset', 'UTF-8');
    if (isNullOrUndefined(promo) || promo === '') {
      promo = prix;
    }
    return this.http.post(environment.hostAdmin + `addProduct`, `sousCat=${sousCat}&lien=${lien}&h3=${h3}&p=${p}&bigP=${bigP}&prix=${prix}&img=${img}&attributs=${JSON.stringify(attributs)}&promo=${promo}&capacite=${capacite}`, { headers });
  }

  changeProduct(id, lien?, sousCat?, h3?, p?, bigP?, prix?, img?, attributs?, promo?, capacite?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    );
    if (isNullOrUndefined(promo) || promo === '') {
      promo = prix;
    }
    return this.http.post(environment.hostAdmin + `setProduct`, `id=${id}&sousCat=${sousCat}&lien=${lien}&h3=${h3}&p=${p}&bigP=${bigP}&prix=${prix}&img=${img}&attributs=${JSON.stringify(attributs)}&promo=${promo}&capacite=${capacite}`, { headers });
  }

  removeProduct(id) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostAdmin + `removeProduct?id=${id}`, { headers });
  }

  provideProducts(sousCat, smaller) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostData + `getProducts?sousCat=${sousCat}&smaller=${smaller}`, { headers });
  }

  provideProductById(id, smaller) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostData + `getProduct?id=${id}&smaller=${smaller}`, { headers });
  }

  provideProductByLien(lien, smaller) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostData + `getProduct?lien=${lien}&smaller=${smaller}`, { headers });
  }

  pullCaract(sousCat, name, type, unit?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post<any>(environment.hostAdmin + `addCaract`, `sousCat=${sousCat}&name=${name}&type=${type}&unit=${unit}`, { headers });
  }

  changeCaract(id, sousCat?, name?, type?, unit?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostAdmin + `setCaract`, `id=${id}&sousCat=${sousCat}&name=${name}&type=${type}&unit=${unit}`, { headers });
  }

  removeCaract(id) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostAdmin + `removeCaract?id=${id}`, { headers });
  }

  provideCaract(sousCat) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostData + `getCaracts?sousCat=${sousCat}`, { headers });
  }

}
