<!-- <ngb-carousel #carousel *ngIf="images" interval=5000 wrap="true" (slide)="onSlide($event)">
    <div *ngFor="let img of images">
        <ng-template ngbSlide [id]="img.id">
            <div class="picsum-img-wrapper" [@simpleTranslation]="slideControl[img.sliderid]">
                <div class="image_kk" [style.background-image]="'url(' + img.img + ')'">
                    <img [src]="img.img" alt="Random second slide">
                </div>
                <div class="carousel-caption">
                    <h3>{{img.h3}}</h3>
                    <p>{{img.p}}</p>
                </div>
            </div>
        </ng-template>
    </div>
</ngb-carousel> -->
<app-loading *ngIf="$finishedLoading.value === false; else finishedTemplate"></app-loading>
<ng-template #finishedTemplate>
    <ngb-carousel #carroussel interval=5000 wrap="true">
        <ng-template ngbSlide *ngFor="let img of images">
            <!--  -->
            <div class="picsum-img-wrapper">
                <div class="image_kk" [style.background-image]="getImage(img)">
                </div>
            </div>
            <div class="carousel-caption">
                <h3>{{img.data.h3}}</h3>
                <p>{{img.data.p}}</p>
            </div>
        </ng-template>
    </ngb-carousel>
    <ng-template>