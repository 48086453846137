import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserModifyService {

  constructor(private http: HttpClient) { }

  setUser(email, username): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostUsersModify + 'setUser', `email=${email}&username=${username}`, { headers });
  }

  setUserEmail(email): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostUsersModify + 'setUserEmail', `email=${email}`, { headers });
  }

  setUsername(username): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostUsersModify + 'setUsername', `username=${username}`, { headers });
  }

  setPassword(password, newPassword): Observable<any> {
    const token = localStorage.getItem('token');
    if (token != null) {
      const jwtUtils = new JwtHelperService();
      const decodedToken = jwtUtils.decodeToken(token);
      const userId = decodedToken.userId;

      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );
      return this.http.post(environment.hostUsersModify + 'setPassword', `password=${password}&newPassword=${newPassword}&userId=${userId}`, { headers });
    } else {
      return null;
    }
  }

  getUser(): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostUsersModify + 'get', { headers });
  }

  getUserEmail(): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostUsersModify + 'getUserEmail', { headers });
  }

  getUsername(): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostUsersModify + 'getUsername', { headers });
  }
}
