<div id="all">
  <app-main-caroussel></app-main-caroussel>
  <div class="dual_img">
    <div class="item1">
      <a routerLink="/cat/e-cigarettes">
        <img src="/assets/img/caroussel/1/IMG_20200520_091454.jpg" alt="e-cigarettes">
        <div class="text-container1">
          <h1>e-cigarettes</h1>
          <h3>kits complets, POD, ou à faire soi-même.</h3>
        </div>
      </a>
    </div>
    <div class="item2">
      <a routerLink="/cat/e-liquides">
        <img src="/assets/img/caroussel/2/IMG_20200520_092847.jpg" alt="e-liquides">
        <div class="text-container2">
          <h1>e-liquides</h1>
          <h3>à chacun sa saveur.</h3>
        </div>
      </a>
    </div>
  </div>
  <!-- <div class="alone_img">
    <div class="item1">
      <a routerLink="/cat/accesoires">
        <img src="/assets/img/caroussel/3/IMG_20200520_094800.jpg" alt="accesoires">
        <div class="text-container3">
          <h1>accessoires</h1>
          <h3>tout ce dont vous avez besoin.</h3>
        </div>
      </a>
    </div>
  </div> -->
  <div class="dual_img">
    <div class="item1">
      <a routerLink="/cat/accesoires">
        <img src="/assets/img/caroussel/3/IMG_20200520_094800.jpg" alt="accesoires">
        <div class="text-container1">
          <h1>accessoires</h1>
          <h3>tout ce dont vous avez besoin.</h3>
        </div>
      </a>
    </div>
    <div class="item2">
      <a routerLink="/faq">
        <img src="\assets\img\caroussel\4\IMG_20200611_115627.jpg" alt="FAQ">
        <div class="text-container2">
          <h1>FAQ</h1>
          <h3>les réponses à toutes vos questions.</h3>
        </div>
      </a>
    </div>
  </div>

  <!--   <input type="button" (click)="doSomeShit()">
  <h3>reCAPTCHA execution log</h3>
  <pre *ngIf="executionLog.length === 0">(empty)</pre>
  <ol *ngIf="executionLog.length > 0">
    <li *ngFor="let entry of executionLog">
      Action: <code>{{ entry.action }}</code>. Token: <code>{{ formatToken(entry.token) }}</code>
    </li>
  </ol> -->
  <!-- <div class="double" id="first">
    <div class="p1">
      <div class="line">
        <span class="dot"></span>
      </div>
      <div class="line">
        <span class="dot"></span>
      </div>
      <div class="line">
        <span class="dot"></span>
      </div>

      <img src="/assets/img/photos/cigarette-présentation.jpg" id="img-pres">

      <a routerLink="/cat/letruc" class="linky" id="linky1">mabiteencarton</a>
      <a routerLink="/cat/letruc" class="linky" id="linky2">clearomiseurs & atomiseurs</a>
      <a routerLink="/cat/letruc" class="linky" id="linky3">batteries</a>

    </div>
</div> -->



</div>