import { Injectable } from '@angular/core';
export interface HeaderIcon {
  src: string, pngsrc: string, text: string, url: string
}
@Injectable({
  providedIn: 'root'
})
export class HeaderIconService {

  constructor() { }

  provideCat(): HeaderIcon[] {
    return [
      { src: '/assets/img/header/e-cigarettes.svg', pngsrc: '/assets/img/header/e-cigarettes.png', text: 'e-cigarettes', url: 'cat/e-cigarettes' },
      { src: '/assets/img/header/e-liquides.svg', pngsrc: '/assets/img/header/e-liquides.png', text: 'e-liquides', url: 'cat/e-liquides' },
      { src: '/assets/img/header/DIY.svg', pngsrc: '/assets/img/header/DIY.png', text: 'Accessoires', url: 'cat/accesoires' },
      { src: '/assets/img/header/vapomatic.svg', pngsrc: '/assets/img/header/vapomatic.png', text: 'Vap\'O Matic', url: 'vapomatic' },
      { src: '/assets/img/header/FAQ.svg', pngsrc: '/assets/img/header/FAQ.png', text: 'FAQ', url: 'faq' },
    ];
  }
}




