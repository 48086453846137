import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BurgerPaneUserService {
  $burger: BehaviorSubject<boolean>;
  $mobile: BehaviorSubject<boolean>;
  constructor() {
    this.$burger = new BehaviorSubject<boolean>(false);
    this.$mobile = new BehaviorSubject<boolean>(false);
  }
  switchBurger() {
    this.$burger.next(!this.$burger.value);
  }
}
