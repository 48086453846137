import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { routes } from 'src/assets/routes';
import { IsLoggedService } from '../../../services/login-register/is-logged.service';
import { LoginformComponent } from '../../login-register/loginform/loginform.component';
import { RegisterformComponent } from '../../login-register/registerform/registerform.component';
import { HeaderIconService } from 'src/app/services/Products/header-icon.service';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  value: number;
  listImage;
  constructor(
    private isLoggedService: IsLoggedService,
    private router: Router,
    private dialog: MatDialog,
    private headerIcon: HeaderIconService,
    private debugS: DebugService) {
    this.listImage = this.headerIcon.provideCat();
  }
  public $clickedBurger = new BehaviorSubject<boolean>(false);
  public $channel = new BehaviorSubject<boolean>(true);
  public $isWindowOpen = new BehaviorSubject<boolean>(false);
  isLoggedvar/* : boolean | Observable<boolean> */;

  ngOnInit() {
    this.isLoggedvar = this.isLoggedService.isLoggedCheck();
    this.checkChannel();
  }

  openBurger() {
    this.$clickedBurger.next(true);
  }

  closeBurger() {
    this.$clickedBurger.next(false);
  }

  clickDashboardButton() {
    this.router.navigate([routes.dashboard]);
  }

  clickLoginButton = () => {
    if (this.$channel.value === false) {
      if (this.$clickedBurger.value === true) {
        this.$clickedBurger.next(false);
      }
    }
    this.$isWindowOpen.next(true);
    if (this.debugS.isDebug()) {
      console.log(this.$isWindowOpen);
    }
    const dialogRef = this.dialog.open(LoginformComponent, { data: { isMaintenance: false } });
    dialogRef.afterClosed().subscribe(result => {
      this.$isWindowOpen.next(false);
      if (result === 'register') {
        this.openRegister();
      }
    });
  }

  openRegister() {
    this.$isWindowOpen.next(true);
    const dialogRef = this.dialog.open(RegisterformComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      this.$isWindowOpen.next(false);
      if (result === 'login') {
        this.clickLoginButton();
      }
    });
  }

  onEvent($event, image) {
    if (this.debugS.isDebug()) {
      console.log('je suis dans header');
      console.log($event);
      console.log(image);
      console.log(image.url);
    }
    this.$clickedBurger.next(false);
    // this.router.navigate([image.url]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.checkChannel();
  }
  checkChannel() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.value = (200 + (this.listImage.length * 150));
    /*     console.log(`${this.screenWidth} /70+(${this.listImage.length}*50)+29+300 = ${this.value}`) */
    if (this.screenWidth >= this.value) {
      this.$channel.next(true);
      /*       console.log('true'); */
    } else {
      /*       console.log('false'); */
      this.$channel.next(false);
    }
  }
}
