//#region imports
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  UrlTree
} from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { routes } from 'src/assets/routes';
import { environment } from 'src/environments/environment';
import { DebugService } from '../debug.service';
//#endregion

@Injectable({ providedIn: 'root' })
export class IsLoggedService implements CanActivate {
  //#region variables
  token: string;
  $isLogged = new BehaviorSubject<boolean>(false);
  //#endregion

  constructor(private http: HttpClient, private router: Router, private debugS: DebugService) {
    this.$isLogged = new BehaviorSubject<boolean>(false);
  }
  // fonction qui va etre appeller par le routeur pour savoir si l'utilisateur peut acceder a cette page
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | boolean | UrlTree {
    if (this.debugS.isDebug())
      console.log('checkin auth');
    /* if (this.isLogged()) {
      return true;
    } else {
      return this.router.parseUrl(routes.accueil);
    } */
    if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
      this.isLogged();
      return this.$isLogged ? true : this.router.parseUrl(routes.accueil);
    } else {
      return false;
    }
  }
  isLoggedCheck() {
    if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
      this.isLogged();
      return this.$isLogged;
    } else {
      return false;
    }
  }

  // fonction pour savoir si l'utilisateur est encore connecté/ si son token est encore valide
  isLogged()/* : boolean | Observable<boolean> */ {
    /*   this.token = localStorage.getItem('token');  
      if (this.token == null) {
        return false;
      } else {
        return this.checkToken().pipe(map(
          res => {
            if (res === 'Token invalid') {
              localStorage.removeItem('token')
              return false;
            } else {
              return true;
            }
          },
          err => {
            return false;
          }
        ));
      } */
    if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
      this.checkToken().pipe(take(1)).subscribe(res => {
        if (res === 'Token invalid') {
          localStorage.removeItem('token');
          this.$isLogged.next(false);
          this.router.navigate([routes.accueil]);
        } else {
          this.$isLogged.next(true);
        }
      },
        err => {
          if(this.debugS.isDebug()) {
          console.log(err);
          console.log('removing token')
          }

          localStorage.removeItem('token');
          this.$isLogged.next(false);
          this.router.navigate([routes.accueil]);
        });
    }
  }

  // fonction pour rediriger si besoin
  redirect() {
    this.router.navigate([routes.accueil]);
  }

  // requete pour checker le token au back
  checkToken(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(environment.hostUsersLoginRegister + 'checkToken', null, { headers });
  }
}
