import { Component, OnInit, OnDestroy } from '@angular/core';
import { TextService } from 'src/app/services/text.service';
import { take } from 'rxjs/internal/operators/take';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { routes } from 'src/assets/routes';
import { IsAdminService } from 'src/app/services/is-admin.service';
import { DebugService } from 'src/app/services/debug.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit, OnDestroy {
  sub = new Subscription();
  data;
  isAdmin;
  $finishedLoading = new BehaviorSubject<boolean>(false);

  constructor(private textS: TextService, private router: Router, private adminS: IsAdminService, private debugS: DebugService, private cookieS: CookieService) {
    this.isAdmin = this.adminS.isAdmin();
    this.sub.add(textS.provideData('faq').pipe(take(1)).subscribe(res => {
      this.data = res;
      res[0]['isClicked'] = true;
      this.$finishedLoading.next(true);
    },
      err => {
        console.log(err);
        this.redirect();
      })
    )
  }
  ngOnInit() {

  }

  onClick(item) {

  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  redirect() {
    if (this.debugS.isDebug())
      console.log('redirecting...');
    let tDate = new Date(Date.now());
    tDate.setSeconds(tDate.getSeconds() + 60);
    this.cookieS.set('redirectFrom', this.router.url, tDate);

    this.router.navigate([routes.notfound]);
  }
}
