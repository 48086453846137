<nav id="horizontal_menu">
    <ul id="list1-hm">
        <a routerLink='/home'>
            <div *ngIf="$burger.value === false; else burgerClicked">
                <li id="left-size-hm" [class.m-lf-hm]="$width.value <= this.maxWidth"
                    [class.pc-lf-hm]="$width.value > this.maxWidth">
                    <img src="assets/img/logos/logo_tropik_blank.png" id="hover1-hm" />
                    <p id="hover2-hm" [class.m-lf-hm-p]="$width.value <= this.maxWidth">TropikVap</p>
                </li>
            </div>
        </a>
        <li id="burger-hm">
            <img id="wrapper" src="assets/img/header/menu.png" (click)="burgerS.switchBurger()" />
        </li>
        <li id="right-size-hm">
            <ul id="list2-hm">
                <li>
                    <!-- retour -->
                    <a routerLink='/home'><img class="logos-l2-hm" src="assets/img/dashboard/icons/home.png" /></a>
                </li>
                <li>
                    <!-- disconect -->
                    <img class="logos-l2-hm" src="assets/img/dashboard/icons/exit.png"
                        (click)='LoginService.disconnect()' />
                <li>
            </ul>
        </li>
    </ul>
</nav>
<ng-template #burgerClicked>
    <li id="left-size-hm" [class.m-lf-hm]="$width.value > this.maxWidth"
        [class.pc-lf-hm]="$width.value <= this.maxWidth">
        <img src="assets/img/logos/logo_tropik_blank.png" id="hover1-hm" />
        <p id="hover2-hm" [class.m-lf-hm-p]="$width.value > this.maxWidth">TropikVap</p>
    </li>
</ng-template>