<app-loading *ngIf="$finishedLoading.value === false; else finishedTemplate"></app-loading>
<ng-template #finishedTemplate>
    <div id="container">
        <div *ngFor="let msg of $AllMsgs.value">
            <div id="sucessfull" class="popup" *ngIf="msg.type === 'success'">
                <img src="/assets/img/utilities/green_check.svg">
                <p>Vos modifications ont bien été enregistrées.</p>
            </div>
            <div id="caramba_error" class="popup" *ngIf="msg.type === 'error'">
                <img src="/assets/img/utilities/red_cross.svg">
                <p>{{msg.error}}</p>
            </div>
        </div>
        <form [formGroup]='reactModifyForm' (ngSubmit)="onSubmit()" id="form1">
            <h1>Changer de pseudo ou d'email</h1>
            <!-- <label>E-Mail</label><br>
            <input #email type="email" formControlName="email" [placeholder]="$mail | async"><br>
            <label>Username</label><br>
            <input #username type="text" formControlName="username" [placeholder]="$username | async"><br> -->

            <div class="txtb">
                <input #email type="email" autofocus formControlName="email" [placeholder]="$mail | async">
                <span data-placeholder="E-Mail" name="email"></span>
                <div id="colored"></div>
            </div>

            <div class="txtb">
                <input #username type="text" formControlName="username" [placeholder]="$username | async">
                <span data-placeholder="Nom d'utilisateur" class="place-holder-login" name="username"></span>
                <div id="colored"></div>
            </div>

            <input type="submit" class="logbtn" value="Valider">
        </form>


        <form [formGroup]='reactModifyPasswordForm' (ngSubmit)="onSubmitPassword()" id="form2">
            <h1>Changer de mot de passe</h1>
            <div class="txtb">
                <input #pass type="password" formControlName="password">
                <span data-placeholder="Mot de passe actuel" class="place-holder-login" name="password"></span>
                <div id="colored"></div>
            </div>

            <div class="txtb">
                <input #newpass type="password" formControlName="newPassword">
                <span data-placeholder="Nouveau mot de passe" class="place-holder-login" name="new_password"></span>
                <div id="colored"></div>
            </div>

            <input type="submit" class="logbtn" value="Valider">
        </form>
    </div>
</ng-template>