import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PasswordPopupService } from 'src/app/services/password-popup.service';
import { take } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-password-popup',
  templateUrl: './password-popup.component.html',
  styleUrls: ['./password-popup.component.css']
})
export class PasswordPopupComponent implements OnInit {
  forms = new FormGroup({
    password: new FormControl('', Validators.required)
  })
  constructor(
    /* private dialogRef: MatDialogRef<PasswordPopupComponent> ,*/ private passwordS: PasswordPopupService, private debugS: DebugService) { }

  ngOnInit(): void {
  }
  onSubmit() {
    this.passwordS.setAdmin(this.passwordS.id, this.forms.controls['password'].value, !this.passwordS.isAdmin).pipe(take(1)).subscribe(res => {
      if (res === "OK") {
        if (this.debugS.isDebug())
          console.log(this.passwordS.index);
        this.passwordS.gridApi.getRowNode(this.passwordS.index).setDataValue('isAdmin', !this.passwordS.isAdmin);
        this.passwordS.gridApi.refreshCells({ force: true, rowNodes: [this.passwordS.gridApi.getRowNode(this.passwordS.index)] });
        this.passwordS.closePopup();
      }
    }, err => { if (this.debugS.isDebug()) console.log(err); })
  }

}
