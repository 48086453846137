import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterStateSnapshot, Router } from '@angular/router';
import { routes } from 'src/assets/routes';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginRegisterService } from 'src/app/services/login-register/login-register.service';
import { take } from 'rxjs/operators';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-verif-mail',
  templateUrl: './verif-mail.component.html',
  styleUrls: ['./verif-mail.component.css']
})
export class VerifMailComponent implements OnInit {
  id: string = "";
  token: string;
  constructor(private route: ActivatedRoute, private router: Router, private LoginS: LoginRegisterService, private debugS: DebugService) {
    const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
    let queryParams = snapshot.url.slice(routes.verifemail.length + 1);
    let id: string = "";
    for (let char of queryParams.slice(3)) {
      if (char != '&') {
        this.id = this.id.concat(char);
      } else {
        break;
      }
    }
    this.token = queryParams.slice(3 + id.length + 9);
    this.LoginS.verifyAccount(this.id, this.token).pipe(take(1)).subscribe(res => { if (this.debugS.isDebug()) console.log(res); this.router.navigate([routes.accueil]) }, err => { if (this.debugS.isDebug()) console.log(err); });
  }

  ngOnInit(): void {

  }

  onSubmitPassword() {

  }

}
