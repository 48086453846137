<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<ng-template #finishedLoading>
    <div id="pres_container">
        <div id="shop_container">
            <img src="assets/img/shop/devanture.png" id="devanture" /><!-- faire un plus gros plan -->
            <div id="subcontainer">
                <h3>{{text['nom_site']}}</h3>
                <p>{{text['adresse']}}</p>
                <p>Tel: <a href="tel:{{text['num_tel']}}">{{text['num_tel']}}</a></p>
                <p>Email: <a href="mailto:{{text['mail']}}">{{text['mail']}}</a></p>
                <div id="horaires">
                    <p><b>horaires:</b></p>
                    <p id="txt">{{text['horaires']}}</p>
                </div>
            </div>
        </div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d928.8064087831963!2d55.61212442924048!3d-21.38101855030756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x21780e9d25365a5b%3A0xae48b9d2b0a64ee9!2s117%20Rue%20Raphael%20Babet%2C%20R%C3%A9union!5e0!3m2!1sfr!2sfr!4v1590688200445!5m2!1sfr!2sfr"
            frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
</ng-template>