import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LyDialog } from '@alyle/ui/dialog';
import { CropperDialog } from 'src/app/core/cropper/cropper-dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CarousselEditService } from 'src/app/services/caroussel-edit.service';
import { take, timeout } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { stringToArray } from 'ag-grid-community';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigCropperService } from 'src/app/services/config-cropper.service';
import { isNullOrUndefined } from 'util';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
import { DebugService } from 'src/app/services/debug.service';
@Component({
  selector: 'app-caroussel-edit-form',
  templateUrl: './caroussel-edit-form.component.html',
  styleUrls: ['./caroussel-edit-form.component.css']
})
export class CarousselEditFormComponent implements OnInit {
  cropped?: string;
  editControl: FormGroup;
  addControl: FormGroup;
  images;
  imgUpload;
  $isOpen = new BehaviorSubject<number>(-1);
  $finishedLoading = new BehaviorSubject<boolean>(false);
  $confirmShow = new BehaviorSubject<boolean>(false);
  $AllMsgs = new BehaviorSubject<any>([]);
  index = 0;
  constructor(
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef,
    private carousselS: CarousselEditService,
    private sanitizer: DomSanitizer,
    private cropperS: ConfigCropperService,
    private errorHandler: ErrorManagerService,
    private debugS: DebugService) {
    this.editControl = new FormGroup({
      titre: new FormControl('', Validators.required),
      sous_titre: new FormControl(''),
      lien: new FormControl(''),
      fichier: new FormControl()
    });
    this.addControl = new FormGroup({
      titre: new FormControl('', Validators.required),
      sous_titre: new FormControl(''),
      lien: new FormControl(''),
      fichier: new FormControl()
    })
    carousselS.provideData().pipe(take(1)).subscribe(res => {
      if (this.debugS.isDebug())
        console.log(res);
      this.images = res;
      this.$finishedLoading.next(true);
    }
      , err => { if (this.debugS.isDebug()) console.log(err); })
  }

  ngOnInit(): void {
  }
  openCropperDialog(event: Event) {
    this.cropped = null!;
    this.cropperS.$wichConfig.next('caroussel');
    this._dialog.open<CropperDialog, Event>(CropperDialog, {
      data: event,
      width: 1500,
      disableClose: true,
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {
      if (result) {
        this.cropped = result.dataURL;
        if(this.debugS.isDebug())
        console.log(JSON.stringify(this.cropped));

        this._cd.markForCheck();
      }
    });
  }

  afficherMsg(type, error?) {
    if (type === 'error') {
      if (isNullOrUndefined(error)) return false;
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ value: true, index: indexTemp, type: 'error', error: error })
      this.$AllMsgs.next(value);
      this.scrollToElement(document.querySelector('#list1-hm'));
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if(this.debugS.isDebug())
        console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    } else if (type === 'success') {
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ index: indexTemp, type: 'success' })
      this.$AllMsgs.next(value);
      this.scrollToElement(document.querySelector('#list1-hm'));
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if(this.debugS.isDebug())
        console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    }
  }

  scrollToElement($element): void {
    if(this.debugS.isDebug())
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  onClickCadres(index, id?) {
    if (this.$confirmShow.value) return;
    this.cropped = null;
    if (this.$isOpen.value === index) {
      this.$isOpen.next(-1);
    } else {
      this.$isOpen.next(index);
    }
    if (index === -1) return;
    setTimeout(() => {
      this.scrollToElement(document.querySelector(`#img${index}`));
    }, 50);
    if (id) {
      this.editControl = new FormGroup({
        titre: new FormControl(this.images[index].data.h3),
        sous_titre: new FormControl(this.images[index].data.p),
        lien: new FormControl(this.images[index].data.lien),
        fichier: new FormControl()
      });
    }
  }

  deleteActualSlide() {
    if(this.debugS.isDebug())
    console.log('click');

    if (this.$isOpen.value === -1 || this.$isOpen.value === -2) return;
    this.carousselS.deleteSlide(this.images[this.$isOpen.value].id).pipe(take(1)).subscribe(
      res => {
        if(this.debugS.isDebug())
        console.log(res);
        this.$finishedLoading.next(false);
        this.carousselS.provideData().pipe(take(1)).subscribe(res => {
          if(this.debugS.isDebug())
          console.log(res);
          this.images = res;
          this.$finishedLoading.next(true);
          this.afficherMsg('success');
          this.$confirmShow.next(false);
        });
      },
      err => {
        this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
      }
    )
  }

  addSlides() {
    if (this.$isOpen.value !== -2) return;
    if (!this.addControl.valid && isNullOrUndefined(this.cropped) || this.cropped === '') {
      this.afficherMsg('error', 'Le champ Titre et l\'image sont requis');
      return;
    }
    this.carousselS.pullData({
      img: this.cropped,
      alt: this.addControl.controls['titre'].value,
      h3: this.addControl.controls['titre'].value,
      p: this.addControl.controls['sous_titre'].value,
      lien: this.addControl.controls['lien'].value
    }).pipe(take(1)).subscribe(
      res => {
        if(this.debugS.isDebug())
        console.log(res);
        this.$finishedLoading.next(false);
        this.carousselS.provideData().pipe(take(1)).subscribe(res => {
          if(this.debugS.isDebug())
          console.log(res);
          this.images = res;
          this.cropped = '';
          this.$isOpen.next(-1);
          this.addControl = new FormGroup({
            titre: new FormControl('', Validators.required),
            sous_titre: new FormControl(''),
            lien: new FormControl(''),
            fichier: new FormControl()
          })
          this.afficherMsg('success');
          this.$finishedLoading.next(true);
          this.$confirmShow.next(false);
        });
      },
      err => {
        this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
      })
  }


  modidySlides(id, image) {
    if(this.debugS.isDebug()) {
    console.log(id);
    console.log('value :', this.$isOpen.value);
    }
    if (this.$isOpen.value === -2) return;
    let img = ''
    if (!isNullOrUndefined(this.cropped) && this.cropped !== '') {
      img = this.cropped
    } else {
      img = image.data.img.split(' ').join('+');
    }
    this.carousselS.pullData({
      img: img,
      alt: this.editControl.controls['titre'].value,
      h3: this.editControl.controls['titre'].value,
      p: this.editControl.controls['sous_titre'].value,
      lien: this.editControl.controls['lien'].value
    }, id).pipe(take(1)).subscribe(
      res => {
        if(this.debugS.isDebug())
        console.log(res);
        this.$finishedLoading.next(false);
        this.carousselS.provideData().pipe(take(1)).subscribe(res => {
          if(this.debugS.isDebug())
          console.log(res);
          this.images = res;
          this.$finishedLoading.next(true);
          this.afficherMsg('success');
        });
      },
      err => {
        this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
      })
  }
/*   handleUpload(event) {
    setTimeout(() => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if(this.debugS.isDebug())
        console.log(reader.result);
        this.imgUpload = reader.result;
      };
    }, 5000)

  } */

  getImage(dataImage) {
    const img = dataImage.data.img.split(' ').join('+');
    return this.sanitizer.bypassSecurityTrustStyle(`url(${img})`);
  }

}
