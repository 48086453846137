//#region imports
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { path, routes } from 'src/assets/routes';
import { BehaviorSubject, zip } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { environment } from 'src/environments/environment';
import { ThemeVariables, ThemeRef, lyl, StyleRenderer } from '@alyle/ui';
import { DebugService } from '../services/debug.service';
import { IsAdminService } from '../services/is-admin.service';
import { LoginformComponent } from '../core/login-register/loginform/loginform.component';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';

const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
  const __ = ref.selectorsOf(STYLES);
  return {
    $global: lyl`{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}
      }
    }`,
    root: lyl`{
      display: block
    }`
  };
};
//#endregion

//#region decorateur
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [StyleRenderer]
})
//#endregion

export class AppComponent implements OnInit {
  readonly classes = this.sRenderer.renderSheet(STYLES, true);
  public $isWindowOpen = new BehaviorSubject<boolean>(false);
  title = 'Tropik Vap';
  $isApiOk: BehaviorSubject<string> = new BehaviorSubject<string>('Loading');
  $MaintenanceMod = new BehaviorSubject<string>('Loading');
  screenHeight: number;
  screenWidth: number;
  constructor(readonly sRenderer: StyleRenderer,
    private router: Router,
    private http: HttpClient,
    private debugS: DebugService,
    private isAdmin: IsAdminService,
    private dialog: MatDialog,) { }

  ngOnInit() {
    this.isApi();
  }

  isApi() {
    const apis = zip(this.http.get(environment.hostUsersLoginRegister + 'health').pipe(take(1)), this.http.get(environment.hostUsersModify + 'health').pipe(take(1)), this.http.get(environment.hostAdmin + 'health').pipe(take(1)), this.http.get(environment.hostData + 'health').pipe(take(1)));
    apis.subscribe(res => {
      if (res[0] === 'API OK' && res[1] === 'API OK' && res[2] === 'API OK', res[3] === 'API OK') {
        this.$isApiOk.next('success');
        this.isMaintenanceMod();
        setTimeout(s => this.isApi(), 60000);
      } else {
        this.router.navigate([routes.accueil]);
        this.$isApiOk.next('error');
        setTimeout(s => this.isApi(), 10000);
      }
    },
      err => {
        this.router.navigate([routes.accueil]);
        this.$isApiOk.next('error');
        setTimeout(s => this.isApi(), 10000);
      });
  }

  isMaintenanceMod() {
    if (this.isAdmin.isAdmin()) {
      this.$MaintenanceMod.next('No')
    } else {
      this.http.get<any>(environment.hostData + 'getMaintenanceMod').pipe(take(1)).subscribe(res => { if (this.debugS.isDebug()) console.log(res); if (res.data === false) { this.$MaintenanceMod.next('No') } else { this.$MaintenanceMod.next('Yes') } }, err => { this.$MaintenanceMod.next('Yes') })
    }
  }
  showHeader() {
    const url = this.router.url;
    let ret = true;
    if (url.includes('dashboard')) {
      ret = false;
    }
    if (this.$isApiOk.value === 'error') {
      ret = false;
    }
    return ret;
  }
  showMenuDashboard() {
    /* return false; */
    const url = this.router.url;
    return url.includes(path.dashboard);
  }
  showFooter() {
    /* return false; */
    const url = this.router.url;
    return url.includes(path.dashboard);
  }
  clickLoginButton = () => {
    this.$isWindowOpen.next(true);
    if (this.debugS.isDebug()) {
      console.log(this.$isWindowOpen);
    }
    const dialogRef = this.dialog.open(LoginformComponent, { data: { isMaintenance: true } });
    dialogRef.afterClosed().subscribe(result => {
      this.$isWindowOpen.next(false);
      this.isMaintenanceMod();
    });
  }

  hasAlreadyAcceptedCookie() {
    let value = localStorage.getItem('acceptedCookie');
    if (!isNullOrUndefined(value)) {
      if (value === 'true') {
        return true;
      }
    }
    return false;
  }

  acceptCookie() {
    localStorage.setItem('acceptedCookie', 'true');
  }

  declinerCookie() {
    window.location.href = 'https://www.ecosia.org';
  }
}

