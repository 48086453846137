//#region imports
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, BehaviorSubject, zip } from 'rxjs';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { LyDialog } from '@alyle/ui/dialog';
import { CropperDialog } from '../cropper/cropper-dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper/image-cropper';
import { ConfigCropperService } from 'src/app/services/config-cropper.service';
import { SousCatService } from 'src/app/services/Products/sous-cat.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
import { routes } from 'src/assets/routes';
import { CookieService } from 'ngx-cookie-service';
import { createGzip } from 'zlib';
import { DebugService } from 'src/app/services/debug.service';
//#endregion
@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit, OnDestroy {
  //#region variables
  sub = new Subscription();
  caracteristiquePossible = [] /* [{ name: 'Poids', type: 'countable' }, { name: 'Taille', type: 'countable' }, { name: 'Marque', type: 'uncountable' }] */;
  // ce qui sera afficher apres la fonction parseCaracts();
  caracts = [];
  // form pour créer une nouvelle caract
  editForm: FormGroup;
  // l'image qu'on affiche apres upload
  cropped;
  // chamin sousCat
  sousCat: any;
  // si le bouton pour créer une nouvelle caract a été cliqué
  $isNewOpened = new BehaviorSubject<boolean>(false);
  // le type de la nouvelle caract qu'on veut crée
  $newOpenedType = new BehaviorSubject<string>('uncountable');
  // le formulaire de la nouvelle catacy
  addGroup: FormGroup;
  // quand on a chargé les infos du produits, les filtres, et les caracts
  $finishedLoading = new BehaviorSubject<boolean>(false);
  // chemin product
  product;
  // chemin cat
  cat;
  // info de produit
  productData;
  // warning quand on veut suprimer le produit
  $warningShow = new BehaviorSubject<boolean>(false);
  // warning quand on veut suprimer une cat
  $warningCatShow = new BehaviorSubject<boolean>(false);
  // warning quand on veut changer le type d'une cat
  $warningChangeShow = new BehaviorSubject<boolean>(false);
  // les notifs d'error/ success
  $AllMsgs = new BehaviorSubject<any>([]);
  // index du msg au dessus
  index = 0;
  // si on a chargé la page une premiere fois ( $finishedLoading se reset si on change de produit)
  initalLoading = false;
  // les infos des filtres
  filters;
  // selected caract pour quand on affiche le $WarningChangeShow
  selectedCaract;
  // form capacité
  capaciteForm: FormGroup;
  //#endregion
  constructor(private _dialog: LyDialog, private _cd: ChangeDetectorRef,
    private cropperS: ConfigCropperService,
    private sousCatS: SousCatService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    private errorHandler: ErrorManagerService,
    private cookieS: CookieService,
    private debugS: DebugService) {
    //#region recuperation des parametres du chemins
    this.sousCat = this.route.snapshot.paramMap.get('souscat');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.sousCat = param.get('souscat');
      if (this.initalLoading)
        this.loadProperties();
      this.reload();
    }));
    this.cat = this.route.snapshot.paramMap.get('cat');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.cat = param.get('cat');
      if (this.initalLoading)
        this.loadProperties();
      this.reload();
    }));
    this.product = this.route.snapshot.paramMap.get('product');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.product = param.get('product');
      if (this.initalLoading)
        this.loadProperties();
      this.reload();
    }));
    //#endregion
    if (this.debugS.isDebug())
      console.log(`/cat/${this.cat}/${this.sousCat}/${this.product}`);
    this.initForm();
    this.loadProperties();
    this.editForm.valueChanges.subscribe((s) => this.onChangeTextArea())
  }

  ngOnInit(): void {
    setTimeout(this.onChangeTextArea, 100);
  }
  // si la fenetre change on reset les variables
  reload() {
    this.$isNewOpened = new BehaviorSubject<boolean>(false);
    this.$newOpenedType = new BehaviorSubject<string>('uncountable');
    this.$warningShow = new BehaviorSubject<boolean>(false);
    this.$warningChangeShow = new BehaviorSubject<boolean>(false);
    this.$AllMsgs = new BehaviorSubject<any>([]);
    this.index = 0;
    this.selectedCaract = null;
  }
  // initialise / reinitialise les FormGroup
  initForm() {
    this.editForm = new FormGroup({
      nomProduct: new FormControl('', Validators.required),
      link: new FormControl(''),
      desc: new FormControl(''),
      bigDesc: new FormControl(''),
      price: new FormControl('', Validators.required),
      promo: new FormControl('', Validators.required),
      formatsLiquides: new FormControl('')
    })
    this.addGroup = new FormGroup({
      caractName: new FormControl('', Validators.required),
      value: new FormControl(''),
      unit: new FormControl(''),
      isFilter: new FormControl(false),
      isOnlyOne: new FormControl(false)
    })
    if (!this.initalLoading) {
      this.sub.add(this.editForm.controls['nomProduct'].valueChanges.subscribe(s => {
        this.editForm.controls['link'].setValue(this.parseUrl(this.editForm.controls['nomProduct'].value));
      }))
    }
    this.capaciteForm = new FormGroup({
      0: new FormControl(false),
      3: new FormControl(false),
      6: new FormControl(false),
      10: new FormControl(false),
      11: new FormControl(false),
      12: new FormControl(false),
      16: new FormControl(false),
      18: new FormControl(false),
      20: new FormControl(false),
    });

    /*  this.sub.add(this.capaciteForm.valueChanges.subscribe(s => this.getSelectedCheckBox(this.capaciteForm.value))); */
  }

  //change le lien de l'url
  parseUrl(str) {
    let str2 = this.removeAccents(str).split(/ +/).join('_').toLowerCase();
    if (this.debugS.isDebug())
      console.log(str2);
    return str2.replace(/\W/g, '').replace(/https?:\/\/[^\/]+/i, "").replace(/http?:\/\/[^\/]+/i, "");
  }

  getSelectedCheckBox(form: AbstractControl) {
    let selected = [];
    for (let i in form) {
      if (form[i]) {
        selected.push(i);
      }
    }
    if (selected.length === 0) {
      return 'reset';
    } else {
      return selected;
    }
  }

  removeAccents(str) {
    var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split('');
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join('');
  }

  // fetch les filtres pour cette catégorie
  loadFilters() {
    this.sub.add(this.sousCatS.provideFilter(this.sousCat).pipe(take(1)).subscribe(res => {
      this.filters = res.data;
      if (this.debugS.isDebug())
        console.log(this.filters);
      this.parseCaractPossible();
      this.$finishedLoading.next(true);

    }, err => {
      if (this.debugS.isDebug()) {
        console.log(err);
        console.log(this.errorHandler.parseError('product-edit', err.error.errorCode, err.error.arg, err.error.error));
      }
      this.redirect();
    }))
  }

  // change le type de la caract, change le filtre et push le tout
  changeCaractType() {
    if (this.debugS.isDebug())
      console.log('founded filter : ');
    if (this.caracts[this.caracts.indexOf(this.selectedCaract)].type === 'countable') {
      this.caracts[this.caracts.indexOf(this.selectedCaract)].type = 'uncountable';
      if (!isNullOrUndefined(this.filters[this.filters.indexOf(this.filters.filter(s => s.titre === this.selectedCaract.key)[0])])) {
        this.filters[this.filters.indexOf(this.filters.filter(s => s.titre === this.selectedCaract.key)[0])].type = 'checkbox';
      }
    } else if (this.caracts[this.caracts.indexOf(this.selectedCaract)].type === 'uncountable') {
      this.caracts[this.caracts.indexOf(this.selectedCaract)].type = 'countable';
      this.caracts[this.caracts.indexOf(this.selectedCaract)].unit = '';
      if (!isNullOrUndefined(this.filters[this.filters.indexOf(this.filters.filter(s => s.titre === this.selectedCaract.key)[0])])) {
        this.filters[this.filters.indexOf(this.filters.filter(s => s.titre === this.selectedCaract.key)[0])].type = 'slider';
      }
    }
    this.sub.add(this.sousCatS.changeCaract(this.selectedCaract.id, this.sousCat, this.selectedCaract.key, this.caracts[this.caracts.indexOf(this.selectedCaract)].type, this.caracts[this.caracts.indexOf(this.selectedCaract)].unit).pipe(take(1)).subscribe(res => {
      if (this.debugS.isDebug())
        console.log(res);
      this.selectedCaract = null
      this.sub.add(this.sousCatS.pullFilter(this.sousCat, this.filters, this.cat).pipe(take(1)).subscribe(res => { if (this.debugS.isDebug()) console.log(res); }, err => { if (this.debugS.isDebug()) console.log(err); }));
    }, err => { if (this.debugS.isDebug()) console.log(err); this.selectedCaract = null }))
    this.$warningChangeShow.next(false);
  }

  // change le nom d'une caract
  changeCaractName(caract, $event) {
    /*     if (this.filters.indexOf(this.filters.filter(s => s.titre === caract.key)[0])) {
          this.filters[this.filters.indexOf(this.filters.filter(s => s.titre === caract.key)[0])].titre = caract.key;
        }
        if (this.filters.indexOf(this.filters.filter(s => s.titre === caract.key)[0])) {
          let pushes = zip(this.sousCatS.pullCaract(this.sousCat, $event.target.value, caract.type, caract.unit), this.sousCatS.pullFilter(this.sousCat, this.filters, this.cat));
          this.sub.add(pushes.pipe(take(1)).subscribe(res => {
            console.log(res)
            caract.key = $event.target.value;
            this.sub.add(this.sousCatS.removeCaract(caract.id).pipe(take(1)).subscribe(res => { console.log(res) }, err => { console.log(err) }))
          }, err => { console.log(err) }));
        } else {
          this.sub.add(this.sousCatS.pullCaract(this.sousCat, $event.target.value, caract.type, caract.unit).pipe(take(1)).subscribe(res => {
            console.log(res)
            caract.key = $event.target.value;
            this.sub.add(this.sousCatS.removeCaract(caract.id).pipe(take(1)).subscribe(res => { console.log(res) }, err => { console.log(err) }))
          }, err => { console.log(err) }))
        } */
  }

  // chargement initial, charges en premeir les caracteristiques possibles de cette catégorie et charge les informations du produit si il existe
  loadProperties() {
    this.$finishedLoading.next(false);
    this.sub.add(this.sousCatS.provideCaract(this.sousCat).pipe(take(1)).subscribe(res => {
      if (this.debugS.isDebug())
        console.log(res);
      let resP: any = res;
      this.caracteristiquePossible = resP
      if (!isNullOrUndefined(this.product) && this.product !== '') {
        if (this.product === 'new') {
          this.productData = {};
          this.cropped = null;
          this.initForm();
          this.initalLoading = true;
          this.loadFilters();
        } else {
          this.sub.add(this.sousCatS.provideProductByLien(this.product, false).pipe(take(1)).subscribe(res => {
            this.productData = res;
            if (this.debugS.isDebug()) {
              console.log('resP :')
              console.log(resP);
            }
            this.editForm.controls['nomProduct'].setValue(this.productData.h3);
            this.editForm.controls['link'].setValue(this.productData.lien);
            this.editForm.controls['desc'].setValue(this.productData.p);
            this.editForm.controls['bigDesc'].setValue(this.productData.BigP);
            this.editForm.controls['price'].setValue(this.productData.prix);
            if (this.productData.prix !== this.productData.promo) {
              this.editForm.controls['promo'].setValue(this.productData.promo);
            }
            this.checkCapacite(this.productData.capacite);
            this.cropped = this.productData.img;
            if (!isNullOrUndefined(this.productData['attributs']) && this.productData['attributs'] !== '') {
              this.productData['attributs'] = JSON.parse(this.productData['attributs']);
              if (this.debugS.isDebug())
                console.log('product attributs :', this.productData['attributs']);
            }
            this.loadFilters();
            setTimeout(this.onChangeTextArea, 100);
            this.initalLoading = true;
          }, err => {
            if (this.debugS.isDebug()) {
              console.log(err);
              console.log(this.errorHandler.parseError('product-edit', err.error.errorCode, err.error.arg, err.error.error));
            }
            this.redirect();
          }))
        }
      } else {
        this.redirect();
      }
    }, err => {
      if (this.debugS.isDebug()) {
        console.log(err);
        console.log(this.errorHandler.parseError('product-edit', err.error.errorCode, err.error.arg, err.error.error));
      }
      this.redirect();
    }))
  }

  //precheck capacité
  checkCapacite(data) {
    if (!isNullOrUndefined(data)) {
      console.log(data.split(','));
      for (let d of data.split(',')) {
        console.log(d);
        if (!isNullOrUndefined(this.capaciteForm.controls[d])) this.capaciteForm.controls[d].setValue(true);
      }
    }
  }

  // redirect sur la page not found avec un cookie pour savoir d'ou on viens
  redirect() {
    if (this.debugS.isDebug())
      console.log('redirecting...');
    let tDate = new Date(Date.now());
    tDate.setSeconds(tDate.getSeconds() + 60);
    this.cookieS.set('redirectFrom', this.router.url, tDate);

    this.router.navigate([routes.notfound]);
  }

  // ajoute la nouvelle caracteristiques a la liste des caractéristiques et push
  onSubmitNewCaract() {
    if (this.debugS.isDebug())
      console.log(this.addGroup);
    if (this.addGroup.valid) {
      this.sub.add(this.sousCatS.pullCaract(this.sousCat, this.addGroup.controls['caractName'].value, this.$newOpenedType.value, this.addGroup.controls['unit'].value).pipe(take(1)).subscribe(
        res => {
          if (this.debugS.isDebug())
            console.log(res);
          this.caracts.push({ id: res.id, key: this.addGroup.controls['caractName'].value, value: this.addGroup.controls['value'].value, type: this.$newOpenedType.value, unit: this.addGroup.controls['unit'].value })
          this.$isNewOpened.next(false);
          this.addGroup = new FormGroup({
            caractName: new FormControl('', Validators.required),
            value: new FormControl(''),
            unit: new FormControl(''),
            isFilter: new FormControl(false),
            isOnlyOne: new FormControl(false)
          });
        }, err => {
          if (this.debugS.isDebug()) {
            console.log(err);
            console.log(this.errorHandler.parseError('product-edit', err.error.errorCode, err.error.arg, err.error.error));
          }
        }));
      if (this.$newOpenedType.value === 'uncountable') {
        /* this.filters.push({ type: 'checkbox', titre: this.addGroup.controls['caractName'].value, isClicked: true, isLocked: false, value: 0, highValue: 0, options: { floor: 0, ceil: 0, animate: false} }) */
      } else if (this.$newOpenedType.value === 'countable') {

      }
    }
  }

  // quand on appuie sur le bouton enregistrer ou creer un autre
  onSubmit(createdAnOther?) {
    // si le produit existe deja on le modife sinon on le crée
    if (!isNullOrUndefined(this.productData.id) && this.productData.id !== '') {
      let img;
      if (!isNullOrUndefined(this.cropped) && this.cropped !== '') {
        img = this.cropped;
      }
      this.sousCatS.changeProduct(
        this.productData.id,
        this.editForm.controls['link'].value,
        this.sousCat,
        this.editForm.controls['nomProduct'].value,
        this.editForm.controls['desc'].value,
        this.editForm.controls['bigDesc'].value,
        this.editForm.controls['price'].value,
        img,
        this.formatCaract(),
        this.editForm.controls['promo'].value,
        this.getSelectedCheckBox(this.capaciteForm.value)
      ).pipe(take(1)).subscribe(res => {
        if (this.debugS.isDebug())
          console.log(res);
        /* this.afficherMsg('success'); */
        if (!isNullOrUndefined(createdAnOther) && createdAnOther !== '') {
          if (createdAnOther) {
            if (this.router.url.split('/').includes('new')) {
              this.loadProperties();
              this.reload();
            } else {
              // ici on change juste le chemin la subscription sur les parametres se charge de tout recharger
              let url = this.router.url.split('/');
              url.pop();
              url.pop();
              url.shift();
              url.push('new');
              url.push('edit');
              if (this.debugS.isDebug())
                console.log(url);
              this.router.navigate([url.join('/')]);
            }
          } else {
            // ici on change juste le chemin la subscription sur les parametres se charge de tout recharger
            let url = this.router.url.split('/');
            url.pop();
            url.shift();
            url.pop();
            this.router.navigate([url.join('/'), this.editForm.controls['link'].value]);
          }
        } else {
          // ici on change juste le chemin la subscription sur les parametres se charge de tout recharger
          let url = this.router.url.split('/');
          url.pop();
          url.shift();
          url.pop();
          this.router.navigate([url.join('/'), this.editForm.controls['link'].value]);
        }
      }, err => {
        this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
      })
    } else {
      this.sousCatS.pullProduct(
        this.sousCat,
        this.editForm.controls['link'].value,
        this.editForm.controls['nomProduct'].value,
        this.editForm.controls['price'].value,
        this.cropped,
        this.formatCaract(),
        this.editForm.controls['promo'].value,
        this.editForm.controls['desc'].value,
        this.editForm.controls['bigDesc'].value,
        this.getSelectedCheckBox(this.capaciteForm.value)
      ).pipe(take(1)).subscribe(res => {
        if (this.debugS.isDebug())
          console.log(res);
        /* this.afficherMsg('success'); */
        if (!isNullOrUndefined(createdAnOther) && createdAnOther !== '') {
          if (createdAnOther) {
            if (this.router.url.split('/').includes('new')) {
              this.loadProperties();
            } else {
              let url = this.router.url.split('/');
              url.pop();
              url.pop();
              url.shift();
              url.push('new');
              url.push('edit');
              if (this.debugS.isDebug())
                console.log(url);
              this.router.navigate([url.join('/')]);
            }
          } else {
            let url = this.router.url.split('/');
            url.pop();
            url.shift();
            url.pop();
            this.router.navigate([url.join('/'), this.editForm.controls['link'].value]);
          }
        } else {
          let url = this.router.url.split('/');
          url.pop();
          url.shift();
          url.pop();
          this.router.navigate([url.join('/'), this.editForm.controls['link'].value]);
        }
      }, err => {
        this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
      })
    }
  }

  // on formate toutes les caracteristiques pour les stocker dans la colonne attributs 
  formatCaract() {
    let ret = [];
    for (let caract of this.caracts) {
      console.log(caract);
      if (!isNullOrUndefined(caract.value) && caract.value !== "") {
        /* ret[caract.key] = caract.value; */
        if (caract.type === 'countable') {
          ret.push({ id: caract.id, name: caract.key, value: caract.value, type: caract.type, unit: caract.unit })
        } else {
          ret.push({ id: caract.id, name: caract.key, value: caract.value, type: caract.type })
        }
      }
    }
    console.log(JSON.stringify(ret));
    return ret;
  }

  // redemensionnement auto des zones de text ( merci gign :innocent: )
  onChangeTextArea() {
    let textAreas = document.getElementsByTagName('textarea');
    // regular for loop 
    for (let i = 0; i < textAreas.length; i++) {
      textAreas[i].style.overflow = 'hidden';
      textAreas[i].style.height = '0px';
      textAreas[i].style.height = textAreas[i].scrollHeight + 'px';
    }
  }

  // une fois qu'on a chargés les caracteristiques possible on les parses pour pouvoir les affichée correctement
  parseCaractPossible() {
    this.caracts = [];
    for (let caract of this.caracteristiquePossible) {
      let value = '';
      let unit = '';
      let filterable = false;
      let onlyOne = false;
      if (!isNullOrUndefined(this.productData)) {
        // pour eviter l'erreur this.productData['attributs'] is undefined
        try {
          value = this.productData['attributs'].filter(s => s.name === caract.name)[0].value;
          if (this.debugS.isDebug())
            console.log('value :', value);
        } catch (t) {
          value = '';
          if (this.debugS.isDebug())
            console.log(`catched error : ${t}`);
        }
      }

      let filterTemp;
      try {
        filterTemp = this.filters.filter(s => s.titre === caract.name)[0];
      } catch (t) {
        if (this.debugS.isDebug())
          console.log(`parsed error : ${t}`);
      }
      if (this.debugS.isDebug()) {
        console.log('filter temp :')
        console.log(filterTemp)
      }
      if (!isNullOrUndefined(filterTemp) || filterTemp === "") {
        if (this.debugS.isDebug())
          console.log('filter exist !');
        filterable = true;
        if (filterTemp.type === 'checkbox') {
          onlyOne = filterTemp.onlyOne;
        }
      }
      try {
        unit = this.caracteristiquePossible.filter(s => s.name === caract.name)[0].unit
      } catch (t) {
        unit = '';
      }
      if (isNullOrUndefined(unit)) {
        unit = '';
      }
      this.caracts.push({ id: caract.id, key: caract.name, value: value, type: caract.type, unit: unit, filterable: filterable, onlyOne: onlyOne });
    }
    if (this.debugS.isDebug()) {
      console.log('caracts : ')
      console.log(this.caracts)
    }
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // on ajoute/modifie/supprime le filtre et caract 
  changeFilterableOrOnlyOne(caract, type, $event) {
    if (this.debugS.isDebug())
      console.log($event);
    if (type === 'filterable') {
      caract.filterable = $event.target.checked;
      if (caract.filterable) {
        let typeTemp = 'checkbox';
        if (caract.type === 'uncountable') {
          typeTemp = 'checkbox';
        } else if (caract.type === 'countable') {
          typeTemp = 'slider'
        }
        this.filters.push({ highValue: 100, isClicked: true, isLocked: false, onlyOne: caract.onlyOne, isProductAttributes: true, options: { floor: 0, ceil: 100, animate: false }, productValue: caract.key, titre: caract.key, type: typeTemp, value: 0 })
      } else {
        let filter = this.filters.filter(s => s.titre === caract.key)[0]
        if (!isNullOrUndefined(filter) && filter !== '') {
          this.filters = this.filters.filter(s => s.titre !== caract.key);
        }
      }
    } else if (type === 'onlyOne') {
      caract.onlyOne = $event.target.checked;
      let filter = this.filters.filter(s => s.titre === caract.key)[0]
      if (!isNullOrUndefined(filter) && filter !== '') {
        this.filters[this.filters.indexOf(filter)].onlyOne = caract.onlyOne;
      }
    }
    this.sub.add(this.sousCatS.pullFilter(this.sousCat, this.filters, this.cat).pipe(take(1)).subscribe(res => { if (this.debugS.isDebug()) console.log(res); $event.target.checked }, err => { if (this.debugS.isDebug()) console.log(err); caract.filterable = !caract.filterable }));
  }


  // appelé si une valeur ou une unité est changé, si c'est une unité on la push dans la table caractpossibles
  onCaractChange(caract, value, valueName) {
    if (valueName === 'unit') {
      try {
        caract.unit = value;
        this.sub.add(this.sousCatS.changeCaract(caract.id, this.sousCat, caract.key, caract.type, caract.unit).pipe(take(1)).subscribe(res => {

        }, err => {

        }))
      } catch (t) {
        if (this.debugS.isDebug())
          console.log(`catched error : ${t}`);
      }
    } else if (valueName === 'value') {
      if (caract.type === 'uncountable') {
        caract.value = value;
      } else if (caract.type === 'countable') {
        try {
          let num: number = value;
          caract.value = num;
        } catch (t) {
          if (this.debugS.isDebug())
            console.log(`catched error : ${t}`);
        }
      }
    }
  }

  // pour ouvrir le cropper pour changer l'image
  openCropperDialog(event: Event) {
    this.cropped = null!;
    this.cropperS.$wichConfig.next('product');
    this._dialog.open<CropperDialog, Event>(CropperDialog, {
      data: event,
      width: 1500,
      disableClose: true
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {
      if (result) {
        this.cropped = result.dataURL;
        if (this.debugS.isDebug())
          console.log(JSON.stringify(this.cropped));

        this._cd.markForCheck();
      }
    });
  }

  // pour supprimer une caracteristiques on l'enleve de la liste caracts et on demande a l'API de l'enlever
  supprCaract() {
    let caractTemp = this.selectedCaract;
    this.selectedCaract = null;
    this.$warningCatShow.next(false);
    if (isNullOrUndefined(caractTemp)) return;
    if (!isNullOrUndefined(caractTemp.id) && caractTemp.id !== '') {
      this.sousCatS.removeCaract(caractTemp.id).pipe(take(1)).subscribe(res => {
        if (this.debugS.isDebug())
          console.log(res);
        this.caracts = this.caracts.filter((s) => s.id !== caractTemp.id);
        if (this.debugS.isDebug())
          console.log('caract :', this.caracts);
      }, err => {
        if (this.debugS.isDebug()) {
          console.log(err);
          console.log(this.errorHandler.parseError('product-edit', err.error.errorCode, err.error.arg, err.error.error));
        }
      })
    }
  }

  // parse le lien en b64 et bypass la protection d'angular ( safe vu que ça viens de alyle )
  getImage(dataImage) {
    if (!isNullOrUndefined(dataImage) && dataImage !== '') {
      const dataImageP = dataImage.split(' ').join('+');
      return this.sanitizer.bypassSecurityTrustUrl(`${dataImageP}`);
    }
    return '';
  }

  // heu Scroll. To. Element.
  scrollToElement($element): void {
    if (this.debugS.isDebug())
      console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  // pour afficher les notifs au dessus
  afficherMsg(type, error?) {
    if (type === 'error') {
      if (isNullOrUndefined(error)) return false;
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ value: true, index: indexTemp, type: 'error', error: error })
      this.$AllMsgs.next(value);
      this.scrollToElement(document.querySelector('#enHaut'));
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if (this.debugS.isDebug())
          console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
        this.scrollToElement(document.querySelector('#enHaut'));
      }, 3000)
    } else if (type === 'success') {
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ index: indexTemp, type: 'success' })
      this.$AllMsgs.next(value);
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if (this.debugS.isDebug())
          console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    }
  }

  // pour supprimer le produit
  delete() {
    if (!isNullOrUndefined(this.productData.id) && this.productData.id !== '') {
      this.sub.add(this.sousCatS.removeProduct(this.productData.id).pipe(take(1)).subscribe(
        res => {
          let url = this.router.url.split('/');
          url.pop();
          url.pop();
          url.shift();
          this.router.navigate([url.join('/')]);
        },
        err => {
          this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
        }))
    }
  }
}
