import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TextService } from 'src/app/services/text.service';
import { zip, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-info-edit',
  templateUrl: './info-edit.component.html',
  styleUrls: ['./info-edit.component.css']
})
export class InfoEditComponent implements OnInit {
  $finishedLoading = new BehaviorSubject<boolean>(false);
  editControl: FormGroup;
  sub = new Subscription();
  $AllMsgs = new BehaviorSubject<any>([]);
  index = 0;
  $isAfficheSucces = new BehaviorSubject<boolean>(false);
  constructor(private textS: TextService, private errorHandler: ErrorManagerService, private debugS: DebugService) {
    this.$finishedLoading.next(false);
    let texts = zip(textS.provideData('contacts'), textS.provideData('mentionslegales'))
    this.sub.add(texts.subscribe(res => {
      if(this.debugS.isDebug()) {
      console.log(res);
      console.log(res[0]['nom_site'])
      }
      this.initForm(res)
      this.$finishedLoading.next(true);
    },
      err => {
        if(this.debugS.isDebug())
        console.log(err);
      }));
  }

  ngOnInit() {
  }

  /*   isAfficheSuccess() {
      let r = this.$msgValide.value.filter(s => s.value === true);
      console.log('r :', r)
      if (r.length === 0) {
        return false;
      }
      return true;
    } */

  initForm(res) {
    // , Validators.required
    this.editControl = new FormGroup({
      Nom_Site: new FormControl(res[0]['nom_site'], Validators.required),
      address: new FormControl(res[0]['adresse'], Validators.required),
      tel: new FormControl(res[0]['num_tel'], Validators.required),
      mail: new FormControl(res[0]['mail'], Validators.required),
      horaire: new FormControl(res[0]['horaires'], Validators.required),
      editeur_du_site: new FormControl(res[1]['editeur_du_site'], Validators.required),
      responsable_de_publication: new FormControl(res[1]['responsable_de_publication'], Validators.required),
      adresse_site: new FormControl(res[1]['adresse_site'], Validators.required),
      nom_hebergeur: new FormControl(res[1]['nom_hebergeur'], Validators.required),
      site_hebergeur: new FormControl(res[1]['site_hebergeur'], Validators.required),
      noms_devs: new FormControl(res[1]['noms_devs'], Validators.required),
      site_devs: new FormControl(res[1]['site_devs'], Validators.required),
      adresse_hebergeur: new FormControl(res[1]['adresse_hebergeur'], Validators.required),
      numero_siret: new FormControl(res[1]['numero_siret'], Validators.required)
    });
  }
  scrollToElement($element): void {
    if(this.debugS.isDebug())
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }


  afficherMsg(type, error?) {
    if (type === 'error') {
      if (isNullOrUndefined(error)) return false;
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ value: true, index: indexTemp, type: 'error', error: error })
      this.$AllMsgs.next(value);
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if(this.debugS.isDebug())
        console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    } else if (type === 'success') {
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ index: indexTemp, type: 'success' })
      this.$AllMsgs.next(value);
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if(this.debugS.isDebug())
        console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    }
  }

  onSubmit() {
    if(this.debugS.isDebug()) {
    console.log('click')
    console.log(this.editControl);
    }
    this.scrollToElement(document.querySelector('#horizontal_menu'));
    let pushContact = false, pushMentionsLegal = false;
    if (this.editControl.valid) {
      if (this.editControl.controls['address'].dirty ||
        this.editControl.controls['horaire'].dirty ||
        this.editControl.controls['mail'].dirty ||
        this.editControl.controls['Nom_Site'].dirty ||
        this.editControl.controls['tel'].dirty) {
        pushContact = true;
      }
      if (this.editControl.controls['address'].dirty ||
        this.editControl.controls['adresse_hebergeur'].dirty ||
        this.editControl.controls['adresse_site'].dirty ||
        this.editControl.controls['editeur_du_site'].dirty ||
        this.editControl.controls['mail'].dirty ||
        this.editControl.controls['nom_hebergeur'].dirty ||
        this.editControl.controls['noms_devs'].dirty ||
        this.editControl.controls['numero_siret'].dirty ||
        this.editControl.controls['responsable_de_publication'].dirty ||
        this.editControl.controls['site_devs'].dirty ||
        this.editControl.controls['site_hebergeur'].dirty ||
        this.editControl.controls['tel'].dirty) {
        pushMentionsLegal = true;
      }
      if (pushContact && !pushMentionsLegal) {
        this.textS.pullData('contacts', {
          adresse: this.editControl.controls['address'].value,
          horaires: this.editControl.controls['horaire'].value,
          mail: this.editControl.controls['mail'].value,
          nom_site: this.editControl.controls['Nom_Site'].value,
          num_tel: this.editControl.controls['tel'].value
        }).pipe(take(1)).subscribe(res => {
          if (res === "Succesfully updated contacts") {
            /*  this.afficherMsgValider() */
            this.afficherMsg('success');
          } else {
            /* this.afficherMsgError('Une Erreur') */
            this.afficherMsg('error', 'Une Erreur fatale est survenue');
          }
        }, err => {
          this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
        });
      } else if (!pushContact && pushMentionsLegal) {
        this.textS.pullData('mentionslegales', {
          adresse: this.editControl.controls['address'].value,
          adresse_hebergeur: this.editControl.controls['adresse_hebergeur'].value,
          adresse_site: this.editControl.controls['adresse_site'].value,
          editeur_du_site: this.editControl.controls['editeur_du_site'].value,
          mail_shop: this.editControl.controls['mail'].value,
          nom_hebergeur: this.editControl.controls['nom_hebergeur'].value,
          noms_devs: this.editControl.controls['noms_devs'].value,
          numero_siret: this.editControl.controls['numero_siret'].value,
          responsable_de_publication: this.editControl.controls['responsable_de_publication'].value,
          site_devs: this.editControl.controls['site_devs'].value,
          site_hebergeur: this.editControl.controls['site_hebergeur'].value,
          telephone: this.editControl.controls['tel'].value
        }).pipe(take(1)).subscribe(res => {
          if (res === "Succesfully updated mentionslegales") {
            /* this.afficherMsgValider() */
            this.afficherMsg('success');
          } else {
            this.afficherMsg('error', 'une erreur...Bla bla');
          }
        }, err => { this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error)); });
      } else if (pushContact && pushMentionsLegal) {
        let resZip = zip(this.textS.pullData('contacts', {
          adresse: this.editControl.controls['address'].value,
          horaires: this.editControl.controls['horaire'].value,
          mail: this.editControl.controls['mail'].value,
          nom_site: this.editControl.controls['Nom_Site'].value,
          num_tel: this.editControl.controls['tel'].value
        }).pipe(take(1)), this.textS.pullData('mentionslegales', {
          adresse: this.editControl.controls['address'].value,
          adresse_hebergeur: this.editControl.controls['adresse_hebergeur'].value,
          adresse_site: this.editControl.controls['adresse_site'].value,
          editeur_du_site: this.editControl.controls['editeur_du_site'].value,
          mail_shop: this.editControl.controls['mail'].value,
          nom_hebergeur: this.editControl.controls['nom_hebergeur'].value,
          noms_devs: this.editControl.controls['noms_devs'].value,
          numero_siret: this.editControl.controls['numero_siret'].value,
          responsable_de_publication: this.editControl.controls['responsable_de_publication'].value,
          site_devs: this.editControl.controls['site_devs'].value,
          site_hebergeur: this.editControl.controls['site_hebergeur'].value,
          telephone: this.editControl.controls['tel'].value
        }).pipe(take(1)));
        resZip.subscribe(res => {
          if (res[1] === "Succesfully updated mentionslegales" && res[0] === "Succesfully updated contacts") {
            /* this.afficherMsgValider() */
            this.afficherMsg('success');
          } else {
            this.afficherMsg('error', 'une erreur...Bla bla');
          }
        }, err => { this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error)); })
      }
    }
  }
}
