import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TextService } from 'src/app/services/text.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DebugService } from 'src/app/services/debug.service';
import { FormGroup, FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { routes } from 'src/assets/routes';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.scss']
})
export class FaqEditComponent implements OnInit {
  sub = new Subscription();
  $finishedLoading = new BehaviorSubject<boolean>(false);
  data: any | [];

  constructor(private textS: TextService, public router: Router, private debugS: DebugService, private cookieS: CookieService) {
    this.sub.add(textS.provideData('faq').pipe(take(1)).subscribe(res => {
      this.data = res;
      this.initForm();
      res[0]['isClicked'] = true;
      this.$finishedLoading.next(true);
      setTimeout(this.onChangeTextArea, 100);
    },
      err => {
        if (this.debugS.isDebug())
          console.log(err);
        this.redirect();
      })
    )
  }

  initForm() {
    for (let item of this.data) {
      if (isNullOrUndefined(item.formG)) {
        item.formG = new FormGroup({
          titre: new FormControl(''),
          sous_titre: new FormControl(''),
          text: new FormControl('')
        })
        this.sub.add(item.formG.valueChanges.subscribe(() => {
          this.onChangeTextArea();
        }));
        item.formG.controls['titre'].setValue(item.h1);
        item.formG.controls['sous_titre'].setValue(item.resume);
        item.formG.controls['text'].setValue(item.p);
      }
    }
  }

  finished() {
    let endData = [];
    for (let item of this.data) {
      endData.push({ h1: item.formG.controls['titre'].value, resume: item.formG.controls['sous_titre'].value, p: item.formG.controls['text'].value })
    }
    if (this.debugS.isDebug()) console.log(endData);
    this.sub.add(this.textS.pullData('faq', endData).pipe(take(1)).subscribe(res => { this.router.navigate([routes.faq]), err => { } }));
  }

  ngOnInit(): void {
  }
  supprimer(item) {
    if (this.debugS.isDebug()) console.log(item);
    this.data.splice(this.data.indexOf(item), 1);
    this.initForm();
  }
  ajouter() {
    if (this.debugS.isDebug()) console.log('click');
    this.data.push({ h1: '', resume: '', p: '' });
    this.initForm();
  }

  onChangeTextArea() {
    let textAreas = document.getElementsByTagName('textarea');
    // regular for loop 
    for (let i = 0; i < textAreas.length; i++) {
      textAreas[i].style.overflow = 'hidden';
      textAreas[i].style.height = '0px';
      textAreas[i].style.height = textAreas[i].scrollHeight + 'px';
    }
  }
  redirect() {
    if (this.debugS.isDebug())
      console.log('redirecting...');
    let tDate = new Date(Date.now());
    tDate.setSeconds(tDate.getSeconds() + 60);
    this.cookieS.set('redirectFrom', this.router.url, tDate);

    this.router.navigate([routes.notfound]);
  }
}
