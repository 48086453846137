import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { IsAdminService } from 'src/app/services/is-admin.service';
import { CatDataService } from 'src/app/services/Products/cat-data.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { routes } from 'src/assets/routes';
import { Subscription } from 'rxjs';
import { SousCatService } from 'src/app/services/Products/sous-cat.service';
import { DebugService } from 'src/app/services/debug.service';
import { TextService } from 'src/app/services/text.service';
@Component({
  selector: 'app-e404',
  templateUrl: './e404.component.html',
  styleUrls: ['./e404.component.css']
})
export class E404Component implements OnInit {
  sub = new Subscription();
  redirectFrom: string;
  isAdmin: boolean = false;
  constructor(
    private cookieS: CookieService,
    private adminS: IsAdminService,
    private dataS: CatDataService,
    private router: Router,
    private sousCatS: SousCatService,
    private debugS: DebugService,
    private textS: TextService) { }

  ngOnInit() {
    this.redirectFrom = this.cookieS.get('redirectFrom');
    this.isAdmin = this.adminS.isAdmin();
    this.cookieS.delete('redirectFrom');
  }
  afficherBouton() {
    if (this.isAdmin && this.redirectFrom !== null && this.redirectFrom !== '') {
      let args = this.redirectFrom.split('/');
      args.shift()
      if (this.debugS.isDebug()) console.log(args);
      if (args.length === 1) {
        if (args[0] === 'faq')
          return true;
      }
      if (args.length === 2 || args.length === 3) {
        return true;
      }
    }
    return false;
  }
  onClick() {
    let args = this.redirectFrom.split('/');
    args.shift()
    if (this.debugS.isDebug())
      console.log(args);
    if (args.length === 1) {
      if (args[0] === 'faq') {
        this.textS.pullData('faq', [{ h1: 'Titre', resume: 'Sous Titre', p: 'Texte' }]).pipe(take(1)).subscribe(res => { this.router.navigate([routes.faq]) }, err => { if (this.debugS.isDebug()) console.log(err); });
      }
    }
    if (args.length === 2) {
      let obj = {
        1: { isEnable: true, textH1: '', textP: '' },
        2: {
          isEnable: true,
          textH2_1: '',
          textH2_2: '',
          textH2_3: '',
          textP_1: '',
          textP_2: '',
          textP_3: '',
          textH3: '',
          Lien1: '',
          Lien2: '',
          Lien3: ''
        },
        'simple_pres': []
      };
      this.dataS.pullData(args[1], obj).pipe(take(1)).subscribe(res => {
        if (this.debugS.isDebug()) console.log(res);
        this.router.navigate([routes.categorie, args[1]])
      }, err => { if (this.debugS.isDebug()) console.log(err); })
    } else if (args[0].length === 3) {
      if (this.debugS.isDebug()) console.log("TO(U)DO(UM): Faire la page");
      if (this.debugS.isDebug()) console.log(routes.categorie, args[0], args[1], args[2]);
      this.sub.add(this.sousCatS.pullFilter(args[2], [], args[1]).pipe(take(1)).subscribe(res => { setTimeout(s => this.router.navigate([args[0], args[1], args[2]]), 1000) }, err => { if (this.debugS.isDebug()) console.log(err); }))

    }
  }

}
