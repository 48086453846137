import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarousselEditService {

  constructor(private http: HttpClient) { }

  provideData() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostData + `getCarousselSlides`, { headers });
  }

  pullData(data, id?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    /*     const headers = new HttpHeaders().set(
          'Content-Type',
          'text/json'
        ); */
    let pData = JSON.stringify(data);
    if (id) {
      return this.http.post(environment.hostAdmin + `setSlide`, `id=${id}&data=${JSON.stringify(data)}`, { headers });
    } else {
      return this.http.post(environment.hostAdmin + `addSlide`, `data=${JSON.stringify(data)}`, { headers });
    }
  }

  deleteSlide(id) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    return this.http.get(environment.hostAdmin + `removeSlide?id=${id}`, { headers });
  }
}
