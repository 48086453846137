import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { routes, path } from 'src/assets/routes';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { BurgerPaneUserService } from 'src/app/services/burger-pane-user.service';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.css']
})
export class VerticalMenuComponent implements OnInit {
  menu;
  isAdmin;
  AdminMenu;
  selected: string;
  $width: BehaviorSubject<number> = new BehaviorSubject<number>(window.innerWidth);
  maxWidth = 550;
  $burger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private router: Router, private burgerS: BurgerPaneUserService, private debugS: DebugService) {
    const url = this.router.url;
    this.selected = url.slice(1).split('/')[1];
    if(this.debugS.isDebug())
    console.log(this.selected);
    this.$burger = burgerS.$burger;
    if (window.innerWidth <= this.maxWidth) {
      burgerS.$mobile.next(true);
    }
  }

  ngOnInit() {
    // url = /dashboard/...
    const token = localStorage.getItem('token');
    if (token != null) {
      const jwtUtils = new JwtHelperService();
      const decodedToken = jwtUtils.decodeToken(token);
      this.isAdmin = decodedToken.isAdmin;
      this.menu = [
        { name: 'Account', src: '/assets/img/dashboard/icons/test.png', route: routes.account, url: path.account },
      ];
      this.AdminMenu = [
        { name: 'Admin', src: '/assets/img/dashboard/icons/admins.svg', route: routes.admin, url: path.admin },
        { name: 'Informations', src: '/assets/img/dashboard/icons/infos.svg', route: routes.informations, url: path.informations },
        { name: 'Caroussel', src: '/assets/img/dashboard/icons/carousel.svg', route: routes.carousselEdit, url: path.carousselEdit }
      ];
    } else {
      this.router.navigate([routes.accueil]);
    }
    if(this.debugS.isDebug())
    console.log(this.$width.value);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.$width.next(window.innerWidth);
    /*     if (window.innerWidth > this.maxWidth) {
          if (this.burgerS.$mobile.value != false) {
            this.burgerS.$mobile.next(false);
            console.log(`mobile : ${this.burgerS.$mobile.value}`);
            this.burgerS.switchBurger();
          }
        } else if (window.innerWidth < this.maxWidth) {
          if (this.burgerS.$mobile.value != true) {
            this.burgerS.$mobile.next(true);
            console.log(`mobile : ${this.burgerS.$mobile.value}`);
            this.burgerS.switchBurger();
          }
        } */
  }
  clickImg($event, img) {
    if(this.debugS.isDebug()) {
    console.log($event);
    console.log(img.name + ' / ' + routes.dashboard + ' / ' + img.url + ' / ' + img.route);
    }
    this.selected = img.url;
    this.router.navigate([img.route]);
  }

}
