//#region imports
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cpus, networkInterfaces } from 'os';
import { ReloadTokenService } from '../services/login-register/reload-token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isNullOrUndefined } from 'util';
import { DebugService } from '../services/debug.service';
//#endregion

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private reloadTokenS: ReloadTokenService, private debugS: DebugService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {


    // Get the auth token from the service.
    const authToken = localStorage.getItem('token');

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.

    if (authToken) {
      req = req.clone({
        headers: req.headers.append('Authorization', `Bearer ${authToken}`)
      });
    }
    if (!req.url.includes('newToken') && req.headers.has('Authorization')) {
      if (!isNullOrUndefined(authToken)) {
        const jwtutils = new JwtHelperService();
        const decodedToken = jwtutils.decodeToken(authToken)
        if (this.debugS.isDebug())
          console.log(`Token expire in ${decodedToken.exp - (Date.now() / 1000)} seconds`);
        if (decodedToken.exp - (Date.now() / 1000) <= (60 * 60 * 4)) {
          this.reloadTokenS.reloadToken();
        }


      }
    }
    if (this.debugS.isDebug())
      console.log(req);
    // send cloned request with header to the next handler.
    return next.handle(req);
  }
}
