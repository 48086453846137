<app-loading *ngIf="$finishedLoading.value === false; else finishedTemplate"></app-loading>
<ng-template #finishedTemplate>
    <div class="body_container">
        <div id="edit_container">
            <input type='button' value='edit' routerLink="edit" id="edit_button" *ngIf="isAdmin">
        </div>
        <p id="back" [routerLink]="returnBack()">revenir en arrière</p>
        <div id="entete">
            <h3>{{this.$product.value.h3}}</h3>
            <p class="desc">{{this.$product.value.p}}</p>
        </div>
        <div id="big_container">
            <div id="presentation">
                <div id="p1">
                    <!-- <div class="image_kk"></div> -->
                    <img [src]="getImage(this.$product.value.img)" />
                </div>
                <div id="p2">
                    <p id="bigDesc">
                        {{this.$product.value.BigP}}
                    </p>
                    <p id="price">
                        <span class="promo"
                            *ngIf="$product.value.promo !== $product.value.prix">{{$product.value.promo | currency:'EUR':'€':'1.2-2':'fr-FR' }}
                        </span>
                        <span
                            [ngClass]="{'promoted' : $product.value.promo !== $product.value.prix}">{{this.$product.value.prix  | currency:'EUR':'€':'1.2-2':'fr-FR' }}</span>
                    </p>
                    <p id="formats" *ngIf="$product.value.capacite !== null">
                        Existe en
                        <span *ngFor="let cap of $product.value.capacite.split(','); let i = index">{{cap}}<span
                                *ngIf="$product.value.capacite.split(',').length > i+1">mg, </span> </span>
                        mg de nicotine
                    </p>
                </div>
            </div>
            <div id="caracteristiques">
                <h3>Caractéristiques - {{this.$product.value.h3}}</h3>
                <table>
                    <tr *ngFor="let caract of this.$caract.value">
                        <td class="c1">{{caract.name}}</td>
                        <td class="c2" *ngIf="caract.type !== 'countable'">{{caract.value}}</td>
                        <td class="c2" *ngIf="caract.type === 'countable'">{{caract.value + caract.unit}}</td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</ng-template>