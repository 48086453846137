//#region imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { routes } from 'src/assets/routes';
import { environment } from 'src/environments/environment';
import { DebugService } from '../debug.service';
//#endregion

//#region decorateur
@Injectable({
  providedIn: 'root'
})
//#endregion

export class LoginRegisterService {
  //#region variables
  $displayLogin = new BehaviorSubject<boolean>(false);
  $displayRegister = new BehaviorSubject<boolean>(false);
  //#endregion

  constructor(private http: HttpClient, private router: Router, private debugS: DebugService) { }

  //#region getters/setters
  // mutateur Display Login
  setDisplayLogin(value: boolean) {
    if (this.debugS.isDebug())
      console.log('SetDisplayLogin : ' + value);
    this.$displayLogin.next(value);
  }

  getDisplayLogin() {
    return this.$displayLogin.getValue();
  }

  // mutateur Display Register
  setDisplayRegister(value: boolean) {
    this.$displayRegister.next(value);
  }

  getDisplayRegister() {
    return this.$displayRegister.getValue();
  }

  disconnect() {
    localStorage.removeItem('token');
    this.router.navigate([routes.accueil]);
  }

  //#endregion

  // requête au back pour le token
  requestLogin(email, password): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers.set('Access-Control-Allow-Origin', '*');
    return this.http.post(environment.hostUsersLoginRegister + 'login',
      `email=${email}&password=${password}`,
      { headers }
    );
  }

  // requête au back pour s'inscrire
  requestRegister(email, username, password): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostUsersLoginRegister + 'register', `email=${email}&username=${username}&password=${password}`, { headers });
  }

  sendForgotPassword(email): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostUsersLoginRegister + 'forgotPassword', `email=${email}`, { headers });
  }

  receiveForgotPassword(id, token, password): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostUsersLoginRegister + 'receiveforgotPassword', `id=${id}&token=${token}&newPassword=${password}`, { headers });
  }
  verifyAccount(id, token): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostUsersLoginRegister + 'verifyEmail', `id=${id}&token=${token}`, { headers });
  }
}
