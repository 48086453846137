<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<ng-template #finishedLoading>
    <div class="body_container">
        <div class="big_title">
            <h1>Mentions légales</h1>
            <span></span>
        </div>
        <p>
            Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y parcourir ses
            pages. En
            vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi, conformément à
            l’article n°6 de
            la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent
            site
            internet
            <a href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a> sont :
        </p>
        <h2>Editeur du Site :</h2>
        <p>
            {{text['editeur_du_site']}}<br />
            Numéro de SIRET : {{text['numero_siret']}}<br />
            Responsable editorial : {{text['responsable_de_publication']}}<br />
            {{text['adresse']}}<br />
            Téléphone : {{text['telephone']}}<br />
            Email : {{text['mail_shop']}}<br />
            Site Web : <a href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a>
        </p>
        <h2>Hébergement :</h2>
        <p>
            Hébergeur : {{text['nom_hebergeur']}}<br />
            {{text['adresse_hebergeur']}}<br />
            Site Web : <a href="http://{{text['site_hebergeur']}}">{{text['site_hebergeur']}}</a>
        </p>
        <h2>Développement</h2>
        <p>
            {{text['noms_devs']}}<br />
            Site Web : <a href="http://{{text['site_devs']}}" target="_blank">{{text['site_devs']}}</a>
        </p>
        <h2>Conditions d’utilisation : </h2>
        <p>
            Ce site (<a href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a>) est proposé en différents
            langages web (HTML,
            HTML5,
            Javascript, CSS, etc…) pour un meilleur confort d'utilisation et un graphisme plus agréable, nous vous
            recommandons de
            recourir à des navigateurs modernes comme Brave, Safari, Firefox, Google Chrome, etc…
            Les mentions légales ont été générées sur le site <a
                title="générateur de mentions légales pour site internet gratuit"
                href="http://www.generateur-de-mentions-legales.com">Générateur de mentions légales</a>, offert par <a
                title="imprimerie paris, imprimeur paris" href="http://welye.com">Welye</a>.
        </p>
        <p>
            {{text['editeur_du_site']}} met en œuvre tous les moyens dont elle dispose, pour
            assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou
            omissions
            peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de
            {{text['editeur_du_site']}}, et signaler toutes
            modifications du site qu'il jugerait utile.{{text['editeur_du_site']}} n'est en aucun cas responsable de
            l'utilisation
            faite de ces informations,
            et de tout préjudice direct ou indirect pouvant en découler.
        </p>
        <p>
            <b>Cookies</b> : Le site <a href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a> peut-être
            amené à vous demander
            l’acceptation des cookies pour des besoins de statistiques et d'affichage. Un cookies est une information
            déposée sur
            votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées
            sur
            votre
            ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations .
            Certaines
            parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.
        </p>
        <p>
            <b>Liens hypertextes :</b> Les sites internet de peuvent offrir des liens vers d’autres sites internet ou
            d’autres
            ressources disponibles sur Internet. {{text['editeur_du_site']}} ne dispose d'aucun moyen pour contrôler les
            sites en
            connexion
            avec ses sites internet.{{text['editeur_du_site']}} ne répond pas de la disponibilité de tels sites et
            sources
            externes,
            ni ne la garantit. Elle ne
            peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces
            sites ou
            sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui
            peut être
            fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l'internaute, qui doit se
            conformer à
            leurs conditions d'utilisation.
        </p>
        <p>
            Les utilisateurs, les abonnés et les visiteurs des sites internet de ne peuvent mettre en place un hyperlien
            en
            direction de ce site sans l'autorisation expresse et préalable de {{text['editeur_du_site']}}.
        </p>
        <p>
            Dans l'hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d’un
            des
            sites
            internet de {{text['editeur_du_site']}}, il lui appartiendra d'adresser un email accessible sur le site afin
            de
            formuler
            sa
            demande de mise en place d'un hyperlien. {{text['editeur_du_site']}} se réserve le droit d’accepter ou de
            refuser un
            hyperlien
            sans avoir à en justifier sa décision.
        </p>
        <p>
            <b>Services fournis :</b> L'ensemble des activités de la société ainsi que ses informations sont présentés
            sur
            notre
            site <a href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a>.
        </p>
        <p>
            {{text['editeur_du_site']}} s’efforce de fournir sur le site {{text['adresse_site']}} des informations aussi
            précises que possible. les renseignements figurant sur le site <a
                href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a> ne sont pas exhaustifs et les photos
            non contractuelles.
            Ils
            sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous
            les
            informations indiquées sur le site {{text['adresse_site']}} sont données à titre
            indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.
        </p>
        <p>
            <b>Limitation contractuelles sur les données :</b> Les informations contenues sur ce site sont aussi
            précises
            que
            possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des
            inexactitudes
            ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de
            bien
            vouloir le
            signaler par email, à l’adresse {{text['mail_shop']}}, en décrivant le problème de la manière la plus
            précise
            possible (page posant
            problème, type d’ordinateur et de navigateur utilisé, …).
        </p>
        <p>
            Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En
            conséquence,
            {{text['editeur_du_site']}} ne saurait être tenu responsable d'un quelconque dommage subi par l'ordinateur
            de
            l'utilisateur ou d'une quelconque
            perte de données consécutives au téléchargement.De plus, l’utilisateur du site s’engage à
            accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière
            génération mis-à-jour.
        </p>
        <p>
            Les liens hypertextes mis en place dans le cadre du présent site internet en direction d'autres ressources
            présentes sur
            le réseau Internet ne sauraient engager la responsabilité de {{text['editeur_du_site']}}.
        </p>
        <p>
            <b>Propriété intellectuelle :</b> Tout le contenu du présent sur le site <a
                href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a>, incluant, de façon non
            limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur
            mise
            en forme
            sont la propriété exclusive de la société à l'exception des marques, logos ou contenus appartenant à
            d'autres
            sociétés
            partenaires ou auteurs.
        </p>
        <p>
            Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle,
            de
            ces
            différents éléments est strictement interdite sans l'accord exprès par écrit de {{text['editeur_du_site']}}.
            Cette
            représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par
            les
            articles
            L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue
            une
            contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires
            des
            Contenus copiés pourraient intenter une action en justice à votre encontre.
        </p>
        <p>
            <b>Déclaration à la CNIL :</b> Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801
            du 6
            août 2004 relative à la protection des
            personnes physiques à l'égard des traitements de données à caractère personnel) relative à l'informatique,
            aux
            fichiers
            et aux libertés, ce site n'a pas fait l'objet d'une déclaration auprès de la Commission nationale de
            l'informatique et
            des libertés (<a href="http://www.cnil.fr/">www.cnil.fr</a>).
        </p>
        <p>
            <b>Litiges :</b> Les présentes conditions du site <a
                href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a> sont
            régies par les lois
            françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de
            celles-ci
            seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de
            référence, pour
            le règlement de contentieux éventuels, est le français.
        </p>
        <p>
            <b>Données personnelles :</b>
            De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez
            notre site
            Internet <a href="http://{{text['adresse_site']}}">{{text['adresse_site']}}</a>.
        </p>
    </div>
</ng-template>