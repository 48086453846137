import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { UserModifyService } from 'src/app/services/login-register/user-modify.service';
import { FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.css']
})
export class AccountInformationComponent implements OnInit {
  reactModifyForm;
  reactModifyPasswordForm;
  $finishedLoading = new BehaviorSubject<boolean>(false);
  $mail = new BehaviorSubject<string>('');
  $username = new BehaviorSubject<string>('');
  $AllMsgs = new BehaviorSubject<any>([]);
  index = 0;
  constructor(private userModify: UserModifyService, private errorHandler: ErrorManagerService, private debugS: DebugService) {
    this.reactModifyForm = new FormGroup({
      email: new FormControl(''),
      username: new FormControl('')
    })
    this.reactModifyPasswordForm = new FormGroup({
      password: new FormControl(''),
      newPassword: new FormControl('')
    })
  }

  ngOnInit() {
    let user = this.userModify.getUser().pipe(take(1)).subscribe(
      res => {
        /* this.reactModifyForm.patchValue({ email: res.email, username: res.username }) */
        this.$mail.next(res.email);
        this.$username.next(res.username);
        if(this.debugS.isDebug())
        console.log(res);
        this.$finishedLoading.next(true);
      },
      err => {
        if(this.debugS.isDebug())
        console.log(err);
      }
    )
  }

  onSubmit() {
    this.userModify.setUser(this.getCEmail(), this.getCUsername()).pipe(take(1)).subscribe(
      res => {
        if(this.debugS.isDebug())
        console.log(res);
        this.afficherMsg('success');
      },
      err => {
        this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
      }
    )
  };
  getCEmail() {
    if (this.reactModifyForm.value.email === '') {
      return this.$mail.value;
    }
    return this.reactModifyForm.value.email;
  }

  getCUsername() {
    if (this.reactModifyForm.value.username === '') {
      return this.$username.value;
    }
    return this.reactModifyForm.value.username;
  }

  onSubmitPassword() {
    this.userModify.setPassword(this.reactModifyPasswordForm.value.password, this.reactModifyPasswordForm.value.newPassword).pipe(take(1)).subscribe(
      res => {
        if(this.debugS.isDebug())
        console.log(res);
        this.afficherMsg('success');
      },
      err => {
        this.afficherMsg('error', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error));
      }
    )
  }
  scrollToElement($element): void {
    if(this.debugS.isDebug())
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  afficherMsg(type, error?) {
    if (type === 'error') {
      if (isNullOrUndefined(error)) return false;
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ value: true, index: indexTemp, type: 'error', error: error })
      this.$AllMsgs.next(value);
      this.scrollToElement(document.querySelector('#list1-hm'));
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if(this.debugS.isDebug())
        console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
        this.scrollToElement(document.querySelector('#list1-hm'));
      }, 3000)
    } else if (type === 'success') {
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ index: indexTemp, type: 'success' })
      this.$AllMsgs.next(value);
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if(this.debugS.isDebug())
        console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    }
  }
}
