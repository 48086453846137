<div class="body_container">
    <div id="container">
        <div id="sucessfull" class="popup">
            <img src="/assets/img/utilities/green_check.svg">
            <p>Vos modifications ont bien été enregistrées.</p>
        </div>
        <div id="caramba_error" class="popup">
            <img src="/assets/img/utilities/red_cross.svg">
            <p>Insérer erreur</p>
        </div>


        <form [formGroup]='reactModifyPasswordForm' id="form2">
            <h1>Changer de mot de passe</h1>
            <div class="txtb">
                <input #pass type="password" formControlName="password">
                <span data-placeholder="Noueau mot de passe" class="place-holder-login" name="password"></span>
                <div id="colored"></div>
            </div>

            <!--             <div class="txtb">
                <input #newpass type="password" formControlName="newPassword">
                <span data-placeholder="Confirmer nouveau mot de passe" class="place-holder-login" name="new_password"></span>
                <div id="colored"></div>
            </div> -->

            <input type="submit" class="logbtn" value="Valider" (click)="onSubmitPassword()">
        </form>
        <p>id : {{this.id}} && token : {{this.token}}</p>
    </div>
</div>