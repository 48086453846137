import { NgModule } from '@angular/core';
import { path } from 'src/assets/routes';
import { IsLoggedService } from './services/login-register/is-logged.service';

import { BodyComponent } from './core/body/body.component';
import { Routes, RouterModule } from '@angular/router';
import { AccountInformationComponent } from './core/user-panel/Components/account-information/account-information.component';
import { AdminPanelComponent } from './core/user-panel/admin-panel/admin-panel.component';
import { CatComponent } from './core/cat/cat.component';
import { MentionsLegalComponent } from './core/footers/mentions-legal/mentions-legal.component';
import { ContactComponent } from './core/footers/contact/contact.component';
import { CatEditComponent } from './core/cat-edit/cat-edit.component';
import { E404Component } from './core/e404/e404.component';
import { SousCatComponent } from './core/sous-cat/sous-cat.component';
import { IsAdminService } from './services/is-admin.service';
import { InfoEditComponent } from './core/user-panel/Components/info-edit/info-edit.component';
import { FaqComponent } from './core/faq/faq.component';
import { CarousselEditFormComponent } from './core/user-panel/Components/caroussel-edit-form/caroussel-edit-form.component';
import { ProductComponent } from './core/product/product.component';
import { VapomaticComponent } from './core/vapomatic/vapomatic.component';
import { VerifMailComponent } from './core/verif-mail/verif-mail.component';
import { ChangePasswordComponent } from './core/change-password/change-password.component';
import { ProductEditComponent } from './core/product-edit/product-edit.component';
import { FaqEditComponent } from './core/faq-edit/faq-edit.component';

//#region routes
/*  const appRoutes: Routes = [
  {
    path: path.accueil, */
/*  loadChildren: () => import("./body-routing.module").then(m => m.BodyRoutingModule),   */
/*      component: BodyComponent,
    pathMatch: "full"
  },
    {
     path: path.dashboard,
     canActivate: [IsLoggedService], */
/* loadChildren: () => import('./core/user-panel/dashboard.module').then(m => m.DashboardModule), */
/*    },
  { path: '', redirectTo: path.accueil,
  pathMatch: "full" },
  { path: '**', redirectTo: path.accueil,
  pathMatch: "full" }
];  */
//#endregion
//#region routes

const dashboardRoutes: Routes = [
  {
    path: path.account,
    canActivate: [IsLoggedService],
    component: AccountInformationComponent,
  },
  {
    path: path.admin,
    canActivate: [IsLoggedService],
    component: AdminPanelComponent,
  },
  {
    path: path.informations,
    canActivate: [IsLoggedService],
    component: InfoEditComponent,
  },
  {
    path: path.carousselEdit,
    canActivate: [IsLoggedService],
    component: CarousselEditFormComponent,
  },
  {
    path: '', redirectTo: path.account,
    pathMatch: 'full'
  },
  { /* TODO: Faire Marcher */
    path: '**',
    redirectTo: path.dashboard,
    pathMatch: 'full'
  },
];

const appRoutes: Routes = [
  {
    path: path.accueil,
    component: BodyComponent,
  },
  {
    path: path.categorie + '/:cat',
    component: CatComponent
  },
  {
    path: path.categorie + '/:cat/edit',
    component: CatEditComponent,
    canActivate: [IsAdminService]
  },
  {
    path: path.categorie + '/:cat/:souscat',
    component: SousCatComponent,
  },
  {
    path: path.categorie + '/:cat/:souscat/:product',
    component: ProductComponent,
  },
  {
    path: path.categorie + '/:cat/:souscat/:product/edit',
    component: ProductEditComponent,
    canActivate: [IsAdminService]
  },
  {
    path: path.faq,
    component: FaqComponent
  },
  {
    path: path.faq + '/edit',
    component: FaqEditComponent,
    canActivate: [IsAdminService]
  },
  {
    path: path.vapomatic,
    component: VapomaticComponent
  },
  {
    path: path.mentionlegal,
    component: MentionsLegalComponent
  },
  {
    path: path.contact,
    component: ContactComponent
  },
  {
    path: path.notfound,
    component: E404Component
  },
  {
    path: path.verifemail,
    component: VerifMailComponent
  },
  {
    path: path.forgotPassword,
    component: ChangePasswordComponent
  },
  {
    path: path.dashboard,
    canActivate: [IsLoggedService],
    /* loadChildren: () => import('./core/user-panel/dashboard-module.module').then(m => m.DashboardModuleModule) */
    children: dashboardRoutes
  },
  {
    path: '', redirectTo: path.accueil,
    pathMatch: 'full'
  },
  {
    path: '**', redirectTo: path.accueil,
    pathMatch: 'full'
  }
];
//#endregion

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes /* , { enableTracing: true } */)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
