<nav id="vertical_menu" [class.m-menu]="$width.value <= this.maxWidth"
    [class.pc-verticalM]="$width.value > this.maxWidth" *ngIf="($burger.value === false); else burgerClicked">
    <!-- dashboard users -->
    <h5 class="gdc" [class.m-hidden]="$width.value <= this.maxWidth">GESTION DU COMPTE</h5>
    <ul id="list1-vm" class="lists-vm">
        <a  title="test">
        <li *ngFor="let img of menu" [class.selected-li]="img.url === selected" [class.hoverable]="img.url !== selected"
            (click)="clickImg($event, img)" [class.m-hoverable]="$width.value <= this.maxWidth"
            [class.m-li]="$width.value <= this.maxWidth" [class.pc-border]="$width.value > this.maxWidth"
            [class.pc-selected1]="$width.value > this.maxWidth && img.url === this.selected">
            <img [src]="img.src" class="icons" [class.selected-img]="img.url === selected"
                [class.m-icons]="$width.value <= this.maxWidth" [class.pc-icons]="$width.value > this.maxWidth"
                [class.pc-marge]="$width.value > this.maxWidth && img.url === this.selected" />
            <p [class.m-hidden]="$width.value <= this.maxWidth">{{img.name}}</p>
        </li>
    </a>
    </ul>
    <div *ngIf='isAdmin'>
        <h5 class="gdc" [class.m-hidden]="$width.value <= this.maxWidth">PANEL ADMIN</h5>
        <ul id="list2-vm" class="lists-vm">
            <li *ngFor="let img of AdminMenu" (click)="clickImg($event, img)" [class.selected-li]="img.url === selected"
                [class.hoverable]="img.url !== selected" [class.m-hoverable]="$width.value <= this.maxWidth"
                [class.m-li]="$width.value <= this.maxWidth" [class.pc-border]="$width.value > this.maxWidth"
                [class.pc-selected1]="$width.value > this.maxWidth && img.url === this.selected">
                <img [src]="img.src" class="icons" [class.selected-img]="img.url === selected"
                    [class.m-icons]="$width.value <= this.maxWidth" [class.pc-icons]="$width.value > this.maxWidth"
                    [class.pc-marge]="$width.value > this.maxWidth && img.url === this.selected" />
                <p [class.m-hidden]="$width.value <= this.maxWidth">{{img.name}}</p>
            </li>
        </ul>
    </div>
</nav>
<ng-template #burgerClicked>
    <nav id="vertical_menu" [class.m-menu]="$width.value > this.maxWidth"
        [class.pc-verticalM]="$width.value <= this.maxWidth">
        <!-- dashboard users -->
        <h5 class="gdc" [class.m-hidden]="$width.value > this.maxWidth">GESTION DU COMPTE</h5>
        <ul id="list1-vm" class="lists-vm">
            <li *ngFor="let img of menu" [class.selected-li]="img.url === selected"
                [class.hoverable]="img.url !== selected" (click)="clickImg($event, img)"
                [class.m-hoverable]="$width.value > this.maxWidth" [class.m-li]="$width.value > this.maxWidth"
                [class.pc-border]="$width.value <= this.maxWidth"
                [class.pc-selected1]="$width.value <= this.maxWidth && img.url === this.selected">
                <img [src]="img.src" class="icons" [class.selected-img]="img.url === selected"
                    [class.m-icons]="$width.value > this.maxWidth" [class.pc-icons]="$width.value <= this.maxWidth"
                    [class.pc-marge]="$width.value <= this.maxWidth && img.url === this.selected" />
                <p [class.m-hidden]="$width.value > this.maxWidth">{{img.name}}</p>
            </li>
        </ul>
        <div *ngIf='isAdmin'>
            <h5 class="gdc" [class.m-hidden]="$width.value > this.maxWidth">PANEL ADMIN</h5>
            <ul id="list2-vm" class="lists-vm">
                <li *ngFor="let img of AdminMenu" (click)="clickImg($event, img)"
                    [class.selected-li]="img.url === selected" [class.hoverable]="img.url !== selected"
                    [class.m-hoverable]="$width.value > this.maxWidth" [class.m-li]="$width.value > this.maxWidth"
                    [class.pc-border]="$width.value <= this.maxWidth"
                    [class.pc-selected1]="$width.value <= this.maxWidth && img.url === this.selected">
                    <img [src]="img.src" class="icons" [class.selected-img]="img.url === selected"
                        [class.m-icons]="$width.value > this.maxWidth" [class.pc-icons]="$width.value <= this.maxWidth"
                        [class.pc-marge]="$width.value <= this.maxWidth && img.url === this.selected" />
                    <p [class.m-hidden]="$width.value > this.maxWidth">{{img.name}}</p>
                </li>
            </ul>
        </div>
    </nav>
</ng-template>