<!-- test version e-cigarettes -->
<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<ng-template #finishedLoading>
  <div class="body_container">
    <div id="edit_container">
      <input type='button' value='edit' routerLink="edit" id="edit_button" *ngIf="this.isAdmin">
    </div>

    <div class="simple_pres" *ngIf="Data[1].isEnable">
      <div class="big_title">
        <h1>{{Data[1].textH1}}</h1>
        <span></span>
      </div>
      <p>{{Data[1].textP}}</p>
    </div>


    <div class="dual_separator" *ngIf="Data[2].isEnable">
      <div class="ds">
        <a [routerLink]="'/cat/'+ cat + '/' + Data[2].Lien1">
          <h2>{{Data[2].textH2_1}}</h2>
        </a>
        <p>{{Data[2].textP_1}}</p>
      </div>
      <div class="dc_separator"></div>
      <div class="ds">
        <a [routerLink]="'/cat/'+ cat + '/' + Data[2].Lien2">
          <h2>{{Data[2].textH2_2}}</h2>
        </a>
        <h3 class="subtitle">{{Data[2].textH3}}</h3>
        <p>{{Data[2].textP_2}}</p>
      </div>
      <div class="dc_separator"></div>
      <div class="ds">
        <a [routerLink]="'/cat/'+ cat + '/' +  Data[2].Lien3">
          <h2>{{Data[2].textH2_3}}</h2>
        </a>
        <p>{{Data[2].textP_3}}</p>
      </div>
    </div>



    <!--     <div class="simple_pres" *ngIf="Data[3].isEnable"> -->
    <div *ngIf="Data['simple_pres'] !== null">
      <div class="simple_pres" *ngFor="let d of Data['simple_pres']">
        <div class="big_title">
          <h1 id="kits&POD">{{d.textH1}}</h1>
          <span></span>
        </div>
        <p>{{d.textP}}</p>
        <div class="itemsContainer" *ngIf="d.img !== null && d.img !== undefined">
          <div class="items voirPlus" (click)="onClickVoirPlus(d)">
            <h1>Voir Plus</h1>
          </div>
          <div *ngFor="let img of d.img">
            <img class="items" [src]="getImage(img.img)"
              [routerLink]="routes.categorie + '/' + this.cat + '/' + img.sousCat + '/' + img.lien"
              [alt]="routes.categorie + '/' + this.cat + '/' + img.sousCat + '/' + img.lien">
          </div>
          <!-- <span *ngFor="let img of images" class="items"><img [src]="getImage(img.img)">{{img.lien}}</span> -->
        </div>
      </div>
    </div>



  </div>

</ng-template>