import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GridApi } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class PasswordPopupService {
  $isLoaded;
  id;
  isAdmin;
  gridApi: GridApi;
  index;
  constructor(private http: HttpClient) {
    this.$isLoaded = new BehaviorSubject<boolean>(false);
  }
  openPopup() {
    if (this.$isLoaded.value !== true)
      this.$isLoaded.next(true)
  }
  closePopup() {
    this.$isLoaded.next(false);
  }

  setAdmin(targetId, password, value): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(environment.hostAdmin + 'setAdmin', `targetUserId=${targetId}&password=${password}&value=${value}`, { headers });
  }
}
