//#region imports
import { Component, OnInit } from '@angular/core';
//#endregion

//#region component
@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.css']
})
//#endregion

export class HeadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
