import { Component, OnInit, HostListener } from '@angular/core';
import { LoginRegisterService } from 'src/app/services/login-register/login-register.service';
import { BehaviorSubject } from 'rxjs';
import { BurgerPaneUserService } from 'src/app/services/burger-pane-user.service';

@Component({
  selector: 'app-dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.css']
})
export class DashHeaderComponent implements OnInit {
  $width: BehaviorSubject<number> = new BehaviorSubject<number>(window.innerWidth);
  maxWidth = 550;
  $burger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(public LoginService: LoginRegisterService, public burgerS: BurgerPaneUserService) {
    this.$burger = burgerS.$burger;
  }

  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.$width.next(window.innerWidth);
  }

}
