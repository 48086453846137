<header>
  <div class="lebordeldemax">
    <!-- pourquoi cette div ? :innocent:/ elle sert a rien mdr / MAIS POURQUOI ?!-->


    <div class="menu_pc" *ngIf="$channel | async ; else menu_mobile">
      <span id="enHaut"></span>
      <ul class="menu1">
        <li>
          <a routerLink="/home" id="a_logo">
            <img src="/assets/img/logos/logo_tropik_blank.png" alt="" style="max-height:70px">
          </a>
        </li>
        <!-- élément à dupliquer -->
        <li *ngFor="let image of listImage" (click)="onEvent($event, image)" [routerLink]="image.url">
          <!-- patern -->
          <app-list-item class="img" [img]="image"></app-list-item>
        </li>
      </ul>
      <div id="menu2">
        <button *ngIf="isLoggedvar | async; else notLogged" (click)="clickDashboardButton()">
          <img id="gear" src="/assets/img/header/gear.png" /> <!-- user logged -> dashboard -->
        </button>
        <ng-template #notLogged>
          <button (click)="clickLoginButton()" [disabled]='($isWindowOpen | async)'>
            <img id="login" src="/assets/img/header/login.png" /> <!-- user not logged -> login interface -->
          </button>
        </ng-template>
      </div>
    </div>



    <ng-template #menu_mobile>
      <div class="menu_mobile">
        <!-- wa ça bosse /dur/ ici -->
        <span id="enHaut"></span>

        <div id="nav">
          <a routerLink="/home" id="a_logo_m">
            <img src="/assets/img/logos/logo_tropik_blank.png" alt="" style="max-height:70px">
          </a>
          <!-- barre pour ouvrir en grand le menu -->
          <img id="burger" src="/assets/img/header/menu.png" (click)='openBurger()'>
        </div>


        <div id="allInOne" *ngIf="$clickedBurger | async">
          <img src="/assets/img/utilities/cross2.png" class="cross-login" (click)="closeBurger()" />
          <ul class="menu1">
            <!-- élément à dupliquer -->
            <li *ngFor="let image of listImage" (click)="onEvent($event, image)" [routerLink]="image.url">
              <!-- patern -->
              <app-list-item class="img" [img]="image"></app-list-item>
            </li>
          </ul>
          <div id="menu2">
            <button *ngIf="isLoggedvar | async; else notLogged" (click)="clickDashboardButton()" id="connection_button">
              <p>paramètres</p> <!-- user logged -> dashboard -->
            </button>
            <ng-template #notLogged>
              <button (click)="clickLoginButton()" id="connection_button">
                <p>se connecter</p> <!-- user not logged -> login interface -->
              </button>
            </ng-template>
          </div>
        </div>

      </div>
    </ng-template>
  </div>
</header><!--  :) -->