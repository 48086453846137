//#region imports
import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from 'protractor';
import { LoginRegisterService } from 'src/app/services/login-register/login-register.service';
import { take } from 'rxjs/internal/operators/take';
import { HeaderIcon } from 'src/app/services/Products/header-icon.service';
import { DebugService } from 'src/app/services/debug.service';
//#endregion

//#region component
@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
//#endregion

export class ListItemComponent {
  //#region variables
  @Input() img: HeaderIcon;
  //#endregion

  constructor(private loginService: LoginRegisterService, private debugS: DebugService) { }

  log = (img) => { if (this.debugS.isDebug()) console.log(img); };

  // quand on clique sur une images
  action() {

  }

}
