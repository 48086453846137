//#region imports
import { Component } from '@angular/core';
import { AuthService } from 'angularx-social-login';
import { LoginRegisterService } from 'src/app/services/login-register/login-register.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { routes } from 'src/assets/routes';
import { MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';
import { ThrowStmt } from '@angular/compiler';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
import { DebugService } from 'src/app/services/debug.service';
//#endregion

//#region component
@Component({
  selector: 'app-registerform',
  templateUrl: './registerform.component.html',
  styleUrls: ['./registerform.component.css']
})
//#endregion

export class RegisterformComponent {

  error = undefined;
  //#region variables
  ReactRegisterForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    username: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    password: new FormControl('', [Validators.required, Validators.maxLength(128), Validators.minLength(6)])
  });
  //#endregion

  constructor(
    private authService: AuthService,
    private loginRegisterService: LoginRegisterService,
    private router: Router,
    private dialogRef: MatDialogRef<RegisterformComponent>,
    private errorHandler: ErrorManagerService,
    private debugS: DebugService
  ) { }

  // quand on appuie sur le bouton s'inscrire
  onSubmit() {
    if (this.debugS.isDebug())
      console.log(this.ReactRegisterForm);
    this.setError()
    this.loginRegisterService.requestRegister(
      this.ReactRegisterForm.value.email,
      this.ReactRegisterForm.value.username,
      this.ReactRegisterForm.value.password
    )
      .pipe(take(1))
      .subscribe(
        // success
        res => {
          if (this.debugS.isDebug())
            console.log(res);
          localStorage.setItem('token', res.token);
          if (this.debugS.isDebug())
            console.log('redirect !');
          this.router.navigate([routes.dashboard]);
          this.dialogRef.close('exit');
        },
        // erreur
        err => {
          if(this.debugS.isDebug())
          console.log(err)
          /*           if (err.error.error === 'username too long') {
                      this.setError('Nom d\'utilisateur trop long')
                      return;
                    }
                    if (err.error.error === 'user already exist email') {
                      this.setError('Un utilisateur possédant ce nom d\'utilisateur ou cet email existe déjà.')
                      return;
                    }
                    if (err.error.error === 'user already exist username') {
                      this.setError('Ce Pseudo existe deja')
                    }
                    this.setError('Coup Dur. essayez de recharger la page')
                  } */
          this.setError(this.errorHandler.parseError('register', err.error.errorCode, err.error.arg, err.error.error));
        });
  }
  // quand on appuie sur le bouton s'inscrire avec google
  RegisterWithGoogle() { }

  // quand on appuie sur le bouton se connecter
  switchToLogin() {
    this.dialogRef.close('login');
  }

  // quand on appuie sur la croix
  close = () => this.dialogRef.close('exit');
  al() {
    alert('fonctionnalité pas encore implementée !');
  }
  showError = () => this.error;
  setError = (message?: string) => this.error = isNullOrUndefined(message) ? undefined : message;
}
