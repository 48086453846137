<div class="login-form">
  <img src="assets/img/utilities/cross.png" class="cross-login" (click)="close()" />
  <h1>{{$isForgotPasswordClicked.value ? 'Mot de passe oublié' : 'Connexion'}}</h1>

  <!-- <ul id="login-with">
    <li>
      <button (click)="al()">
        <img id="apple-logo" src="assets/img/utilities/png/apple.png" srcset="assets/img/utilities/apple.svg"
          alt="s'enregistrer avec apple" height="50px" />
      </button>
    </li>
    <li>
      <button (click)="al()">
        <img src="assets/img/utilities/png/google.png" srcset="assets/img/utilities/google.svg"
          alt="s'enregistrer avec apple" height="50px" />
      </button>
    </li>
    <li>
    <button>
      <img
        src="assets/img/utilities/png/facebook.png"
        srcset="assets/img/utilities/facebook.svg"
        alt="s'enregistrer avec apple"
        height="50px"
      />
    </button>
  </li>
  </ul>

  <p id="login-OU">OU</p> -->
  <form [formGroup]="reactLoginForm" (ngSubmit)="onSubmit()">
    <div class="txtb">
      <input #email type="email" formControlName="email" />
      <span data-placeholder="Nom d'utilisateur" name="username" *ngIf="!$isForgotPasswordClicked.value"></span>
      <span data-placeholder="Email" name="username" *ngIf="$isForgotPasswordClicked.value"></span>
      <div id="colored"></div>
    </div>

    <div class="txtb">
      <input #password type="password" formControlName="password" [readOnly]="$isForgotPasswordClicked.value" />
      <span data-placeholder="Mot de passe" class="place-holder-login" name="password"
        [id]="$isForgotPasswordClicked.value ? 'pswd_fgt' : ''"></span>
      <div id="colored"></div>
    </div>

    <p class="error" *ngIf='showError()'>{{error}}</p>
    <input type='button' class="logbtn" value="Valider" (click)="forgotPassword()"
      *ngIf="$isForgotPasswordClicked.value" [disabled]="!reactLoginForm.controls['email'].valid">
    <input type="submit" class="logbtn" value="Se connecter" *ngIf="!$isForgotPasswordClicked.value" />
  </form>
  <div class="bottom-text" *ngIf="!data.isMaintenance">
    Pas de compte? <a (click)="register()">S'inscrire</a><br />
    <a
      (click)="$isForgotPasswordClicked.next(!$isForgotPasswordClicked.value); this.reactLoginForm.controls['password'].setValue(''); setError()">
      {{$isForgotPasswordClicked.value ? 'Se connecter ?' : 'Mot de passe oublié ?'}}
    </a>
  </div>
</div>