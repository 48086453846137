import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { NgbSlide } from '@ng-bootstrap/ng-bootstrap'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CatDataService } from 'src/app/services/Products/cat-data.service';
import { FormGroup, FormControl } from '@angular/forms';
import { routes } from 'src/assets/routes';
import { IsAdminService } from 'src/app/services/is-admin.service';
import { Options } from 'ng5-slider';
import { CookieService } from 'ngx-cookie-service';
import { isNullOrUndefined } from 'util';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { DebugService } from 'src/app/services/debug.service';
import { upperFirst } from 'lodash';
@Component({
  selector: 'cat',
  templateUrl: './cat.component.html',
  styleUrls: ['./cat.component.scss'],
  animations: [
    trigger('simpleTranslation', [
      state('outleft', style({ transform: `translateX(100%)` })),
      state('outright', style({ transform: `translateX(-100%)` })),
      state('inright', style({ transform: `translateX(0)` })),
      state('inleft', style({ transform: `translateX(0)` })),
      transition('*=>inright', [
        style({ transform: `translateX(-100%)` }),
        animate('260ms ease-in')
      ]),
      transition('*=>inleft', [
        style({ transform: `translateX(100%)` }),
        animate('260ms ease-in')
      ]),
      transition('*=>outleft', [
        animate('260ms ease-in', style({ transform: `translateX(-100%)` }))
      ]),
      transition('*=>outright', [
        animate('260ms ease-in', style({ transform: `translateX(100%)` }))
      ]),
    ])
  ]
})
export class CatComponent implements OnInit, OnDestroy {
  value: number = 100;
  maxValue: number = 101;
  options: Options = {
    floor: 0,
    ceil: 100000,
    animate: false
  };
  isAdmin: boolean;
  $finishedLoading = new BehaviorSubject<boolean>(false);
  test = true;
  cat: any;
  sub = new Subscription();
  researchOption: FormGroup = new FormGroup({
    search: new FormControl('')
  });
  Data;
  routes = routes;
  onSubmit() {
  }

  @ViewChildren(NgbSlide) slides: QueryList<NgbSlide>;
  slideControl: any[] = [];
  images = [];
  onSlide(event) {
    /*     this.slides.forEach((x, index) => {
          if (x.id == event.current) {
            this.slideControl[index] = 'in' + event.direction
          }
          if (x.id == event.prev) {
            this.slideControl[index] = 'out' + event.direction
          }
        }) */
  }
  constructor(private route: ActivatedRoute, private dataS: CatDataService, private router: Router, private isAdminS: IsAdminService, private cookieS: CookieService, private sanitizer: DomSanitizer, private debugS: DebugService) {
    this.cat = this.route.snapshot.paramMap.get('cat');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.$finishedLoading.next(false);
      this.cat = param.get('cat');
      this.sub.add(dataS.provideData(this.cat).subscribe(
        res => {
          if (this.debugS.isDebug())
            console.log(res);
          this.Data = res;
          for (var prop in this.Data) {
            let isEmpty = true;
            for (let prop2 in this.Data[prop]) {
              if (!isNullOrUndefined(this.Data[prop][prop2]) && this.Data[prop][prop2] !== '' && prop2 !== 'isEnable') {
                if (this.debugS.isDebug())
                  console.log(prop + ' : ' + prop2 + ' : ' + this.Data[prop][prop2])
                isEmpty = false;
              }
            }
            if (isEmpty) {
              this.Data[prop].isEnable = false;
            }
          }
          this.isAdmin = isAdminS.isAdmin();
          if (!isNullOrUndefined(this.Data['simple_pres'])) {
            if (this.Data['simple_pres'].length !== 0) {
              for (let d in this.Data['simple_pres']) {
                console.log(this.Data['simple_pres'][d]);
                if (this.Data['simple_pres'][d].afficherSlider && !isNullOrUndefined(this.Data['simple_pres'][d].configSlider)) {
                  let config = this.Data['simple_pres'][d].configSlider.split('$');
                  console.log(config);
                  if (!isNullOrUndefined(config[0]) && !isNullOrUndefined(config[1]) && !isNullOrUndefined(config[2])) {
                    this.Data['simple_pres'][d].souscat = config[0];
                    this.Data['simple_pres'][d].filter = config[1] + '$' + config[2];
                    this.loadImage(config[0], config[1], config[2], d);
                  } else if (!isNullOrUndefined(config[0])) {
                    console.log(config[0]);
                    this.Data['simple_pres'][d].souscat = config[0];
                    this.loadImage(config[0], '', '', d);
                  }

                }
              }
            }
          }
          this.$finishedLoading.next(true);
        },
        err => {
          if (this.debugS.isDebug())
            console.log(err);
          let tDate = new Date(Date.now());
          tDate.setSeconds(tDate.getSeconds() + 60);
          cookieS.set('redirectFrom', '/cat/' + this.cat, tDate);
          router.navigate([routes.notfound]);
        }));
    }));
  }

  /*   images = ['https://picsum.photos/300/255?random&t=1',
      'https://picsum.photos/300/225?random&t=2',
      'https://picsum.photos/300/225?random&t=3', 'https://picsum.photos/300/255?random&t=4'] */
  ngOnInit() {

  }

  loadImage(souscat, caract, value, index) {
    this.sub.add(this.dataS.provideRandomImage(souscat, caract, value).pipe(take(1)).subscribe(res => {
      this.Data['simple_pres'][index].img = this.randomizeArray(res);
    }, err => {
      if (this.debugS.isDebug())
        console.log(err);
    }))
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getImage(dataImage) {
    if (!isNullOrUndefined(dataImage) && dataImage !== '') {
      const dataImageP = dataImage.split(' ').join('+');
      return this.sanitizer.bypassSecurityTrustUrl(`${dataImageP}`);
    }
    return '';
  }

  onClickVoirPlus(d) {
    if (!isNullOrUndefined(d.filter)) {
      let tDate = new Date(Date.now());
      tDate.setSeconds(tDate.getSeconds() + 60);
      this.cookieS.set('filter', d.filter, tDate);
    }
    this.router.navigate([routes.categorie, this.cat, d.souscat]);
  }

  randomizeArray(arr) {
    var output = [];
    while (arr.length) {
      output.push(arr.splice(Math.floor(Math.random() * arr.length), 1)[0]);
    }
    return output;
  }
}
