<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<ng-template #finishedLoading>
    <div id="form1" class="container" [formGroup]="editControl">
        <!--  <div id="sucessfull" class="popup" *ngIf="$isAfficheSucces.value">
            <img src="/assets/img/utilities/green_check.svg">
            <p>Vos modifications ont bien été enregistrées.</p>
        </div>
        <div id="caramba_error" class="popup" *ngIf="$msgError.value">
            <img src="/assets/img/utilities/red_cross.svg">
            <p>{{error}}</p>
        </div> -->
        <div *ngFor="let msg of $AllMsgs.value">
            <div id="sucessfull" class="popup" *ngIf="msg.type === 'success'">
                <img src="/assets/img/utilities/green_check.svg">
                <p>Vos modifications ont bien été enregistrées.</p>
            </div>
            <div id="caramba_error" class="popup" *ngIf="msg.type === 'error'">
                <img src="/assets/img/utilities/red_cross.svg">
                <p>{{msg.error}}</p>
            </div>
        </div>
        <label>Nom du Site :</label>
        <input type="text" #Nom_Site formControlName="Nom_Site" class="input">
        <label>Adresse :</label>
        <input type="text" #address formControlName="address" class="input">
        <label>Téléphone :</label>
        <input type="text" #tel formControlName="tel" class="input">
        <label>Mail :</label>
        <input type="email" #mail formControlName="mail" class="input">
        <label>Horaires :</label>
        <input type="text" #horaire formControlName="horaire" class="input">

        <label>Editeur du site :</label>
        <input type="text" #editeur_du_site formControlName="editeur_du_site" class="input">
        <label>N° de Siret :</label>
        <input type="text" #numero_siret formControlName="numero_siret" class="input">
        <label>Responsable de publication :</label>
        <input type="text" #responsable_de_publication formControlName="responsable_de_publication" class="input">
        <label>Address du site :</label>
        <input type="text" #adresse_site formControlName="adresse_site" class="input">
        <label>Nom de l'hebergeur :</label>
        <input type="text" #nom_hebergeur formControlName="nom_hebergeur" class="input">
        <label>Site de l'hebergeur :</label>
        <input type="text" #site_hebergeur formControlName="site_hebergeur" class="input">
        <label>Adresse de l'hebergeur :</label>
        <input type="text" #adresse_hebergeur formControlName="adresse_hebergeur" class="input">
        <label>Noms des développeurs :</label>
        <input type="text" #noms_devs formControlName="noms_devs" class="input" readonly=true>
        <label>Site des développeurs :</label>
        <input type="text" #site_devs formControlName="site_devs" class="input">
        <input type="button" value="Enregistrer" (click)='onSubmit()' id="valider">
    </div>
</ng-template>