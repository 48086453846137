import { Component, ViewChildren, ViewChild, ViewEncapsulation, QueryList, AfterViewInit, OnInit } from '@angular/core';
import { NgbCarousel, NgbSlide } from '@ng-bootstrap/ng-bootstrap'
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { CarousselEditService } from 'src/app/services/caroussel-edit.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-main-caroussel',
  templateUrl: './main-caroussel.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./main-caroussel.component.css'],
  animations: [
    trigger('simpleTranslation', [
      state('outright', style({ transform: `translateX(100%)` })),
      state('outleft', style({ transform: `translateX(-100%)` })),
      transition('*=>inright', [
        style({ transform: `translateX(-100%)` }),
        animate('500ms ease-in', style({ transform: `translateX(0)` }))
      ]),
      transition('*=>inleft', [
        style({ transform: `translateX(100%)` }),
        animate('500ms ease-in', style({ transform: `translateX(0)` }))
      ]),
      transition('*=>outleft', [
        animate('500ms ease-in', style({ transform: `translateX(-100%)` }))
      ]),
      transition('*=>outright', [
        animate('500ms ease-in', style({ transform: `translateX(100%)` }))
      ]),
    ])
  ]
}
)
export class MainCarousselComponent implements AfterViewInit, OnInit {

  images;
  $finishedLoading = new BehaviorSubject<boolean>(false);

  currentSlide: number = 0;
  constructor(private carousselS: CarousselEditService, private router: Router, private sanitizer: DomSanitizer, private debugS: DebugService) {
    carousselS.provideData().pipe(take(1)).subscribe(res => {
      if (this.debugS.isDebug())
        console.log(res);
      this.images = res;
      this.$finishedLoading.next(true);
    }
      , err => { if (this.debugS.isDebug()) console.log(err); })
  }
  @ViewChildren(NgbSlide) slides: QueryList<NgbSlide>

  slideControl: any[] = []

  onSlide(event) {
    this.slides.forEach((x, index) => {
      this.currentSlide = event.current;
      if (x.id == event.current) {
        this.slideControl[index] = 'in' + event.direction
      }
      if (x.id == event.prev) {
        this.slideControl[index] = 'out' + event.direction
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slides.forEach((x, index) => {
        this.slideControl[index] = index ? 'outleft' : 'inleft'
      })
    })

  }

  ngOnInit() {

  }
  @ViewChildren('carroussel') carrouselRef: NgbCarousel;
  onClick(event) {
    console.log('click');
    let lien: any = "";
    console.log('event:', event);
    console.log('carrousel:', this.carrouselRef);
    console.log('cSlide:', this.currentSlide);
    try {
      lien = this.images[this.carrouselRef.activeId].lien
    } catch (err) { /* console.log(err); */ console.log('carrousel:', this.carrouselRef); }
    console.log("click :" + lien);
    if (lien === '' || isNullOrUndefined(lien)) return;
    if(lien[0] === "/") {
      this.router.navigate([lien]);
    } else {
      window.location.href = lien;
    }
  }

  getImage(dataImage) {
    const img = dataImage.data.img.split(' ').join('+');
    return this.sanitizer.bypassSecurityTrustStyle(`url(${img})`);
  }
}