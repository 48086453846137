import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { routes } from 'src/assets/routes';
import { DebugService } from './debug.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IsAdminService {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.isAdmin() ? true : this.router.parseUrl(routes.accueil);
  }

  constructor(private router: Router, private debugS: DebugService, private http: HttpClient) { }
  isAdmin(): boolean {
    const token = localStorage.getItem('token');
    if (token != null) {
      const jwtUtils = new JwtHelperService();
      const decodedToken = jwtUtils.decodeToken(token);
      if (this.debugS.isDebug())
        console.log(decodedToken.isAdmin);
      return decodedToken.isAdmin;
    }
  }

  setMaintenance(value) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get<any>(environment.hostAdmin + `setMaintenanceMode?value=${value}`, { headers });
  }
  getMaintenance() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get<any>(environment.hostData + `getMaintenanceMod`, { headers });
  }
}
