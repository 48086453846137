import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { cpus } from 'os';
import { environment } from 'src/environments/environment';
import { DebugService } from '../debug.service';

@Injectable({
  providedIn: 'root'
})
export class ReloadTokenService {

  constructor(private http: HttpClient, private router: Router, private debugS: DebugService) { }

  reloadTokenReq(): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(environment.hostUsersLoginRegister + 'newToken', { headers });
  }
  reloadToken() {
    this.reloadTokenReq().pipe(take(1)).subscribe(
      res => {
        if (this.debugS.isDebug())
          console.log(res);
        localStorage.setItem('token', res.token);
      },
      err => {
        if (this.debugS.isDebug()) {
          console.log(err);
          console.log('removing token');
        }
        localStorage.removeItem('token');
      }
    );
  }
}
