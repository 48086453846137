import { Component, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CatDataService } from 'src/app/services/Products/cat-data.service';
import { routes } from 'src/assets/routes';
import { take } from 'rxjs/operators';
import { TextService } from 'src/app/services/text.service';
import { CookieService } from 'ngx-cookie-service';
import { DebugService } from 'src/app/services/debug.service';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-cat-edit',
  templateUrl: './cat-edit.component.html',
  styleUrls: ['./cat-edit.component.css']
})
export class CatEditComponent implements OnDestroy {
  cat: string;
  sub = new Subscription();
  researchOption: FormGroup = new FormGroup({
    search: new FormControl('')
  });
  Data;
  lien: string;
  $finishedLoading = new BehaviorSubject<boolean>(false);
  editControl: FormGroup;
  isLien1: boolean;
  isLien2: boolean;
  isLien3: boolean;
  constructor(
    private route: ActivatedRoute,
    private dataS: CatDataService,
    private router: Router,
    private textS: TextService,
    private cookieS: CookieService,
    private debugS: DebugService) {
    this.cat = this.route.snapshot.paramMap.get('cat');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.$finishedLoading.next(false);
      this.cat = param.get('cat');
      this.sub.add(dataS.provideData(this.cat).subscribe(
        res => {
          if (this.debugS.isDebug())
            console.log(res);
          this.Data = res;
          this.initFormGroup();
          this.sub.add(this.textS.provideData('mentionslegales').pipe(take(1)).subscribe(res => {
            this.lien = res['adresse_site'].toLowerCase();
            this.$finishedLoading.next(true);
          }, err => {
            this.lien = "";
            this.$finishedLoading.next(true);
          }))
          setTimeout(this.onChangeTextArea, 100);
        },
        err => {
          if (this.debugS.isDebug())
            console.log(err);
          let tDate = new Date(Date.now());
          tDate.setSeconds(tDate.getSeconds() + 60);
          cookieS.set('redirectFrom', '/cat/' + this.cat, tDate);
          router.navigate([routes.notfound]);
        }));
    }));
  }

  onSubmit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onEditSubmit() {
    if (this.editControl.controls['Lien1'].value.length === 0) { this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['D2TH2N1'].value)); } else { this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['Lien1'].value)) }
    if (this.editControl.controls['Lien2'].value.length === 0) { this.editControl.controls['Lien2'].setValue(this.parseUrl(this.editControl.controls['D2TH2N2'].value)); } else { this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['Lien1'].value)) }
    if (this.editControl.controls['Lien3'].value.length === 0) { this.editControl.controls['Lien3'].setValue(this.parseUrl(this.editControl.controls['D2TH2N3'].value)); } else { this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['Lien1'].value)) }
    let obj = {
      1: { isEnable: true, textH1: this.editControl.controls['D1TH1N1'].value, textP: this.editControl.controls['D1TP1'].value },
      2: {
        isEnable: true,
        textH2_1: this.editControl.controls['D2TH2N1'].value,
        textH2_2: this.editControl.controls['D2TH2N2'].value,
        textH2_3: this.editControl.controls['D2TH2N3'].value,
        textP_1: this.editControl.controls['D2TP1'].value,
        textP_2: this.editControl.controls['D2TP2'].value,
        textP_3: this.editControl.controls['D2TP3'].value,
        textH3: this.editControl.controls['D2TH3N1'].value,
        Lien1: this.editControl.controls['Lien1'].value.replace(this.lien, ''),
        Lien2: this.editControl.controls['Lien2'].value.replace(this.lien, ''),
        Lien3: this.editControl.controls['Lien3'].value.replace(this.lien, '')
      },
      /*       3: { isEnable: true, textH1: this.editControl.controls['D3TH1N1'].value, textP: this.editControl.controls['D3TP1'].value },
            4: { isEnable: true, HTML: '' },
            5: { isEnable: true, textH1: this.editControl.controls['D5TH1N1'].value, textP: this.editControl.controls['D5TP1'].value },
            6: { isEnable: true, textH1: this.editControl.controls['D6TH1N1'].value, textP: this.editControl.controls['D6TP1'].value },
            7: { isEnable: true, textH1: this.editControl.controls['D7TH1N1'].value, textP: this.editControl.controls['D7TP1'].value },
            8: { isEnable: true, textH1: this.editControl.controls['D8TH1N1'].value, textP: this.editControl.controls['D8TP1'].value },
            9: { isEnable: true, textH1: this.editControl.controls['D9TH1N1'].value, textP: this.editControl.controls['D9TP1'].value }, */
      'simple_pres': this.getSimplePres()
    };
    console.log(obj)
    this.dataS.pullData(this.cat, obj).pipe(take(1)).subscribe(res => {
      if (this.debugS.isDebug())
        console.log(res);
      this.router.navigate([routes.categorie, this.cat])
    }, err => { if (this.debugS.isDebug()) console.log(err); })
  }

  onChangeTextArea() {
    let textAreas = document.getElementsByTagName('textarea');
    // regular for loop 
    for (let i = 0; i < textAreas.length; i++) {
      textAreas[i].style.overflow = 'hidden';
      textAreas[i].style.height = '0px';
      textAreas[i].style.height = textAreas[i].scrollHeight + 'px';
    }
  }

  initFormGroup() {
    this.editControl = new FormGroup({
      D1TH1N1: new FormControl(this.Data[1].textH1),
      D1TP1: new FormControl(this.Data[1].textP),
      D2TH2N1: new FormControl(this.Data[2].textH2_1),
      D2TP1: new FormControl(this.Data[2].textP_1),
      D2TH2N2: new FormControl(this.Data[2].textH2_2),
      D2TH3N1: new FormControl(this.Data[2].textH3),
      D2TP2: new FormControl(this.Data[2].textP_2),
      D2TH2N3: new FormControl(this.Data[2].textH2_3),
      D2TP3: new FormControl(this.Data[2].textP_3),
      /*       D3TH1N1: new FormControl(this.Data[3].textH1),
            D3TP1: new FormControl(this.Data[3].textP), */
      /*       D5TH1N1: new FormControl(this.Data[5].textH1),
            D5TP1: new FormControl(this.Data[5].textP),
            D6TH1N1: new FormControl(this.Data[6].textH1),
            D6TP1: new FormControl(this.Data[6].textP),
            D7TH1N1: new FormControl(this.Data[7].textH1),
            D7TP1: new FormControl(this.Data[7].textP),
            D8TH1N1: new FormControl(this.Data[8].textH1),
            D8TP1: new FormControl(this.Data[8].textP),
            D9TH1N1: new FormControl(this.Data[9].textH1),
      D9TP1: new FormControl(this.Data[9].textP),*/
      Lien1: new FormControl(this.Data[2].Lien1),
      Lien2: new FormControl(this.Data[2].Lien2),
      Lien3: new FormControl(this.Data[2].Lien3)
    });
    this.isLien1 = this.editControl.controls['Lien1'].value.length === 0;
    this.isLien2 = this.editControl.controls['Lien2'].value.length === 0;
    this.isLien3 = this.editControl.controls['Lien3'].value.length === 0;
    if (this.isLien1) { this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['D2TH2N1'].value)); } else { this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['Lien1'].value)) }
    if (this.isLien2) { this.editControl.controls['Lien2'].setValue(this.parseUrl(this.editControl.controls['D2TH2N2'].value)); } else { this.editControl.controls['Lien2'].setValue(this.parseUrl(this.editControl.controls['Lien2'].value)) }
    if (this.isLien3) { this.editControl.controls['Lien3'].setValue(this.parseUrl(this.editControl.controls['D2TH2N3'].value)); } else { this.editControl.controls['Lien3'].setValue(this.parseUrl(this.editControl.controls['Lien3'].value)) }
    this.sub.add(this.editControl.valueChanges.subscribe(() => {
      this.onChangeTextArea();
    }));
    this.changeSousCat();
    this.initForm()
  }

  htmlestcon() {
    this.router.navigate([routes.categorie, this.cat]);
  }

  initForm() {
    for (let item of this.Data['simple_pres']) {
      if (isNullOrUndefined(item.formG)) {
        item.formG = new FormGroup({
          titre: new FormControl(''),
          sous_titre: new FormControl(''),
          text: new FormControl(''),
          afficherSlider: new FormControl(false),
          configSlider: new FormControl('')
        })
        item.formG.controls['titre'].setValue(item.textH1);
        /* item.formG.controls['sous_titre'].setValue(item.textP); */
        item.formG.controls['text'].setValue(item.textP);
        item.formG.controls['afficherSlider'].setValue(item.afficherSlider);
        item.formG.controls['configSlider'].setValue(item.configSlider);
        this.sub.add(item.formG.valueChanges.subscribe((s) => {
          this.onChangeTextArea();
          console.log(this.getSimplePres());
        }));
      }
    }
  }

  changeSousCat() {
    /* D2TH2N1
       D2TH2N2
       D2TH1N3 */
    /* let h2n1 = this.removeAccents(this.editControl.controls['D2TH2N1'].value).split(/ +/).join('_');
    console.log(h2n1); */
    this.sub.add(this.editControl.controls['D2TH2N1'].valueChanges.subscribe((s) => {
      if (!this.editControl.controls['Lien1'].dirty && this.isLien1) {
        this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['D2TH2N1'].value));
      } else {
        this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['Lien1'].value));
      }
    }));
    this.sub.add(this.editControl.controls['D2TH2N2'].valueChanges.subscribe((s) => {
      if (!this.editControl.controls['Lien2'].dirty && this.isLien2) {
        this.editControl.controls['Lien2'].setValue(this.parseUrl(this.editControl.controls['D2TH2N2'].value));
      } else {
        this.editControl.controls['Lien2'].setValue(this.parseUrl(this.editControl.controls['Lien2'].value));
      }
    }));
    this.sub.add(this.editControl.controls['D2TH2N3'].valueChanges.subscribe((s) => {
      if (!this.editControl.controls['Lien3'].dirty && this.isLien3) {
        this.editControl.controls['Lien3'].setValue(this.parseUrl(this.editControl.controls['D2TH2N3'].value));
      } else {
        this.editControl.controls['Lien1'].setValue(this.parseUrl(this.editControl.controls['Lien1'].value));
      }
    }));
  }
  parseUrl(str) {
    let str2 = this.removeAccents(str).split(/ +/).join('_').toLowerCase();
    if (this.debugS.isDebug())
      console.log(str2);
    return str2.replace(/\W/g, '').replace('https://' + this.lien, '').replace('http://' + this.lien, '').replace(this.lien, '');
  }
  removeAccents(str) {
    var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split('');
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join('');
  }

  getSimplePres() {
    let endData = [];
    for (let item of this.Data['simple_pres']) {
      if (!isNullOrUndefined(item.formG)) {
        endData.push({
          textH1: item.formG.controls['titre'].value,
          textP: item.formG.controls['text'].value,
          afficherSlider: item.formG.controls['afficherSlider'].value,
          configSlider: item.formG.controls['configSlider'].value
        })
      }
    }
    if (this.debugS.isDebug()) console.log(endData);
    return endData;
  }

  supprimer(item) {
    if (this.debugS.isDebug()) console.log(item);
    this.Data['simple_pres'].splice(this.Data['simple_pres'].indexOf(item), 1);
    this.initForm();
  }
  ajouter() {
    if (this.debugS.isDebug()) console.log('click');
    this.Data['simple_pres'].push({ h1: '', resume: '', p: '', afficherSlider: false, configSlider: '' });
    this.initForm();
  }

}
