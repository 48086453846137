import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  constructor(private cookieS: CookieService) { }

  isDebug() {
    let cookieTemp = this.cookieS.get('debug');
    if (!isNullOrUndefined(cookieTemp) && cookieTemp !== '') {
      if (cookieTemp === 'true') {
        return true;
      }
    }
    return false;
  }
}
