// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hostUsersLoginRegister: 'https://tropikvap.nwo.ovh:3000/api/connect/',
  hostUsersModify: 'https://tropikvap.nwo.ovh:3004/api/users/',
  hostAdmin: 'https://tropikvap.nwo.ovh:3002/api/sudoUsers/',
  hostData: 'https://tropikvap.nwo.ovh:3003/api/data/',
  debug_mode: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
