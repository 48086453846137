import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImgCropperConfig } from '@alyle/ui/image-cropper';

@Injectable({
  providedIn: 'root'
})
export class ConfigCropperService {
  $wichConfig = new BehaviorSubject<string>('caroussel')
  constructor() { }
  getConfig(): ImgCropperConfig {
    if (this.$wichConfig.value === 'caroussel') {
      return {
        width: 250, // Default `250`
        height: 90, // Default `200`
        type: 'image/jpeg', // Or you can also use `image/jpeg`,
        output: {
          width: 3840,
          height: 1382,
        }
      }
    }
    if (this.$wichConfig.value === 'product') {
      return {
        width: 600, // Default `250`
        height: 450, // Default `200`
        type: 'image/jpeg', // Or you can also use `image/jpeg`,
        output: {
          width: 2000,
          height: 1500,
        }
      }
    }
  }

}
