<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<span #m_classify id="m_classify"></span>
<ng-template #finishedLoading>
    <div class="body_container">
        <p id="back" [routerLink]="returnBack()">revenir en arrière</p>
        <div class="big_title">
            <h1>{{this.sousCat}}</h1>
            <span></span>
        </div>
        <div id="big_container">
            <div id="extender" (click)="$isFilterOuvert.next(!$isFilterOuvert.value)">
                <div id="n1" *ngIf="!$isFilterOuvert.value">
                    <p>Affinez votre rechercher</p>
                    <img src="/assets/img/utilities/up.svg">
                </div>
                <div id="n2" *ngIf="$isFilterOuvert.value">
                    <p>Fermer</p>
                    <img src="/assets/img/utilities/down.svg">
                </div>

            </div>
            <div id="classify" *ngIf="$isFilterOuvert.value || !$isMobile.value ">
                <h1>Affinez votre rechercher</h1>
                <ul id="title_container">
                    <li id="search" [formGroup]="this.barreRechercheForm">
                        <label for="searchInput">Rechercher une référence
                            :</label>
                        <div>
                            <input type="search" id="searchInput" name="searchInput" placeholder="nom, référence, ..."
                                #barreRecherche formControlName="barreRecherche" (input)="this.DOfilter()" />
                            <!-- <input type="button" value="Ok" id="searchButton" /> -->
                        </div>
                    </li>

                    <li *ngFor="let filter of filters;let i = index">
                        <div class="cat_woman" (click)="filter.isClicked = !filter.isClicked;">
                            <!-- faut cliquer sur ça -->
                            <div class="title_filter">
                                <p>{{filter.titre}}</p>
                                <div *ngIf="(filter.help !== null && filter.help !== undefined && filter.help !== '') || isAdmin">
                                    <img class="plus" src="\assets\img\utilities\help.svg">
                                    <div class="cat_interrog">
                                        <span
                                            *ngIf="!filter.isEditing">{{filter.help || 'Il n\'a pas d\'aide pour le moment'}}</span>
                                        <textarea class="edited_input" *ngIf="filter.isEditing"
                                            placeholder="remplir pour afficher une aide"
                                            (input)="filter.editText = $event.target.value">{{filter.help ||''}}</textarea>
                                        <br /><span class="edit" *ngIf="isAdmin && !filter.isEditing"
                                            (click)="filter.isEditing = true; filter.editText = ''">edit</span>

                                        <div id="container_slu" *ngIf="filter.isEditing">
                                            <span class="edit red" (click)="filter.isEditing = false">annuler</span>
                                            <span class="edit green"
                                                (click)="editHelp(filter.titre, filter.editText); filter.isEditing = false; filter.help = filter.editText">valider</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <img class=" plus" src="\assets\img\utilities\-.svg" *ngIf="filter.isClicked">
                            <img class="plus" src="\assets\img\utilities\+.svg" *ngIf="!filter.isClicked">

                        </div>

                        <!-- <div class="cat_interrog">
                            test
                        </div> -->

                        <div class="cat_content" *ngIf="filter.isClicked && filter.type === 'checkbox'">
                            <ul class="checkable">
                                <div *ngFor="let cheb of filter.checkb; let i2 = index">
                                    <li (click)="checkBoxChange(i, i2)">
                                        <input type="checkbox" [checked]="this.filters[i].checkb[i2].isChecked" />
                                        <label [for]=" cheb.id">{{cheb.name}}</label>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div class="cat_content sliders" *ngIf="filter.isClicked && filter.type === 'slider'">
                            <ng5-slider [(value)]="filter.value" [(highValue)]="filter.highValue"
                                [options]="filter.options" (userChange)="this.DOfilter()"></ng5-slider>
                        </div>
                    </li>
                </ul>
            </div>
            <div id="content">
                <div class="product" id="addProduct" *ngIf="isAdmin" [routerLink]="this.router.url + '/new/edit'">
                    <img src="\assets\img\utilities\+2.svg" />
                </div>
                <div class="product" *ngFor="let product of filteredProduct">
                    <div [routerLink]="'/cat/'+ this.cat + '/' + this.sousCat + '/' +product.lien">
                        <!-- background-image: url("https://hw-cdn.trafficjunky.net/uploaded_content/creative/101/962/530/1/1019625301.jpg");/ -->
                        <div class="image_kk" [style.background-image]="getImage(product.img)">
                        </div>
                        <h3>{{product.h3}}</h3>
                        <p class="desc">{{product.p}}</p>
                        <p class="price">
                            <span class="promo"
                                *ngIf="product.promo !== product.prix">{{product.promo | currency:'EUR':'€':'1.2-2':'fr-FR' }}
                            </span>
                            <span
                                [ngClass]="{'promoted' : product.promo !== product.prix}">{{product.prix | currency:'EUR':'symbol':'1.2-2':'fr-FR' }}</span>
                        </p>
                    </div>
                </div>
                <div class="notfound" *ngIf="filteredProduct.length === 0">
                    <p>Aucun produit n'a été trouvé, tentez de changer vos paramètres de recherche.</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>