<app-loading *ngIf="$isApiOk.value === 'Loading'; else loadingMaintenance"></app-loading>
<ng-template #loadingMaintenance>
  <app-loading *ngIf="$MaintenanceMod.value === 'Loading'; else Finishloading"></app-loading>
</ng-template>
<ng-template #Finishloading>
  <div *ngIf="$MaintenanceMod.value === 'No'; else maintenance">
    <!-- <div id="page_selector"> -->
    <app-header *ngIf="showHeader()"></app-header>

    <div id="mabite123" *ngIf="showMenuDashboard(); else nodash">
      <app-dash-header id="dash_header"></app-dash-header>
      <div id="page_content">
        <app-vertical-menu id="vertical_menu"></app-vertical-menu>
        <div id="insider">
          <router-outlet *ngIf="$isApiOk.value === 'success'; else noApi" id="test2"></router-outlet>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <ng-template #nodash>
      <router-outlet *ngIf="$isApiOk.value === 'success'; else noApi"></router-outlet>
      <div id="footer_margin" *ngIf="!showMenuDashboard()">
      </div>
      <app-footer *ngIf="$isApiOk.value === 'success'"></app-footer>
    </ng-template>
    <ng-template #noApi id="test">
      <div id="errorApiContainer">
        <p id="errorApi">Le site est parti en fumée.
          <br />Prenez une petite taffe sur votre vapoteuse, et nous serons de retour !</p>
        <img id="logo-error" src="\assets\img\logos\logo_tropik_blank.png" alt="logo site" />
      </div>
    </ng-template>
  </div>
</ng-template>
<ng-template #maintenance id="test">
  <div id="errorApiContainer">
    <p id="errorApi">Le site est parti en fumée pour cause de maintenance.
      <br />Prenez une petite taffe sur votre vapoteuse, et nous serons de retour !</p>
    <img id="logo-error" src="\assets\img\logos\logo_tropik_blank.png" alt="logo site" />
  </div>
  <p id="maintenance_login" (click)='clickLoginButton()'>se connecter</p>
</ng-template>

<div *ngIf="$isApiOk.value === 'success' && $MaintenanceMod.value === 'No' && !hasAlreadyAcceptedCookie()" id="cookies">
  <div id="flag">
    <img src="\assets\img\loader\e-liquide-cookie.png">
    <p>Afin de continuer la navigation sur ce site, veuillez accepter l'utilisation de cookies servants
      casi-exclusivement
      au fonctionnement du site.</p>
    <input type="button" value="décliner" (click)="declinerCookie()"><!-- keur keur sur toi <3 -->
    <input type="button" value="accepter" (click)="acceptCookie()">
  </div>
</div>