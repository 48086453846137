import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { PasswordPopupComponent } from '../password-popup/password-popup.component';
import { PasswordPopupService } from 'src/app/services/password-popup.service';
import { timingSafeEqual } from 'crypto';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-bouton-admin',
  templateUrl: './bouton-admin.component.html',
  styleUrls: ['./bouton-admin.component.css']
})
export class BoutonAdminComponent implements ICellRendererAngularComp {
  isAdmin;
  id;
  params;
  index;
  constructor(private dialog: MatDialog, private passwordS: PasswordPopupService, private debugS: DebugService) { }
  refresh(params: any): boolean {
    if(this.debugS.isDebug())
    console.log('refresh 2');
    this.params = params;
    this.isAdmin = params.data.isAdmin;
    return true;
  }
  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    this.params = params;
    this.isAdmin = params.data.isAdmin;
  }
  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {

  }

  onClick() {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        rowData: this.params.node.data,
        index: this.params.rowIndex
      }
      this.params.onClick(params);
    }
  }
}
