<div class="login-form">
  <img src="assets/img/utilities/cross.png" class="cross-login" (click)="close()" />
  <h1>Inscription</h1>

  <!-- <ul id="login-with">
     <li>
       <button (click)="al()">
         <img
           id="apple-logo"
           src="assets/img/utilities/png/apple.png"
           srcset="assets/img/utilities/apple.svg"
           alt="s'enregistrer avec apple"
           height="50px"
         />
       </button>
     </li>
     <li>
       <button (click)="al()">
         <img
           src="assets/img/utilities/png/google.png"
           srcset="assets/img/utilities/google.svg"
           alt="s'enregistrer avec apple"
           height="50px"
         />
       </button>
     </li> 
     <li>
       <button>
         <img
           src="assets/img/utilities/png/facebook.png"
           srcset="assets/img/utilities/facebook.svg"
           alt="s'enregistrer avec apple"
           height="50px"
         />
       </button>
     </li>
    </ul>
 
   <p id="login-OU">OU</p> -->
  <form [formGroup]="ReactRegisterForm" (ngSubmit)="onSubmit()">
    <div class="txtb">
      <input #email type="email" name="email" formControlName="email" />
      <span data-placeholder="Email"></span>
      <div id="colored"></div>
    </div>

    <div class="txtb">
      <input #username type="text" name="username" formControlName="username" />
      <span data-placeholder="Nom d'utilisateur"></span>
      <div id="colored"></div>
    </div>

    <div class="txtb">
      <input #password type="password" name="password" formControlName="password" />
      <span data-placeholder="Mot de passe" class="place-holder-login"></span>
      <div id="colored"></div>
    </div>

    <p class="error" *ngIf='showError()'>{{error}}</p>

    <input type="submit" class="logbtn" value="S'inscrire" />
  </form>

  <div class="bottom-text">
    Déjà un compte? <a (click)="switchToLogin()">Se connecter</a>
  </div>
</div>