import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { IsAdminService } from 'src/app/services/is-admin.service';
import { SousCatService } from 'src/app/services/Products/sous-cat.service';
import { take } from 'rxjs/operators';
import { routes } from 'src/assets/routes';
import { CookieService } from 'ngx-cookie-service';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
import { isNullOrUndefined } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  product: any;
  sub = new Subscription();
  isAdmin;
  $product = new BehaviorSubject<any>({});
  $finishedLoading = new BehaviorSubject<boolean>(false);
  $caract = new BehaviorSubject<[]>([]);
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private isAdminS: IsAdminService,
    private sousCatS: SousCatService,
    private cookieS: CookieService,
    private errorHandler: ErrorManagerService,
    private sanitizer: DomSanitizer,
    private debugS: DebugService) {
    this.product = this.route.snapshot.paramMap.get('product');
    this.sub.add(this.route.paramMap.subscribe(param => {
      this.product = param.get('product');
    }));
    if (this.product === "new") {
      let redirectUrlTab = this.router.url.split('/');
      redirectUrlTab.shift();
      redirectUrlTab.pop();
      let redirectUrl = redirectUrlTab.join('/')
      if (this.debugS.isDebug())
        console.log('redirectUrl :', redirectUrl)
      this.router.navigate([redirectUrl])
    }
    this.sub.add(this.sousCatS.provideProductByLien(this.product, false).pipe(take(1)).subscribe(res => {
      this.$product.next(res);
      if (this.debugS.isDebug())
        console.log(res);
      if (!isNullOrUndefined(this.$product.value['attributs']) && this.$product.value['attributs'] !== '') {
        let value = this.$product.value;
        value['attributs'] = JSON.parse(value['attributs']);
        this.$product.next(value);
        if (this.debugS.isDebug())
          console.log('product attributs :', this.$product.value['attributs']);
        this.parseCaract();
        this.$finishedLoading.next(true);
      } else {
        this.$finishedLoading.next(true);
      }
    }, err => {
      if (this.debugS.isDebug()) {
        console.log(err);
        console.log(this.errorHandler.parseError('product-edit', err.error.errorCode, err.error.arg, err.error.error));
      }
      this.redirect();
    }))
    this.isAdmin = isAdminS.isAdmin();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  redirect() {
    let tDate = new Date(Date.now());
    tDate.setSeconds(tDate.getSeconds() + 60);
    this.cookieS.set('redirectFrom', this.router.url, tDate);

    this.router.navigate([routes.notfound]);
  }
  ngOnInit(): void {
  }

  returnBack() {
    let ret = this.router.url.split('/');
    ret.pop();
    return ret;
  }
  parseCaract() {
    let ret: any = [];
    if(this.debugS.isDebug())
    console.log(this.$product.value.attributs);
    for (let attribut of this.$product.value.attributs) {
      if(this.debugS.isDebug())
      console.log('attribut :', attribut);
      if (attribut.type === 'countable') {
        ret.push({ name: attribut.name, value: attribut.value, type: 'countable', unit: attribut.unit });
      } else {
        ret.push({ name: attribut.name, value: attribut.value, type: attribut.type });
      }
    }
    this.$caract.next(ret);
  }
  getImage(dataImage) {
    if (!isNullOrUndefined(dataImage) && dataImage !== '') {
      const dataImageP = dataImage.split(' ').join('+');
      return this.sanitizer.bypassSecurityTrustUrl(`${dataImageP}`);
    }
    return '';
  }
}
