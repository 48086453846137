import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BoutonAdminComponent } from 'src/app/core/user-panel/admin-panel/bouton-admin/bouton-admin.component';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PasswordPopupComponent } from './password-popup/password-popup.component';
import { PasswordPopupService } from 'src/app/services/password-popup.service';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { cpus } from 'os';
import { DebugService } from 'src/app/services/debug.service';
import { IsAdminService } from 'src/app/services/is-admin.service';
import { take } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {
  public frameworkComponents;
  context;
  columnDefs;
  defaultColDef;
  sub = new Subscription();
  rowData: any;
  rowClassRules;
  gridOptions: GridOptions = {};
  private gridApi: GridApi;
  gridColumnApi: ColumnApi;
  $isMaintenance = new BehaviorSubject<boolean>(false);
  $AllMsgs = new BehaviorSubject<any>([]);
  index = 0;
  constructor(
    private http: HttpClient,
    public passwordS: PasswordPopupService,
    private dialog: MatDialog,
    private debugS: DebugService,
    private isAdminS: IsAdminService,
    private errorHandler: ErrorManagerService) {
    this.sub.add(this.isAdminS.getMaintenance().pipe(take(1)).subscribe(res => { this.$isMaintenance.next(res.data) }, err => { if (this.debugS.isDebug()) console.log(err); }))
    this.columnDefs = [
      { headerName: 'Id', field: 'id', sortable: true, filter: true },
      { headerName: 'Email', field: 'email', sortable: true, filter: true },
      { headerName: 'Nom d\'utilisateur', field: 'username', sortable: true, filter: true },
      { headerName: 'Admin', field: 'isAdmin', valueFormatter: this.booleanFormatter, sortable: true, filter: true },
      { headerName: 'Compte vérifié', field: 'isVerified', valueFormatter: this.booleanFormatter, sortable: true, filter: true },
      { headerName: 'Créé le', field: 'createdAt', valueFormatter: this.dateFormatter, sortable: true, filter: true },
      {
        headerName: 'Actions',
        field: 'value',
        cellRenderer: 'BoutonAdminComponent',
        colId: 'params',
        cellRendererParams: {
          onClick: (params) => this.click(params)
        },
        editable: false,
        suppressSizeToFit: true,
        cellStyle: {
          "display": "flex",
          "align-items": "center",
          "overflow": "visible",
          "width": "230px"
        },
        cellClass: 'BoutonAdmin',
        sortable: false,
        filter: false,
      },
    ];
    this.frameworkComponents = {
      BoutonAdminComponent: BoutonAdminComponent
    };
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      enableCellTextSelection: true
    };
    /*   this.gridOptions.rowClassRules = {
        'test': function (params) {
          console.log(params.node.rowIndex % 2 === 0)
          return params.node.rowIndex % 2 === 0;
        }
      } */
  }

  click(params) {
    if (this.debugS.isDebug())
      console.log(params.index);
    this.passwordS.id = params.rowData.id;
    this.passwordS.isAdmin = params.rowData.isAdmin;
    this.passwordS.gridApi = this.gridApi
    this.passwordS.index = params.index;
    this.passwordS.openPopup();
  }

  scrollToElement($element): void {
    if (this.debugS.isDebug())
      console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  afficherMsg(type, error?) {
    if (type === 'error') {
      if (isNullOrUndefined(error)) return false;
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ value: true, index: indexTemp, type: 'error', error: error })
      this.$AllMsgs.next(value);
      this.scrollToElement(document.querySelector('#list1-hm'));
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if (this.debugS.isDebug())
          console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    } else if (type === 'success') {
      let value = this.$AllMsgs.value;
      this.index = this.index + 1;
      let indexTemp = this.index;
      value.push({ index: indexTemp, type: 'success' })
      this.$AllMsgs.next(value);
      this.scrollToElement(document.querySelector('#list1-hm'));
      setTimeout(() => {
        let value: any = this.$AllMsgs.value;
        value = value.filter(s => s.index !== indexTemp);
        if (this.debugS.isDebug())
          console.log('value', value, 'index', indexTemp);
        this.$AllMsgs.next(value);
      }, 3000)
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.autoSizeAllColumns();
  }

  ngOnInit() {
    this.rowData = this.http.get(environment.hostAdmin + 'getUsersList');
    if (this.debugS.isDebug())
      console.log('raw data :' + this.rowData);
  }

  redrawCells() {
    if (this.debugS.isDebug())
      console.log('refresh 1');
    this.gridApi.refreshCells();
  }
  refresh() {
    this.rowData = this.http.get(environment.hostAdmin + 'getUsersList');
  }
  booleanFormatter(params) {
    return params.value ? 'Oui' : 'Non';
  }

  dateFormatter(params) {
    return Intl.DateTimeFormat('fr-fr').format(Date.parse(params.value))
  }
  setAdminMode() {
    this.sub.add(this.isAdminS.setMaintenance(!this.$isMaintenance.value).pipe(take(1)).subscribe(res => { this.afficherMsg('success'); this.$isMaintenance.next(!this.$isMaintenance.value) }, err => { this.afficherMsg('error', this.afficherMsg('errror', this.errorHandler.parseError('infoedit', err.error.errorCode, err.error.arg, err.error.error))); }))
  }
}

/* nique ta race :) */