//#region imports
import { Component, OnDestroy, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { LoginRegisterService } from 'src/app/services/login-register/login-register.service';
import { routes } from 'src/assets/routes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ErrorManagerService } from 'src/app/services/error-manager.service';
import { DebugService } from 'src/app/services/debug.service';

//#endregion

//#region component
@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.css']
})
//#endregion

export class LoginformComponent implements OnDestroy {
  $isForgotPasswordClicked = new BehaviorSubject<boolean>(false);
  //#region variables
  reactLoginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required)
  });
  //#endregion
  sub = new Subscription();
  error = undefined;

  constructor(
    private loginService: LoginRegisterService,
    private router: Router,
    private dialogRef: MatDialogRef<LoginformComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private errorHandler: ErrorManagerService,
    private debugS: DebugService
  ) {
    if (this.debugS.isDebug()) console.log(data);
    if (isNullOrUndefined(data)) {
      data = { isMaintenance: false };
    }
    this.sub.add(this.$isForgotPasswordClicked.subscribe(v => {
      if (this.debugS.isDebug())
        console.log(`click ${v}`)
      if (v) {
        this.reactLoginForm = new FormGroup({
          email: new FormControl('', [Validators.required, Validators.email]),
          password: new FormControl('', Validators.required)
        });
      } else {
        this.reactLoginForm = new FormGroup({
          email: new FormControl('', [Validators.required]),
          password: new FormControl('', Validators.required)
        });
      }
    }))
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  // quand on appuie sur le bouton Se Connecter
  onSubmit() {
    this.login(
      this.reactLoginForm.value.email,
      this.reactLoginForm.value.password
    );
  }

  // fonction de connexion
  login(email: string, password: string) {
    this.setError();
    this.loginService
      .requestLogin(email, password)
      .pipe(take(1))
      .subscribe(
        // success
        res => {
          if (this.debugS.isDebug())
            console.log(res);
          localStorage.setItem('token', res.token);
          if (this.debugS.isDebug())
            console.log('redirect !');
          this.router.navigate([routes.dashboard]);
          this.dialogRef.close('end');
        },
        // erreur
        err => {
          if (this.debugS.isDebug())
            console.log(err);
          this.setError(this.errorHandler.parseError('login', err.error.errorCode, err.error.arg, err.error.error));
        });
  }
  // quand on clique sur la croix
  close = () => this.dialogRef.close('exit');

  // quand on clique sur le bouton s'inscrire
  register() {
    this.dialogRef.close('register');
  }
  al() {
    alert('fonctionnalité pas encore implementée !');
  }

  forgotPassword() {
    if (this.debugS.isDebug())
      console.log('click');
    this.loginService.sendForgotPassword(this.reactLoginForm.controls['email'].value).pipe(take(1)).subscribe(res => { if (this.debugS.isDebug()) console.log(res); }, err => { if (this.debugS.isDebug()) console.log(err); })
  }

  showError = () => this.error;
  setError = (message?: string) => this.error = isNullOrUndefined(message) ? undefined : message;
}
