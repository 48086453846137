<div *ngFor="let msg of $AllMsgs.value">
    <div id="sucessfull" class="popup" *ngIf="msg.type === 'success'">
        <img src="/assets/img/utilities/green_check.svg">
        <p>Vos modifications ont bien été enregistrées.</p>
    </div>
    <div id="caramba_error" class="popup" *ngIf="msg.type === 'error'">
        <img src="/assets/img/utilities/red_cross.svg">
        <p>{{msg.error}}</p>
    </div>
</div>
<ag-grid-angular style="width: 100%; height: 500px; overflow-x:auto;" class="ag-theme-alpine"
    [rowData]="rowData | async" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
    [context]="context" [defaultColDef]="defaultColDef" [rowClassRules]="rowClassRules" [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>
<button (click)="refresh()" class="apply_modify">Refresh</button><br />
<app-password-popup *ngIf="this.passwordS.$isLoaded.value"></app-password-popup>
<!-- <app-caroussel-edit-form></app-caroussel-edit-form> -->
<input type="button" [value]="this.$isMaintenance
.value ? 'Désactiver le mode maintenance' : 'Activer le mode maintenance'" class="apply_modify red"
    (click)="setAdminMode()" />