<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<ng-template #finishedLoading>
    <div class="body_container">
        <div id="back_container">
            <input type="submit" value="annuler" class="apply_modify"
                [routerLink]="this.router.url.replace('/edit', '')" />
        </div>
        <div class="big_title">
            <h1>Foire Aux Questions</h1>
            <span></span>
        </div>
        <ul id="title_container" *ngFor="let item of data" [formGroup]="item.formG">
            <li class="cat_woman">
                <!-- faut cliquer sur ça -->
                <div>
                    <h3><input class="edited_input" placeholder="Saisissez un titre" type="text"
                            formControlName="titre">
                    </h3><!-- titre -->
                    <p><input class="edited_input" placeholder="Saisissez un sous-titre" type="text"
                            formControlName="sous_titre"></p><!-- résumé -->
                </div>
                <p class="rm_btn" (click)="supprimer(item)">supprimer</p>

            </li>
            <li class="cat_content">
                <!-- quand déplié -->
                <textarea id="testarea" class="edited_textarea" placeholder="Saisissez du texte" type="text"
                    formControlName="text"></textarea>
                <!-- contenu -->
            </li>
        </ul>
        <p class="add_btn" (click)="ajouter()">ajouter</p>

        <div id="apply_container">
            <input type="submit" value="appliquer changements" class="apply_modify" (click)="finished()" />
