<app-loading *ngIf="$finishedLoading.value === false; else finishedLoading"></app-loading>
<ng-template #finishedLoading>
    <div class="body_container">
        <div id="edit_container">
            <input type='button' value='edit' routerLink="edit" id="edit_button" *ngIf="this.isAdmin">
        </div>
        <div class="big_title">
            <h1>Foire Aux Questions</h1>
            <span></span>
        </div>
        <ul id="title_container" *ngFor="let item of data">
            <li class="cat_woman" (click)="item.isClicked = !item.isClicked;">
                <!-- faut cliquer sur ça -->
                <div>
                    <h3>{{item.h1}}</h3><!-- titre -->
                    <p>{{item.resume}}</p><!-- résumé -->
                </div>
                <img class="plus" src="\assets\img\utilities\-.svg" *ngIf="item.isClicked">
                <!-- quand déplié -->
                <img class="plus" src="\assets\img\utilities\+.svg" *ngIf="!item.isClicked">
                <!-- quand replié -->

            </li>
            <li class="cat_content" *ngIf="item.isClicked">
                <!-- quand déplié -->
                {{item.p}}
                <!-- contenu -->
            </li>
        </ul>
    </div>
</ng-template>