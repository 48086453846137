<!-- <div [formGroup]='editControl'>
    <label>Titre :</label>
    <input type="text" #titre formControlName="titre">
    <label>Sous Titre :</label>
    <input type="text" #sous_titre formControlName="sous_titre">
    <label>Lien :</label>
    <input type="text" #lien formControlName="lien">
    <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>

    <button ly-button color="primary" (click)="_fileInput.click()">
        <ly-icon [mf]="1">image</ly-icon>
        <span>Select File</span>
    </button>

    <div *ngIf="cropped"><img [src]="cropped"></div>
    <input type="button" (click)="onSubmit()" value="clicker bandes de salopes">
</div> -->
<app-loading *ngIf="$finishedLoading.value === false; else finishedTemplate"></app-loading>
<ng-template #finishedTemplate>
    <div id="page_content">
        <div *ngFor="let msg of $AllMsgs.value">
            <div id="sucessfull" class="popup" *ngIf="msg.type === 'success'">
                <img src="/assets/img/utilities/green_check.svg">
                <p>Vos modifications ont bien été enregistrées.</p>
            </div>
            <div id="caramba_error" class="popup" *ngIf="msg.type === 'error'">
                <img src="/assets/img/utilities/red_cross.svg">
                <p>{{msg.error}}</p>
            </div>
        </div>

        <div *ngFor="let img of images; let i = index">
            <!-- <img [src]="img.data.img"> -->
            <!-- <p>{{img.id}}</p> -->
            <div class="cadres" [style.background-image]="getImage(img)" (click)="onClickCadres(i, img.id)"
                [id]="'img' + i">
                <div class="darker">
                    <img src="/assets/img/utilities/w-pencil.svg">
                </div>
            </div>
            <div class="selection_container" [formGroup]="editControl" *ngIf="$isOpen.value === i">
                <div id="confirm_rm" *ngIf="this.$confirmShow.value">
                    <div id="rm_subcontainer">
                        <p>Souhaitez-vous supprimer cette slide ?</p>
                        <div>
                            <input type="submit" value="annuler" class="apply_modify grey"
                                (click)="this.$confirmShow.next(false)" />
                            <input type="submit" value="supprimer" class="apply_modify red"
                                (click)="deleteActualSlide()" />
                        </div>
                    </div>
                </div>
                <label>Titre</label>
                <input type="text" #titre formControlName="titre" class="input">
                <label>Sous-titre</label>
                <input type="text" #sous_titre formControlName="sous_titre" class="input">
                <label>Lien</label>
                <input type="text" #lien formControlName="lien" class="input">
                <label>Changer d'image</label>
                <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>

                <button ly-button color="primary" (click)="_fileInput.click()">
                    <ly-icon [mf]="1">image</ly-icon>
                    <span>Select File</span>
                </button>

                <div *ngIf="cropped" class="cropped"><img [src]="cropped"></div>
                <!-- faut mettre ton truc magique ici -->
                <div class="dual_button">
                    <input type="submit" value="supprimer" class="apply_modify red"
                        (click)="this.$confirmShow.next(true)" />
                    <input type="submit" value="appliquer" class="apply_modify green"
                        (click)='modidySlides(img.id, img)' />
                </div>
            </div>
        </div>
        <div class="cadres new" (click)="onClickCadres(-2)" id="img-2">
            <div class="darker2">
                <img src="/assets/img/utilities/+.svg">
            </div>
        </div>
        <div class="selection_container" [formGroup]="addControl" *ngIf="$isOpen.value === -2">
            <label>Titre</label>
            <input type="text" #titre formControlName="titre" class="input">
            <label>Sous-titre</label>
            <input type="text" #sous_titre formControlName="sous_titre" class="input">
            <label>Lien</label>
            <input type="text" #lien formControlName="lien" class="input">
            <label>Charger l'image</label>
            <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>

            <button ly-button color="primary" (click)="_fileInput.click()">
                <ly-icon [mf]="1">image</ly-icon>
                <span>Select File</span>
            </button>

            <div *ngIf="cropped" class="cropped"><img [src]="cropped"></div>
            <input type="submit" value="valider" class="apply_modify green" (click)="addSlides()" />
        </div>
    </div>
</ng-template>