import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TextService } from 'src/app/services/text.service';
import { routes } from 'src/assets/routes';
import { DebugService } from 'src/app/services/debug.service';

@Component({
  selector: 'app-mentions-legal',
  templateUrl: './mentions-legal.component.html',
  styleUrls: ['./mentions-legal.component.css']
})
export class MentionsLegalComponent implements OnInit {
  text;
  $finishedLoading = new BehaviorSubject<boolean>(false);
  sub = new Subscription();
  constructor(private router: Router, private textS: TextService, private debugS: DebugService) {
    this.$finishedLoading.next(false);
    // this.text = { nom_site: "TropikVap", adresse: "117 bis rue Raphaël Babet 97480 St Joseph", num_tel: "0262921767", mail: "contact@tropikvap.re", horaires: "en semaine de 3h à minuit↵le week-end non-stop !" }
    this.sub.add(this.textS.provideData('mentionslegales').subscribe(
      res => {
        if(this.debugS.isDebug())
        console.log(res);
        this.text = res;
        this.$finishedLoading.next(true);
      },
      err => {
        if(this.debugS.isDebug())
        console.log(err);
        router.navigate([routes.notfound]);
      }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
