import { Injectable, ɵSWITCH_VIEW_CONTAINER_REF_FACTORY__POST_R3__ } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ReactiveFormsModule } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagerService {

  constructor() { }

  parseError(from, ErrorCode, arg?, error?) {
    let firstFormat = this.firstFormat(from, ErrorCode, arg, error);
    /*     if( firstFormat === 'vous existe deja'){
          firstFormat = 'vous existez deja'
        } */
    return firstFormat;
  }
  firstFormat(from, ErrorCode, arg?, error?) {
    switch (ErrorCode) {
      case 9: {
        return 'Votre identidiant ou mot de passe est invalide, veuillez réessayer.'
      }
      case 5: {
        if (!isNullOrUndefined(arg) && arg !== "") {
          return `il manque : ${this.formatArg(arg)}`
        }
        return 'Un argument est manquant'
      }
      case 7: {
        if (!isNullOrUndefined(error) && error !== "") {
          if (error === 'username too long') {
            return 'nom d\'utilisateur trop long'
          } else if (error === 'invalid email') {
            return 'adresse email invalide'
          } else if (error === 'invalid password length') {
            return 'taille du mot de passe invalide'
          }
        }
      }
      case 8: {
        if (!isNullOrUndefined(arg) && arg !== "") {
          return `${arg} existe déjà`
        }
      }
      case 1: {
        return `une Erreur d'authentifaction est survenue`
      }
    }
    if (ErrorCode === 500) {
      return 'Une erreur est survenue'
    }
    return 'Une erreur est survenue'
  }
  formatArg(arg) {
    if (arg === "email") {
      return 'adresse email'
    }
    if (arg === "user") {
      return 'vous'
    }
    if (arg === 'password') {
      return 'mot de passe'
    }
    if (arg === 'username/email') {
      return 'email ou nom d\'utilisateur'
    }
    if (arg === 'id') {
      return 'bo'
    }
    if (arg === 'token') {

    }
    if (arg === 'newPassword') {

    }
    if (arg === 'img') {
      return 'image';
    }
    return arg;
  }
}
